import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { AppService } from "src/app/app.service";

@Component({
  selector: "app-iframe",
  templateUrl: "./iframe.component.html",
  styleUrls: ["./iframe.component.scss"],
})
export class MainIframeComponent implements OnInit, AfterViewInit {
  public visible: boolean = false;
  public iframeSource: string = "https://example.com";
  EventSubWatcher: any;
  cuser: string = "";
  constructor(
    public service: AppService,
    private changeDetector: ChangeDetectorRef
  ) {
    var d = this.service.CurentUserData;
    this.cuser = d.userGuid;

    this.EventSubWatcher = service.App.LoadMainIframe.subscribe((obj) => {
      this.service.DevelopConsoleLog("LoadMainIframeLoadMainIframe", this.iframeSource);

      //this.changeDetector.detectChanges();

      if (obj.visible && obj.src != "") {
        this.visible = obj.visible;
        this.iframeSource = obj.src;

        this.loadiframe();
      } else {
      }
    });
  }
  ngOnInit() {}

  ngAfterViewInit() {}

  loadiframe() {
    // this.visible = true;
    // this.service.DevelopConsoleLog("loadiframe", this.iframeSource);
    // const iframe = document.getElementById("myIframe") as HTMLIFrameElement;
    // iframe.style.height = `${$(window).height() - 60}px`;
    // iframe.setAttribute("src", this.iframeSource);

    try {
      var params = [
        "height=" + screen.height,
        "width=" + screen.width,
        "fullscreen=yes",
      ].join(",");

      if (this.iframeSource == this.service.qdispatchDomain + "/SignOut.aspx") {
        //params = ["height=2", "width=2", "fullscreen=no"].join(",");
        var popup = window.open(this.iframeSource, "TwmlDail", params);
        popup.moveTo(screen.width, screen.height); 
        setTimeout(() => {
          popup.close(); 
          this.iframeSource = "";
        }, 3000);  

      } else {
        var popup = window.open(this.iframeSource, "TwmlDail", params);
        popup.moveTo(0, 0);
        setTimeout(() => {
    
          this.iframeSource = "";
        }, 2000);  
      }
    } catch (x) {
      this.service.DevelopConsoleLog("LoadDailyScheduleGroup", x);
    }
  }

  CloseIframe() {
    // this.visible = false;
    // this.service.DevelopConsoleLog("CloseIframe", new Date().getTime());
    // const iframe = document.getElementById("myIframe") as HTMLIFrameElement;
    // iframe.style.height = `0px`;
    // iframe.setAttribute("src", "");
  }
}
