<div class="top-nav d-flex">
	<!-- Mobile search toggle icon -->
	<div class="top-nav__item d-md-none" (click)="service.searchToggled = true">
		<i class="zwicon zwicon-search"></i>
	</div>


	<div class="top-nav__item align-items-center" (click)="LockScreen()">
		<i class="zwicon zwicon-lock"></i>
		<small class="icon_text">Lock</small>
	</div>




	<div class="top-nav__item align-items-center">
		<i id="daterangeTop" class="zwicon zwicon-calendar"></i>

		<small class="icon_text">{{service.ServerDataDto.fromDate | date : "MM/dd/yy"}}</small>
	</div>

	<!-- zwicon-task -->
	<div class="top-nav__item align-items-center" (click)="SelectMenue('/app-views/lgCenter')">
		<i class="zwicon zwicon-earth-alt"></i>
		<small class="icon_text">lGCenter</small>
	</div>

	<div class="top-nav__item align-items-center" (click)="SelectMenue('/app-views/datalists')">
		<i class="zwicon zwicon-call-out"></i>
		<!-- (click)="LoadDailySchedule(2) -->
		<small class="icon_text">Telemarketing</small>
	</div>



</div>