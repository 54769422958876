import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  rols: any = [
    {
      RoleId: "89503FB6-EC80-4EDF-AB31-DAC5859287AA",
      RoleName: "Accountant",
      LoweredRoleName: "accountant",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "E163B520-EE75-45D6-B708-C7DCAA54B520",
      RoleName: "Admin",
      LoweredRoleName: "admin",
      SystemUser: 1,
      UserGroup: "Admin",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 1,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "06124057-2593-4FF6-B74F-6AC8F2CB7C31",
      RoleName: "Blocked Users",
      LoweredRoleName: "blocked users",
      SystemUser: 0,
      UserGroup: "Bloced",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 0,
    },
    {
      RoleId: "A803FB1C-00CC-4DF8-BA0B-F97F63A9345C",
      RoleName: "Canvasser",
      LoweredRoleName: "canvasser",
      SystemUser: 1,
      UserGroup: "Canvasser",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 1,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "9733E7CF-88D2-41CA-B067-CF876235287B",
      RoleName: "Canvasser-Manager",
      LoweredRoleName: "canvasser-manager",
      SystemUser: 1,
      UserGroup: "Canvasser",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 1,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "DCFD97BC-59DB-4865-81E4-DFE43FE6FA42",
      RoleName: "Confirmation",
      LoweredRoleName: "confirmation",
      SystemUser: 1,
      UserGroup: "Confirmation",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "F1CB5526-5F16-4AA6-A9E7-7491143223A4",
      RoleName: "Coordinator",
      LoweredRoleName: "coordinator",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "9C76112B-1359-4B50-B650-1CA6E38DB18D",
      RoleName: "Designer",
      LoweredRoleName: "designer",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "AEDFB98D-F185-41A4-8B55-772183614CA7",
      RoleName: "Dialer-Agent",
      LoweredRoleName: "dialer-agent",
      SystemUser: 1,
      UserGroup: "Marketing",
      Marketing: 0,
      TeleMarketing: 1,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "0E041C97-1A9F-4041-9A1B-D2C0DC735E12",
      RoleName: "Dialer-Manager",
      LoweredRoleName: "dialer-manager",
      SystemUser: 1,
      UserGroup: "Marketing",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "CD5927A0-DFD2-4257-9F7A-6F3F675A2ADE",
      RoleName: "Dispatch",
      LoweredRoleName: "dispatch",
      SystemUser: 1,
      UserGroup: "Dispatch",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "77A139BC-1E4D-43E9-AD34-052A4FA9C6FC",
      RoleName: "Field Sale Manager",
      LoweredRoleName: "field sale manager",
      SystemUser: 0,
      UserGroup: "",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "8D553187-B8DA-45E3-9CBC-FF8C8516669C",
      RoleName: "Helper",
      LoweredRoleName: "Helper",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "78644CDB-97A9-40A0-AC30-D2FE9CE91219",
      RoleName: "Inspector",
      LoweredRoleName: "Inspector",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "58F0E2BB-5F1A-4C48-B025-8C67BE807FA0",
      RoleName: "Installer",
      LoweredRoleName: "installer",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "BDC13B0F-E7A5-4D7B-8052-EA3A77891209",
      RoleName: "Lead Generator",
      LoweredRoleName: "lead generator",
      SystemUser: 0,
      UserGroup: "",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 1,
      Manager: 0,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "ABA5D397-C98F-4B4C-9171-9324CF911258",
      RoleName: "LGManager",
      LoweredRoleName: "lgmanager",
      SystemUser: 1,
      UserGroup: "",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 1,
      Manager: 1,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "DD394766-CD15-46D4-BEA3-E57040CAE9E5",
      RoleName: "Loan Officer",
      LoweredRoleName: "loan officer",
      SystemUser: 0,
      UserGroup: "",
      Marketing: 1,
      TeleMarketing: 1,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 0,
    },
    {
      RoleId: "9FE7BA29-59B3-4F84-B7E7-CFDC69D00C13",
      RoleName: "Manager",
      LoweredRoleName: "manager",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 1,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "E7C0191E-A3BC-44BB-9175-B0ECD5F127FF",
      RoleName: "Marketer",
      LoweredRoleName: "marketer",
      SystemUser: 1,
      UserGroup: "Marketing",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "FDEC91D6-7A3D-4455-90E1-358A91B8F197",
      RoleName: "NewUser",
      LoweredRoleName: "newuser",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "36C1A7F2-AB04-45DD-B99C-F6FD3A033C0B",
      RoleName: "Permit Runner",
      LoweredRoleName: "permit runner",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "7DA7DF80-DC13-439E-9321-FEF1441EC219",
      RoleName: "PhoneSaleAgent",
      LoweredRoleName: "phonesaleagent",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "C0B37C54-DDAA-4E83-8C7C-EA61AED14AB8",
      RoleName: "PhoneSaleManager",
      LoweredRoleName: "phonesalemanager",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "411BA1F7-D1CA-4182-9BAB-BA5DD5694B4E",
      RoleName: "Power User",
      LoweredRoleName: "power user",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "696C44CB-9369-496F-A577-2E18417F64AE",
      RoleName: "Processor",
      LoweredRoleName: "processor",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "652E702E-73B4-4BA4-8669-D5F48565AD3F",
      RoleName: "Project Admin",
      LoweredRoleName: "project admin",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "840844EA-62A9-4905-BFBC-01C8E8E0350B",
      RoleName: "Project-Manager",
      LoweredRoleName: "project-manager",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 1,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "A1C8C8E1-6117-40D9-9308-4761764DEB10",
      RoleName: "Provider",
      LoweredRoleName: "provider",
      SystemUser: 1,
      UserGroup: "Provider",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "8B38B5D0-2776-4F20-8FCA-97D1DA5717B4",
      RoleName: "ReadOnly",
      LoweredRoleName: "readonly",
      SystemUser: 1,
      UserGroup: "ReadOnly",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "541898CC-143F-4A29-A0F9-41B6FFA7D55B",
      RoleName: "Referral",
      LoweredRoleName: "referral",
      SystemUser: 1,
      UserGroup: "Referral",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "A04B6EAB-7C58-4698-8BC1-D3195F407725",
      RoleName: "Rehasher",
      LoweredRoleName: "rehasher",
      SystemUser: 1,
      UserGroup: "Dispatch",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "4EBA56BE-123B-4302-B4FD-2CD0C8A4CE9D",
      RoleName: "Sales Representative",
      LoweredRoleName: "sales representative",
      SystemUser: 1,
      UserGroup: "SalesRep",
      Marketing: 1,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "EC1656AA-3CC9-46EF-8B94-3B31A95F6435",
      RoleName: "ServiceCSR",
      LoweredRoleName: "servicecsr",
      SystemUser: 0,
      UserGroup: "ServiceSCR",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "840BB112-4E50-4F33-87D9-F42F509605FD",
      RoleName: "SetupInstaller",
      LoweredRoleName: "setupinstaller",
      SystemUser: 0,
      UserGroup: "SetupInstaller",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 1,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "DCC21C14-8B28-4DF5-B5F5-766E72C5D417",
      RoleName: "SubContractor",
      LoweredRoleName: "subcontractor",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 1,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "9D812FEB-19DC-4702-B628-A46F0AC6458C",
      RoleName: "System-Manager",
      LoweredRoleName: "system-manager",
      SystemUser: 1,
      UserGroup: "Production",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 1,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
    {
      RoleId: "98E3CA5B-4EC1-4DB3-A358-D8B2BAD4716D",
      RoleName: "Team",
      LoweredRoleName: "team",
      SystemUser: 1,
      UserGroup: "Dispatch",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 1,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 0,
    },
    {
      RoleId: "1E6C7550-B897-4FF0-B36D-F6E8C19471B1",
      RoleName: "Tech",
      LoweredRoleName: "tech",
      SystemUser: 1,
      UserGroup: "Marketing",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 1,
      SmsList: 1,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 1,
      ManualClockIn: 1,
    },
    {
      RoleId: "590974DD-7D75-48BE-9823-2443A9BE4B16",
      RoleName: "TvMonitor",
      LoweredRoleName: "tvmonitor",
      SystemUser: 0,
      UserGroup: "",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 1,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 0,
    },
    {
      RoleId: "E7AB8735-50D3-496E-B090-76FA1AC60F99",
      RoleName: "Warehouse/Driver",
      LoweredRoleName: "warehouse/driver",
      SystemUser: 0,
      UserGroup: "",
      Marketing: 0,
      TeleMarketing: 0,
      ProductionUser: 0,
      FieldUser: 0,
      SmsList: 0,
      CreateLead: 0,
      ViewInCallMap: 0,
      IsLg: 0,
      Manager: 0,
      ViewInTimeLine: 0,
      ManualClockIn: 1,
    },
  ];

  existingRole: any = {};
  constructor(public service: AppService,
	public router: Router) {
  
 
  }

  ngOnInit() {
  
	 
  }


  // showAdmin: boolean = false;
  // showReferal: boolean = false;
  // showDispatch: boolean = false;
  // showTelemarketer: boolean = false;
  // showHeader() {
	// //console.clear();
	// this.service.DevelopConsoleLog("this.service.CurentUserData.roleId", this.service.CurentUserData.roleId);
	// this.existingRole = this.rols.find(
	// 	(e) => e.RoleId.toLowerCase() == this.service.CurentUserData.roleId.toLowerCase()
	//   );

  //   this.showAdmin = false;
  //   this.showReferal = false;
  //   this.showDispatch = false;
  //   this.showTelemarketer = false;
	// this.service.DevelopConsoleLog(" showHeader this.existingRole",  this.existingRole);
  //   if (this.existingRole.RoleName == "Referral") {
  //     this.showReferal = true;
	//   this.router.navigate(["/app-views/lgCenter"]).then(() => {});
  //   } else if (this.existingRole.RoleName == "Admin") {
  //     this.showAdmin = true;
  //   } else if (this.existingRole.RoleName == "Dispatch") {
  //     this.showDispatch = true;
	//   this.router.navigate(["/dashboards/area"]).then(() => {});
  //   } else if (this.existingRole.RoleName == "Lead Generator" || this.existingRole.RoleName == "Marketer" || this.existingRole.RoleName == "Dialer-Manager") {
  //     this.showTelemarketer = true;
	//   this.router.navigate(["/app-views/datalists"]).then(() => {});
  //   } else {
  //     this.showAdmin = false;
  //     this.showReferal = false;
  //     this.showDispatch = false;
  //     this.showTelemarketer = false;
  //   }
  // }
}
