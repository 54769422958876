import { Component, OnInit, Input } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { timeStamp } from "console";
import { AppService } from "../../../app.service";
import { DataListsApiService } from "src/app/shared/apiServices/datalists/datalists.api.services";
import { EmployeeApiService } from "src/app/shared/apiServices/Employee/employee.api.services";
var moment = require("moment");
var moment = require("moment-timezone");
//const dateInput = new Date();



// import * as Rx from 'rxjs/Rx';

@Component({
  selector: "app-top-nav-dispatch",
  templateUrl: "./dispatch-top-nav.component.html",
  styleUrls: ["./dispatch-top-nav.component.scss"],
})
export class DispatchTopNavComponent implements OnInit {
  timeZone:any;
  EventSubWatcher: any;
  // Order Notifications
  orderNotifications: any = [
    {
      title: "New sales order received",
      status: "unread",
      id: "#204",
      time: "10 minutes ago",
      icon: "&#xea6d;",
      color: "red",
    },
    {
      title: "New user account registered",
      status: "unread",
      id: "#3102",
      time: "23 minutes ago",
      icon: "&#xea7d;",
      color: "green",
    },
    {
      title: "New product review received",
      status: "read",
      id: "#8973",
      time: "48 minutes ago",
      icon: "&#xea4e;",
      color: "blue",
    },
    {
      title: "New product review received",
      status: "read",
      id: "#8972",
      time: "50 minutes ago",
      icon: "&#xea4e;",
      color: "blue",
    },
    {
      title: "New sales order received",
      status: "read",
      id: "#203",
      time: "55 minutes ago",
      icon: "&#xe9fb;",
      color: "red",
    },
    {
      title: "Review unpaid orders",
      status: "read",
      id: "#765",
      time: "2 hours ago",
      icon: "&#xea46;",
      color: "amber",
    },
  ];

  // Support notifications
  supportNotifications: any = [
    {
      title: "New issue filed by customer",
      status: "unread",
      id: "#721",
      time: "10 seconds ago",
      icon: "&#xea2b;",
      color: "blue",
    },
    {
      title: "New comment received",
      status: "unread",
      id: "#976",
      time: "5 minutes ago",
      icon: "&#xea45;",
      color: "lime",
    },
    {
      title: "Thread responded and closed",
      status: "unread",
      id: "#45",
      time: "2 hours ago",
      icon: "&#xea47;",
      color: "orange",
    },
    {
      title: "Thread re-opened by administrator",
      status: "unread",
      id: "#8972",
      time: "5 hours ago",
      icon: "&#xea2a;",
      color: "purple",
    },
    {
      title: "New support request received",
      status: "read",
      id: "#432",
      time: "10 hours ago",
      icon: "&#xea28;",
      color: "deep-orange",
    },
    {
      title: "New issue filed by customer",
      status: "read",
      id: "#720",
      time: "2 days ago",
      icon: "&#xea2b;",
      color: "blue",
    },
  ];

  // Mails
  mails: any = [
    {
      image: "15.jpg",
      user: "David Belle",
      content: "Cum sociis natoque penatibus et magnis dis parturient montes",
      time: "10:02 AM",
      status: "unread",
    },
    {
      cap: "AW",
      color: "red",
      user: "Aaron Warner",
      content:
        "Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel",
      time: "02:30 PM",
      status: "read",
    },
    {
      cap: "FM",
      color: "blue",
      user: "Fredric Mitchell Jr.",
      content:
        "Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies",
      time: "Tue",
      status: "read",
    },
    {
      image: "12.jpg",
      user: "Glenn Jecobs",
      content:
        "Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque",
      time: "Mon",
      status: "unread",
    },
    {
      image: "11.jpg",
      user: "Bill Phillips",
      content:
        "Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat",
      time: "Fri",
      status: "unread",
    },
    {
      image: "4.jpg",
      user: "Alice Margit",
      content: "Aenean lacinia bibendum nulla sed consectetur",
      time: "May 10",
      status: "read",
    },
    {
      cap: "VH",
      color: "amber",
      user: "Van Hasnon",
      content: "Nullam iolor ieonibh ultricies vehicula magna",
      time: "May 09",
      status: "unread",
    },
    {
      cap: "IA",
      color: "purple",
      user: "Iason Antonius",
      content:
        "Maecenas faucibus mollis interdum Maecenas sed diam eget risus varius blandit sit amet non magna",
      time: "May 08",
      status: "read",
    },
  ];

  // Tasks
  tasks = [
    {
      name: "Page speed test for parent website",
      completed: 95,
      color: "blue",
    },
    {
      name: "Project version 3.2.1 release",
      completed: 80,
      color: "green",
    },
    {
      name: "Severe bug fixes",
      completed: 20,
      color: "amber",
    },
    {
      name: "UI design for proposed landing page",
      completed: 60,
      color: "red",
    },
    {
      name: "Blog article for the month of August",
      completed: 80,
      color: "cyan",
    },
    {
      name: "August newsletter",
      completed: 32,
      color: "teal",
    },
    {
      name: "Github issue #3241",
      completed: 82,
      color: "purple",
    },
    {
      name: "Github issue #3118",
      completed: 45,
      color: "pink",
    },
  ];

  // Languages
  languages: any = {
    current: "English",
    list: [
      {
        language: "French",
        flag: "france",
      },
      {
        language: "German",
        flag: "germany",
      },
      {
        language: "Japanese",
        flag: "japan",
      },
      {
        language: "Chinese",
        flag: "china",
      },
    ],
  };

  myStatusDialog: boolean = false;

  viewStartDate: Date = new Date();
  userRoleId:string = '';
  constructor(private router: Router,
    public service: AppService, 
    public employeeApiService: EmployeeApiService,
    public vdataListsApiService: DataListsApiService) {


      this.userRoleId =  service.CurentUserData.roleId;

      this.timeZone = moment.tz.guess();
    let d: Date = new Date(); // moment(this.getSunday(this.viewStartDate)).add(1, 'day').toDate();

    let cd: Date = new Date();

    // if (d.getDate() == cd.getDate()) {
    // 	var cwm = new Date(d.setDate(d.getDate() - d.getDay() - 6)); // Get monday of current week
    // 	d = cwm; // assign monday as from date
    // }
    this.service.UtcPickerData = {
      fromDate: d,
      toDate: cd,
    };

    this.service.pickerPostData = {
      fromDate: this.service.Moment(d).format("MM/DD/YYYY HH:mm:ss"),
      toDate: this.service.Moment(cd).format("MM/DD/YYYY HH:mm:ss"),
    };

    // const today = new Date();
    // const yesterday = new Date(today);

    // yesterday.setDate(yesterday.getDate() - 1);

    // //this.service.DevelopConsoleLog('viewStartDate1', this.viewStartDate );
    // var moment = require("moment-timezone");
    // //const dateInput = new Date();
    // const timeZone = moment.tz.guess();
    // //this.service.DevelopConsoleLog('dateInput', dateInput);
    // //this.service.DevelopConsoleLog('timeZone', timeZone);
    // this.viewStartDate = moment(yesterday).tz(timeZone).toDate(); //.format('hh:mm:ss a'), 'hh:mm:ss a');
    // this.service.DevelopConsoleLog("viewStartDate2", this.viewStartDate);
    // this.service.UtcPickerData = {
    //   fromDate: this.viewStartDate,
    //   toDate: this.viewStartDate,
    // };

    // this.service.ServerDataDto = {
    //   fromDate: this.viewStartDate.toISOString(),
    //   toDate: this.viewStartDate.toISOString(),
    // };

    this.service.ServerDataDto = {
      fromDate: this.service.Moment(d).format("MM/DD/YYYY HH:mm:ss"),
      toDate: this.service.Moment(cd).format("MM/DD/YYYY HH:mm:ss"),
    };

    this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);

    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationStart) this.myStatusDialog = false;
    });




 
 






    // this.callsService.GetTwilioVoiceAccessToken().then((res) => {
    //   if (res.status == 1) {
    //     this.twilioAccessToken = res.token;
    //     this.sysService.App.SetData("twilioAccessToken", this.twilioAccessToken);
    //     // Set up the Twilio Client Device with the token
    //     //this.service.DevelopConsoleLog('this.twilioAccessToken', this.twilioAccessToken); 

    //     window["toastr"]["info"]('Twilio starting connection', 'Twilio Voice Access Token' || "Twilio Voice Access Token");


    //     if (this.twilioAccessToken != '' && this.twilioAccessToken !== null) {

    //       var rs = this.sysService.Twilioclient.Device.setup(this.twilioAccessToken, { debug: true, enableRingingState: true, answerOnBridge: true });
    //       // window["toastr"]["info"](this.twilioAccessToken, 'twilioAccessToken' || "twilioAccessToken");



    //       this.sysService.Twilioclient.Device.ready((device) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "ready";
            
    //       });

    //       this.sysService.Twilioclient.Device.error((error) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "error";
         
    //       });

    //       this.sysService.Twilioclient.Device.connect((conn) => {
    //         this.sysService.incallstatus = true;
    //         this.sysService.TwilioStatus = "connect";
            
    //       });

    //       this.sysService.Twilioclient.Device.disconnect((conn) => {
    //         this.sysService.incallstatus = false; 
    //         this.sysService.TwilioStatus = "disconnect";
            
    //       });

    //       this.sysService.Twilioclient.Device.incoming((conn) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "incoming";
            
    //       });


    //     }


    //   }
    // }).catch((err) => {
    //   window["toastr"]["info"]('Twilio starting connection', 'ERROR GETTING Twilio Voice Access Token' || "ERROR GETTING Twilio Voice Access Token");
    //   this.sysService.DevelopConsoleLog('this.GetTwilioVoiceAccessToken error', err);

    // });


    //this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);
  }
  ngOnInit() {
    this.userRoleId =  this.service.CurentUserData.roleId;
    this.getCustomDataLists();
    this.GetDataListNames();
    this.twillioDeviceSetUp();
    this.GetRoles();
    this.eventChangeSubscribe()
    //console.clear();
    //this.service.DevelopConsoleLog("UserDaCurentUserDatata",this.service.CurentUserData)

     this.loadDatePicker();
    //  var ClassItem = this.service.Data.BuildObjectClassItems('GetRoles',this.service.CurentUserData);
    //  this.service.DevelopConsoleLog("ClassItem CurentUserData", ClassItem);
      
  }

  eventChangeSubscribe()
  {
    this.EventSubWatcher = this.service.App.onGlobalDateChange.subscribe((obj) => {
      const startdate = moment(this.service.UtcPickerData.fromDate)
      .tz(this.timeZone)
      .toDate();
     

      $("#daterangeTop")
      .data("daterangepicker")
      .setStartDate(new Date(startdate));

      $("#daterangeTop")
      .data("daterangepicker")
      .setEndDate(new Date(startdate))
    });
  }

  twillioDeviceSetUp(){
    var CurentUserData = this.service.CurentUserData;  
    //this.service.DevelopConsoleLog("callConnect CurentUserData",  CurentUserData);
    //this.service.DevelopConsoleLog("callConnect twillioAcessToken",  CurentUserData.twillioAcessToken);
    if(CurentUserData.twillioAcessToken != undefined){
      //this.service.DevelopConsoleLog("callConnect d.twillioAcessToken",  CurentUserData.twillioAcessToken);
      this.service.Twilioclient.Device.setup(CurentUserData.twillioAcessToken, { debug: true, enableRingingState: true, answerOnBridge: true });
      
      //this.LockScreen()
    }
    else{
      this.LockScreen()
    }
  }


  loadDatePicker() {
    // ranges: {
    // 	Today: [moment(), moment()],
    // 	Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
    // 	'Two Days ago': [moment().subtract(2, 'day'), moment().subtract(2, 'day')]
    // 	'This Week': [
    // 		moment(new Date(this.getSunday(this.viewStartDate))).add(1, 'day') ,
    // 		moment()
    // 	],
    // 	'Last Week': [
    // 		moment(new Date(this.getSunday(this.viewStartDate)))
    // 			.subtract(6, 'days') ,
    // 		moment(new Date(this.getSunday(this.viewStartDate)))

    // 	],
    // 	'Last 7 Days': [
    // 		moment().subtract(6, 'days'),
    // 		moment()
    // 	],
    // 	'This Month': [
    // 		moment().startOf('month'),
    // 		moment().endOf('month')
    // 	],
    // 	'Last Month': [
    // 		moment().subtract(1, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 2 Months': [
    // 		moment().startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'2 Months ago': [
    // 		moment().subtract(2, 'month').startOf('month'),
    // 		moment().subtract(2, 'month').endOf('month')
    // 	],
    // 	'3 Months ago': [
    // 		moment().subtract(3, 'month').startOf('month'),
    // 		moment().subtract(3, 'month').endOf('month')
    // 	],
    // 	'Last 3 Months': [
    // 		moment().subtract(3, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 6 Months': [
    // 		moment().subtract(6, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 9 Months': [
    // 		moment().subtract(9, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 12 Months': [
    // 		moment().subtract(12, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 24 Months': [
    // 		moment().subtract(24, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 3 years': [
    // 		moment().subtract(36, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	]
    // }
    // need to be in EST as defult

    // //https://www.daterangepicker.com/#options

    // startDate: this.service.UtcPickerData?.fromDate  || this.viewStartDate,
    // endDate: this.service.UtcPickerData?.toDate || this.viewStartDate,

    $("#daterangeTop")["daterangepicker"](
      {
        autoApply: true,
        singleDatePicker: true,
        timePicker: false,
        autoUpdateInput: true,
        showDropdowns: false,
        startDate: new Date(this.service.ServerDataDto.fromDate),
        endDate: new Date(this.service.ServerDataDto.fromDate),
      },
      (x, d) => {
        // this.service.pickerPostData = {
        // 	fromDate: this.service.Moment(x._d).format('MM/DD/YYYY HH:mm:ss'),
        // 	toDate: this.service.Moment(d._d).format('MM/DD/YYYY HH:mm:ss')
        // };

        // this.service.registerEvent('GLOBALDATESKC',this,(args:any)=>{

        //  })

        this.service.pickerPostData = {
          fromDate: x._d,
          toDate: d._d,
        };
        this.service.UtcPickerData = {
          fromDate: x._d,
          toDate: d._d,
        };

        this.service.ServerDataDto = {
          fromDate: new Date(x._d).toISOString(),
          toDate: new Date(d._d).toISOString(),
        };


        this.service.SelectedEpochDate = new Date(x._d).getTime()
        this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);
      }
    );

    // $("#daterangeTop")
    //   .data("daterangepicker")
    //   .setStartDate(new Date(this.service.ServerDataDto.fromDate));

    //   $("#daterangeTop")
    //   .data("daterangepicker")
    //   .setStartDate(new Date(this.service.ServerDataDto.fromDate));

    // console.clear();
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + 1; // + (day == 0 ? -6 : 1) adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  LockScreen() {
    //
    //this.service.DevelopConsoleLog("LockScreen Caled");

    var frlnk =
    this.service.qdispatchDomain + "/SignOut.aspx";
    this.service.App.LoadMainIframe.emit({visible: true,src: frlnk}); 

    this.service.LockedApp = true;
    this.service.App.onOpenTopSlide.emit({ type: "OpenlockedScreenComponent" });
    localStorage.setItem("LockedApp", "1");
    this.service.logOut();
  }

 
  SelectMenue(page:string) {
    this.router.navigate([page], {});
 
  }
selecteddl:number=1;
  setDatalistSurce(src:number) {

    this.selecteddl = src;
    this.filterDatalists =[];
    if(src == 0){
      this.getCustomDataLists();
      this.GetDataListNames();
    this.selecteddl = 1;
    }
    else if(src == 1){
      this.filterDatalists = this.Datalists ;
    }
    else{
      this.filterDatalists = this.customDatalists;
    }

  }
  customDatalists:any = [];
  Datalists:any = [];
  filterDatalists:any = [];
getCustomDataLists() {
  
  //
  this.customDatalists =[];
  this.filterDatalists =[];
      this.vdataListsApiService
        .GetCustomDataListNames()
        .then((data) => { 
          this.customDatalists = data;
          //this.service.DevelopConsoleLog("getDataLists GetCustomDataListNames", data);
          
        });
  
  }


  GetDataListNames() {
  
    //GetCustomDataListNames
    this.filterDatalists =[];
    this.Datalists =[];
        this.vdataListsApiService
          .GetDataListNames()
          .then((data) => { 
            this.Datalists = data;
            this.filterDatalists = this.Datalists ;
            //this.service.DevelopConsoleLog("getDataLists GetCustomDataListNames", data);
            
          });
    
    }


    GetRoles() {
      this.employeeApiService
        .GetRoles()
        .then((data) => {
        this.service.cRoles = data;
       // this.service.BuildObjectItems ()
        // var ClassItem = this.service.Data.BuildObjectClassItems('GetRoles',this.service.cRoles[0]);
        // this.service.DevelopConsoleLog("ClassItem cRoles", ClassItem);
        // this.service.DevelopConsoleLog("GetRoles cRoles", this.service.cRoles);
        
    
         });
      }


      public LoadDailySchedule(n:number):void {
        try {
          var params = [
            "height=" + screen.height,
            "width=" + screen.width,
            "fullscreen=yes",
          ].join(",");
          var frlnk =
            this.service.qdispatchDomain + "/Control/Dispatch/DailySchedule.aspx?rwndrnd=1"
    
            if(n==0){
              frlnk =
            this.service.qdispatchDomain + "/Control/Marketing/MarketingCalendar.aspx?status=veiwall&rwndrnd=1"
            }

            if(n==2){
              frlnk =
            this.service.qdispatchDomain + "/Control/DataLists/ADataLists.aspx?loadTop=1&rwndrnd=0.6787506018102656"
            }


            this.service.App.LoadMainIframe.emit({visible: true, src: frlnk}); 

          // var popup = window.open(frlnk, "DailySchedule", params);
          // popup.moveTo(0, 0);
        } catch (x) {
          this.service.DevelopConsoleLog("LoadDailySchedule", x);
        }
      }


  public LoadTwmlDail(item: any) {
    try {
      var params = [
        "height=" + screen.height,
        "width=" + screen.width,
        "fullscreen=yes",
      ].join(",");
      var frlnk =
        this.service.qdispatchDomain + "/ConProUi/Twml/TwmlDail.aspx?DataListVarId=" +
        item.DataListVarId +   "&CallBtn=1";
       this.service.App.LoadMainIframe.emit({visible: true,src: frlnk}); 
      // var popup = window.open(frlnk, "TwmlDail", params);
      // popup.moveTo(0, 0);
    } catch (x) {
      this.service.DevelopConsoleLog("LoadDailyScheduleGroup", x);
    }
  }

  ngOnDestroy()
{
  if(this.EventSubWatcher)
    this.EventSubWatcher.unsubscribe()
}

}

