import { Component, OnInit } from '@angular/core';
import { AppService } from "src/app/app.service";

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
	userInfo: any = {
		name: 'Malinda Hollaway',
		email: 'mailnda-h@beyonder.xom',
		img: './assets/demo/img/contacts/1.jpg'
	};

	constructor(public service: AppService) {



	}

	ngOnInit() {

		
		
		

		// accountGuid
		// : 
		// "09128a43-e6e7-4f1e-ad81-265a05e9f0a9"
		// applicationId
		// : 
		// "ba0b7cdd-a573-4d55-9e3d-d64f2141ca35"
		// firstName
		// : 
		// ""
		// imageUrl
		// : 
		// "../../Images/Accounts/3c550629-f9d5-4f2c-9db4-31c0765c4a6a/Users/15f92839-4ccb-4550-9ec0-86753b9a1d11.png"
		// lastName
		// : 
		// ""
		// lineName
		// : 
		// "Admin"
		// pageVarId
		// : 
		// "login"
		// phoneNumber
		// : 
		// "8188095705"
		// role
		// : 
		// "Admin"
		// roleId
		// : 
		// "e163b520-ee75-45d6-b708-c7dcaa54b520"
		// userGuid
		// : 
		// "15f92839-4ccb-4550-9ec0-86753b9a1d11"

		 //this.service.DevelopConsoleLog('d CurentUserData',this.service.CurentUserData);
		var d = this.service.CurentUserData; 
		if(d == null){
			this.UserLogOut()
		}
		//this.service.DevelopConsoleLog('CurentUserData',d);
		//d.imageUrl.replace('../../','https://www.qdtext.com/')
		this.userInfo  ={
			name: d.lineName,
			role: d.role,
			email: d.email,
			imageUrl: d.imageUrl ==this.service.qdispoAgentImageUrl ? './assets/demo/img/contacts/no_usr.png' : d.imageUrl,
			userIdVarID:d.userIdVarID,
			cellPhone:d.cellPhone
		};

	}

	public UserLogOut() {
		
		if(this.userInfo.role != "Admin"){
			var frlnk =
			this.service.qdispatchDomain + "/SignOut.aspx";
	
			this.service.DevelopConsoleLog('LogOut',frlnk); 
			this.service.App.LoadMainIframe.emit({visible: true,src: frlnk}); 
		  
		  }


		this.service.LockedApp = true;
		this.service.App.onOpenTopSlide.emit({ type: "OpenlockedScreenComponent" });
		localStorage.setItem("LockedApp", '1');
		 
		this.service.logOut();

	}

 


}
