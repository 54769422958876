import { Injectable } from "@angular/core";
import { AppService } from "../../../app.service";
import { HttpClient } from "@angular/common/http";
 
@Injectable({
  providedIn: "root",
})
export class URACApiService {
  constructor(public service: AppService, public http: HttpClient) {}

  

  GetUserAccesses() { 
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Admin/UserAccess/GetUserAccesses"
    ); 
  }


  GetLeadMopComponents() { 
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Admin/UserAccess/GetLeadMopComponents"
    ); 
  }


    
  GetComponentUserAccesses() { 
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Admin/UserAccess/GetComponentUserAccesses"
    ); 
  }
  SetComponentDisply(urcSetting:any) { 
    return this.service.Data.ExecuteAPI_Post<Array<any>>(
      "Admin/UserAccess/SetComponentDisply",urcSetting
    ); 
  }


  // GETSMSByUser(userGuid:string) { 
  //   return this.service.Data.ExecuteAPI_Get<Array<any>>(
  //     "Data/SMS/GETSMSByUser?selectedDate=" +
  //       this.service.ServerDataDto.fromDate +
  //       "&userGuid=" + userGuid
  //   ); 
  // }


  // GetSMSHistory(CustomerPhone:string) { 
  //   return this.service.Data.ExecuteAPI_Get<Array<any>>(
  //     "Data/SMS/GetSMSHistory?CustomerPhone=" +
  //     CustomerPhone  
  //   ); 
  // }
  
  // GETCustomerDataLists(CustomerPhone:string) { 
  //   return this.service.Data.ExecuteAPI_Get<Array<any>>(
  //     "Data/SMS/GETCustomerDataLists?CustomerPhone=" +
  //     CustomerPhone  
  //   ); 
  // }

}
