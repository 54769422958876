import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from "src/app/app.service";
@Pipe({
  name: 'phone'
})
export class formatPhonePipe implements PipeTransform { 
  transform(value: any): any {
    if (!value) {
      return value;
    }

    return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
 
  }

}
