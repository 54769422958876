import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppService } from "src/app/app.service";
@Component({
	selector: "app-avatar",
	templateUrl: "./avatar.component.html",
	styleUrls: ["./avatar.component.scss"]
})
export class AvatarComponent implements OnInit {
	@Input() color: string;
	@Input() cap: string;
	@Input() type: any;
	@Input() id: any;
	@Input() img: any;
	@Input() online: boolean;
	@Input() size: number = 36;
	@Input() glyph: string;
	@Input() glyphTheme: string = "light"; // or dark

	@Output() onclick = new EventEmitter();

	constructor(public service: AppService) {
	 
	}

	ngOnInit(): void {
		if(this.img == this.service.qdispoAgentImageUrl)
		{
		   this.img = './assets/demo/img/contacts/no_usr.png';
		}

		if(this.img != null && this.img != undefined)
		{
			//this.img = this.img.replace('../../','https://www.qdtext.com/');
			//this.img = this.img.replace('../../','https://www.qdispo.com/');
			//this.img = this.img.replace('../../','https://www.largecrm.com/');
		}

		//
		// this.service.DevelopConsoleLog("id",this.id);
		// this.service.DevelopConsoleLog("color",this.color);
		// this.service.DevelopConsoleLog("cap",this.cap);
		// this.service.DevelopConsoleLog("img",this.img);
		// this.service.DevelopConsoleLog("type",this.type);
		// this.service.DevelopConsoleLog("size",this.size);
	}
}
