 

<div class="top-nav d-flex">
	<!-- Mobile search toggle icon -->
	<div class="top-nav__item d-md-none">
		<i class="zwicon zwicon-search" (click)="service.searchToggled = true"></i>
	</div>
	<!-- End mobile search toggle icon -->

	<!-- Notifications -->


	<!-- <div class="d-flex date-range-picker date-picker" style="margin-top:5px;">

		<div class="align-self-starts w-100 d-flex align-items-center">
			


		</div>
		 <div class="ml-auto">
			<i class="zwicon zwicon-chevron-down date-arrow" style="font-size: 4vh;"></i>
		</div>  

	</div> -->


	<!-- <div class="top-nav__item  ">
		{{this.viewStartDate}}
	</div>
	<div class="top-nav__item  ">
		{{ this.service.ServerDataDto.fromDate }} </div> -->


	<!-- 
	<div class="top-nav__item  ">
		  <span id="daterangeTop" style="background-color: transparent;border-color:transparent !important;  
			border-width: 0px !important; border: none !important; cursor: pointer;" readonly style="margin-top:5px;" ><i class="zwicon zwicon-calendar" ></i></span> 
 

	</div> -->

 
	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-lock" (click)="LockScreen()"></i>
		<small class="icon_text">Lock</small>
	</div>


	<div class="top-nav__item align-items-center"  >
		<i id="daterangeTop" class="zwicon zwicon-calendar"></i>

		<small class="icon_text">{{service.ServerDataDto.fromDate | date : "MM/dd/yy"}}</small>
	</div>



	<!-- -->
	<div class="top-nav__item align-items-center"  >
		<i class="zwicon zwicon-cell-full"  (click)="LoadDailySchedule(1)"></i>
		<small class="icon_text">Appts</small>
	</div>

	<div class="top-nav__item align-items-center"  >
		<i class="zwicon zwicon-cell-empty"  (click)="LoadDailySchedule(0)"></i>
		<small class="icon_text">Leads</small>
	</div>


 
	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-grid" (click)="SelectMenue('/dashboards/area')"></i>
		<small class="icon_text">Area</small>
	</div>
	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-users" (click)="SelectMenue('/app-views/team')"></i>
		<small class="icon_text">Team</small>
	</div>
	<!-- zwicon-task -->
	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-call-out" (click)="SelectMenue('/app-views/datalists')"></i>
		 <!-- (click)="LoadDailySchedule(2) -->
		<small class="icon_text">Telemarketing</small>
	</div>


	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-earth-alt" (click)="SelectMenue('/app-views/lgCenter')"></i>
		<small class="icon_text">LGCenter</small>
	</div>


	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-persona" (click)="SelectMenue('/app-views/contacts')"></i>
		<small class="icon_text">Contacts</small>
	</div>


	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-headphone" title="outbound calls reports"
			(click)="SelectMenue('/admin/setting/twilio/calls')"></i>
			<small class="icon_text">Calls</small>
	</div>


	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-play-alt" title="calls recordings"
			(click)="SelectMenue('/admin/setting/twilio/recording')"></i>
			<small class="icon_text">Recordings</small>


	</div>

	<div class="top-nav__item align-items-center">
		<i class="zwicon zwicon-phone" title="outbound DID use reportd"
			(click)="SelectMenue('/admin/setting/twilio/numbers')"></i>
			<small class="icon_text">DID</small>

	</div>

 

 
	<!-- Tasks -->
	<div class="top-nav__item d-none d-sm-block align-items-center" ngbDropdown *ngIf="this.service.IsDevelopment == true">
		<i class="zwicon zwicon-task" ngbDropdownToggle></i>
		<small class="icon_text">Tasks</small>
		<div ngbDropdownMenu>
			<div class="bg-gradient-cyan rounded-1 p-2 text-white d-flex align-items-center mb-1">
				<div class="text-center flex-grow-1 ps-9">
					Data Lists
					<a class="btn btn-default" (click)="setDatalistSurce(2)" [class.active]="selecteddl==2">
						Custom
					</a>
					<a class="btn btn-default" (click)="setDatalistSurce(1)" [class.active]="selecteddl==1">
						Marketing
					</a>

					<a class="btn btn-default" (click)="setDatalistSurce(0)">
						<i class="zwicon fs-5 zwicon-refresh-double"></i> Refresh
					</a>
				</div>

				<!-- <div ngbDropdown placement="bottom-right">
					<button type="button" ngbDropdownToggle
						class="icon bg-white-10-hover zwicon zwicon-more-v text-white">
						<span class="visually-hidden">More</span>
					</button>
					<div ngbDropdownMenu>
						<a  ngbDropdownItem>
							<i class="zwicon zwicon-window"></i>
							Open tasks
						</a>
						<a   ngbDropdownItem>
							<i class="zwicon zwicon-checkmark-circle"></i>
							 Completed tasks
						</a>
						<a   ngbDropdownItem>
							<i class="zwicon zwicon-cog"></i>
							My Tasks
						</a>
					</div>
				</div> -->
			</div>
			<ng-scrollbar visibility="hover">
				<!-- <a   *ngFor="let task of customDatalists"
					class="d-flex align-items-center p-2.5 rounded-2 bg-100-hover">
					<div class="flex-grow-1">
						<div class="text-headings fw-bold mb-2">{{ task.ListName }}</div>
						<ngb-progressbar [type]="task.color" [value]="task.soldCalls"></ngb-progressbar>
					</div>
				</a> -->

				<div class="d-flex align-items-center p-1 rounded-2 bg-100-hover" *ngFor="let task of filterDatalists">
					<div class="flex-grow-1" (click)="LoadTwmlDail(task)">
						<div class="d-flex">
							<div title="" class="flex-grow-1 ms-1 fs-5 fw-bold mb-2">
								{{ task.ListName }} </div>
							<div title=" " class="ms-1 fs-5 fw-bold mb-2"><i class="zwicon fs-5 zwicon-phone"></i>
								{{ task.totalLeads }}</div>
							<div title=" " class="ms-1 fs-5 fw-bold mb-2"><i class="fs-5 zwicon zwicon-history"></i>
								{{ task.totalCalls }}</div>
							<div title=" " class="ms-1 text-end mb-2 d-none d-sm-inline w-11 text-green"><i
									class="fs-5 zwicon zwicon-checkmark-circle"></i> {{ task.soldCalls }} </div>
						</div>
						<ngb-progressbar [type]="task.color" [value]="task.soldCalls"></ngb-progressbar>
					</div>
				</div>

			</ng-scrollbar>



		</div>
	</div>
	<!-- End tasks -->

	<!-- Language -->
	<div class="top-nav__item d-none d-sm-block" ngbDropdown *ngIf="this.service.IsDevelopment == true">
		<i ngbDropdownToggle>
			<img class="w-6 rounded-1" [src]="['./assets/demo/img/languages/' + languages.current + '.png']"
				alt="English" />
		</i>

		<div ngbDropdownMenu>
			<a ngbDropdownItem routerLink="./" *ngFor="let item of languages.list">
				<img class="w-6 me-2 rounded-1" [src]="['./assets/img/flags/' + item.flag + '.png']" alt="" />
				{{ item.language }}
			</a>
		</div>
	</div>
	<!-- End language -->
</div>