<header class="header">


	<!-- <app-header-toggle>mobile menuw </app-header-toggle> -->

	<app-search class="search" [class.search--toggled]="service.searchToggled"></app-search>

 

		

	<div class="header__loader text-primary spinner-border" [class.active]="service.isLoading"></div>

	<!-- <app-top-nav></app-top-nav>  -->
	<!-- -->

	<app-top-nav-admin></app-top-nav-admin> 

 <!-- <app-top-nav-admin  *ngIf="showAdmin == true"></app-top-nav-admin> 
  <app-top-nav-referral  *ngIf="showReferal == true" ></app-top-nav-referral> 
  <app-top-nav-dispatch  *ngIf="showDispatch == true" ></app-top-nav-dispatch>
  <app-top-nav-lg *ngIf="showTelemarketer == true" ></app-top-nav-lg> -->

	<app-user></app-user>
</header>


 