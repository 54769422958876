import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "src/app/app.service";
import { EmployeeApiService } from "src/app/shared/apiServices/Employee/employee.api.services";
@Pipe({
  name: "varIdDecript",
})
export class varIdDecriptPipe implements PipeTransform {
  constructor(public service: AppService) {}

  transform(labelVarID: string, args?: any): any {
 
    //return "1111"
    if(args == null){
      //args = "userName" varIdDecript : "referral", varIdDecript : "company"
      return labelVarID;
    }
    this.service.DevelopConsoleLog("varIdDecriptPipe  " + labelVarID , args);

    this.service.DevelopConsoleLog("varIdDecriptPipe  " + labelVarID , this.service.AllReferalsDB);

    if(args == "referral"){
      var ref = this.service.AllReferalsDB.find(
        (e) => e.ReferralVarID.toLowerCase() == labelVarID.toLowerCase()
        || e.ContractorVarID.toLowerCase() == labelVarID.toLowerCase()
      );
 
      if (ref != null) {
        return ref.Name;
        //	return emp.firstName + " " + emp.lastName;
      } else return labelVarID;
    }
    if(args == "company"){
      var ref = this.service.AllReferalsDB.find(
        (e) => e.ReferralVarID.toLowerCase() == labelVarID.toLowerCase()
        || e.ContractorVarID.toLowerCase() == labelVarID.toLowerCase()
      );
 
      if (ref != null) {
        return ref.Company;
        //	return emp.firstName + " " + emp.lastName;
      } else return labelVarID;
    }
    else return labelVarID;



// 	Address
// : 
// ""
// AltEmailAddress
// : 
// ""
// Caps
// : 
// "an"
// CellPhone
// : 
// "8188095705"
// City
// : 
// ""
// EmployeeVarID
// : 
// "10f98eeb38d41611"
// FullName
// : 
// ""
// ImageUrl
// : 
// "https://www.qdispocc.com/UserImage.aspx?empvr="
// LoweredUserName
// : 
// "angular"
// Position
// : 
// 21
// Positiontxt
// : 
// "Marketer"
// RoleId
// : 
// "89503fb6-ec80-4edf-ab31-dac5859287aa"
// RoleName
// : 
// "Accountant"
// State
// : 
// ""
// UserId
// : 
// "63c36541-4500-4927-a6cd-83a98785eb97"
// UserName
// : 
// "angular"
// usernumber
// : 
// 4757
// zip
// : 
// ""

    // if (isNaN(num)) return null; // will only work value is a number
    // if (num === null) return 0;
    // if (num === 0) return 0;

    // if (num.toString().includes(',')) {
    // 	num = parseFloat(num.toString().replace(/\,/g, ''));
    // }

    // let abs = Math.abs(num);
    // const rounder = Math.pow(10, 1);
    // const isNegative = num < 0; // will also work for Negetive numbers
    // let key = '';

    // const powers = [
    // 	{ key: 'Q', value: Math.pow(10, 15) }, // quadrillion
    // 	{ key: 'T', value: Math.pow(10, 12) }, // Trillion
    // 	{ key: 'B', value: Math.pow(10, 9) }, // Billion
    // 	{ key: 'M', value: Math.pow(10, 6) }, // Million
    // 	{ key: 'K', value: 1000 } // Thousand
    // ];

    // for (const i of powers) {
    // 	let reduced = abs / i.value;
    // 	reduced = Math.round(reduced * rounder) / rounder;
    // 	if (reduced >= 1) {
    // 		abs = reduced;
    // 		key = i.key;
    // 		break;
    // 	}
    // }
    //return (isNegative ? '-' : '') + abs + key;
  }
}
