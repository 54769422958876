import { Component, OnInit, Input } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { timeStamp } from "console";
import { AppService } from "../../../app.service";
import { DataListsApiService } from "src/app/shared/apiServices/datalists/datalists.api.services";
import { EmployeeApiService } from "src/app/shared/apiServices/Employee/employee.api.services";
import { URACApiService } from "src/app/shared/apiServices/urac/urac.api.services";

import { ReferalsApiService } from "src/app/shared/apiServices/referals/referals.api.services";
var moment = require("moment");
var moment = require("moment-timezone");
//const dateInput = new Date();

// import * as Rx from 'rxjs/Rx';

@Component({
  selector: "app-top-nav-admin",
  templateUrl: "./admin-top-nav.component.html",
  styleUrls: ["./admin-top-nav.component.scss"],
})
export class AdminTopNavComponent implements OnInit {
  timeZone: any;
  EventSubWatcher: any;
  // Order Notifications

  // {
  //   page: "Recording",
  //   url: "/admin/setting/twilio/recording",
  //   icon:"zwicon-play-alt"
  // },

  myStatusDialog: boolean = false;

  viewStartDate: Date = new Date();
  userRoleId: string = "";
  constructor(
    private router: Router,
    public service: AppService,
    public employeeApiService: EmployeeApiService,
    public uracApiService: URACApiService,
    public _vReferalsApiService: ReferalsApiService,
    public vdataListsApiService: DataListsApiService
  ) {
    this.userRoleId = service.CurentUserData.roleId;

    this.timeZone = moment.tz.guess();
    let d: Date = new Date(); // moment(this.getSunday(this.viewStartDate)).add(1, 'day').toDate();

    let cd: Date = new Date();

    // if (d.getDate() == cd.getDate()) {
    // 	var cwm = new Date(d.setDate(d.getDate() - d.getDay() - 6)); // Get monday of current week
    // 	d = cwm; // assign monday as from date
    // }
    this.service.UtcPickerData = {
      fromDate: d,
      toDate: cd,
    };

    this.service.pickerPostData = {
      fromDate: this.service.Moment(d).format("MM/DD/YYYY HH:mm:ss"),
      toDate: this.service.Moment(cd).format("MM/DD/YYYY HH:mm:ss"),
    };

    // const today = new Date();
    // const yesterday = new Date(today);

    // yesterday.setDate(yesterday.getDate() - 1);

    // //this.service.DevelopConsoleLog('viewStartDate1', this.viewStartDate );
    // var moment = require("moment-timezone");
    // //const dateInput = new Date();
    // const timeZone = moment.tz.guess();
    // //this.service.DevelopConsoleLog('dateInput', dateInput);
    // //this.service.DevelopConsoleLog('timeZone', timeZone);
    // this.viewStartDate = moment(yesterday).tz(timeZone).toDate(); //.format('hh:mm:ss a'), 'hh:mm:ss a');
    // this.service.DevelopConsoleLog("viewStartDate2", this.viewStartDate);
    // this.service.UtcPickerData = {
    //   fromDate: this.viewStartDate,
    //   toDate: this.viewStartDate,
    // };

    // this.service.ServerDataDto = {
    //   fromDate: this.viewStartDate.toISOString(),
    //   toDate: this.viewStartDate.toISOString(),
    // };

    this.service.ServerDataDto = {
      fromDate: this.service.Moment(d).format("MM/DD/YYYY HH:mm:ss"),
      toDate: this.service.Moment(cd).format("MM/DD/YYYY HH:mm:ss"),
    };

    this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);

    router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationStart) this.myStatusDialog = false;
    });

    // this.callsService.GetTwilioVoiceAccessToken().then((res) => {
    //   if (res.status == 1) {
    //     this.twilioAccessToken = res.token;
    //     this.sysService.App.SetData("twilioAccessToken", this.twilioAccessToken);
    //     // Set up the Twilio Client Device with the token
    //     //this.service.DevelopConsoleLog('this.twilioAccessToken', this.twilioAccessToken);

    //     window["toastr"]["info"]('Twilio starting connection', 'Twilio Voice Access Token' || "Twilio Voice Access Token");

    //     if (this.twilioAccessToken != '' && this.twilioAccessToken !== null) {

    //       var rs = this.sysService.Twilioclient.Device.setup(this.twilioAccessToken, { debug: true, enableRingingState: true, answerOnBridge: true });
    //       // window["toastr"]["info"](this.twilioAccessToken, 'twilioAccessToken' || "twilioAccessToken");

    //       this.sysService.Twilioclient.Device.ready((device) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "ready";

    //       });

    //       this.sysService.Twilioclient.Device.error((error) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "error";

    //       });

    //       this.sysService.Twilioclient.Device.connect((conn) => {
    //         this.sysService.incallstatus = true;
    //         this.sysService.TwilioStatus = "connect";

    //       });

    //       this.sysService.Twilioclient.Device.disconnect((conn) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "disconnect";

    //       });

    //       this.sysService.Twilioclient.Device.incoming((conn) => {
    //         this.sysService.incallstatus = false;
    //         this.sysService.TwilioStatus = "incoming";

    //       });

    //     }

    //   }
    // }).catch((err) => {
    //   window["toastr"]["info"]('Twilio starting connection', 'ERROR GETTING Twilio Voice Access Token' || "ERROR GETTING Twilio Voice Access Token");
    //   this.sysService.DevelopConsoleLog('this.GetTwilioVoiceAccessToken error', err);

    // });

    //this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);
  }
  ngOnInit() {
    this.userRoleId = this.service.CurentUserData.roleId;
    this.getCustomDataLists();
    this.GetDataListNames();
    this.twillioDeviceSetUp();
    this.GetRoles();
    this.GetUserAccesses();
    this.GetRolesUsersCount();
    this.LoadReferrals();
    this.GetAllEmployees();
    this.eventChangeSubscribe();
    //console.clear();
    //this.service.DevelopConsoleLog("UserDaCurentUserDatata",this.service.CurentUserData)

    this.loadDatePicker();
    //  var ClassItem = this.service.Data.BuildObjectClassItems('GetRoles',this.service.CurentUserData);
    //  this.service.DevelopConsoleLog("ClassItem CurentUserData", ClassItem);
  }

  userAccesses: any = {
    Admin: false,
    Twml: false,
    Email: false,
    Notify: false,
    Calls: false,
    Sms: false,
    Contacts: false,
    LGCenter: false,
    Team: false,
    Telemarketing: false,
    Area: false,
    Schedule: false,
    Leads: false,
    Appointments: false,
    Clients: false,
  };
  GetUserAccesses() {
    this.userAccesses = {
      Admin: false,
      Twml: false,
      Email: false,
      Notify: false,
      Calls: false,
      Sms: false,
      Contacts: false,
      LGCenter: false,
      Team: false,
      Telemarketing: false,
      Area: false,
      Schedule: false,
      Leads: false,
      Appointments: false,
      Clients: false,
    };

    var admin = this.service.GetRoleAccess("Admin");

    this.uracApiService.GetUserAccesses().then((data) => {
      //this.service.DevelopConsoleLog("GetUserAccesses11111",data)
      var logoutUser = false;
      data.forEach((item) => {
        //string[] components = { "Admin", "Twml", "Email", "Notify", "Calls", "Sms", "Contacts", "LGCenter",
        //"Team", "Telemarketing", "Area", "Schedule", "Leads", "Appointments" };
        if (!logoutUser) {
          logoutUser = item.display;
        }
        if (item.componentid == 1) {
          this.userAccesses.Admin = item.display;
        } else if (item.componentid == 2) {
          this.userAccesses.Appointments = item.display;
        } else if (item.componentid == 3) {
          this.userAccesses.Area = item.display;
        } else if (item.componentid == 4) {
          this.userAccesses.Calls = item.display;
        } else if (item.componentid == 5) {
          this.userAccesses.Contacts = item.display;
        } else if (item.componentid == 6) {
          this.userAccesses.Email = item.display;
        } else if (item.componentid == 7) {
          this.userAccesses.Leads = item.display;
        } else if (item.componentid == 8) {
          this.userAccesses.LGCenter = item.display;
        } else if (item.componentid == 9) {
          this.userAccesses.Notify = item.display;
        } else if (item.componentid == 10) {
          this.userAccesses.Schedule = item.display;
        } else if (item.componentid == 11) {
          this.userAccesses.Sms = item.display;
        } else if (item.componentid == 12) {
          this.userAccesses.Team = item.display;
        } else if (item.componentid == 13) {
          this.userAccesses.Telemarketing = item.display;
        } else if (item.componentid == 14) {
          this.userAccesses.Twml = item.display;
        } else if (item.componentid == 15) {
          this.userAccesses.Clients = item.display;
        }
      });

      if (!logoutUser) {
        this.service.logOut();
      }

      if (admin) {
        this.userAccesses.Admin = true;
      }

      //this.service.DevelopConsoleLog("GetUserAccesses->userAccesses",   this.userAccesses);
    });
  }

  LoadReferrals() {
    this._vReferalsApiService.GetUsersReferalls().then((data) => {
      this.service.AllReferalsDB = data;
      this.service.DevelopConsoleLog(
        "_vReferalsApiService.GetUsersReferalls",
        this.service.AllReferalsDB
      );
    });
  }

  GetAllEmployees() {
    this.employeeApiService.GetUsersInRols().then((data) => {
      this.service.AllEmployeesDB = data;

     this.service.DevelopConsoleLog("GetUsersInRols:AllEmployeesDB1111111", this.service.AllEmployeesDB);
    });
  }

  eventChangeSubscribe() {
    this.EventSubWatcher = this.service.App.onGlobalDateChange.subscribe(
      (obj) => {
        //this.service.DevelopConsoleLog("toheader onGlobalDateChange", this.service.UtcPickerData.fromDate);
        //this.service.DevelopConsoleLog("toheader timeZone", this.timeZone);
        const startdate = moment(this.service.UtcPickerData.fromDate)
          .tz(this.timeZone)
          .toDate();

        $("#daterangeTop")
          .data("daterangepicker")
          .setStartDate(new Date(startdate));

        $("#daterangeTop")
          .data("daterangepicker")
          .setEndDate(new Date(startdate));
      }
    );
  }

  twillioDeviceSetUp() {
    var CurentUserData = this.service.CurentUserData;
    //this.service.DevelopConsoleLog("callConnect CurentUserData",  CurentUserData);
    //this.service.DevelopConsoleLog("callConnect twillioAcessToken",  CurentUserData.twillioAcessToken);
    if (CurentUserData.twillioAcessToken == undefined) {
      this.service.infoToster(
        "Your Twilio account registration has encountered an issue. Please review your Twilio account settings to resolve the problem.",
        "https://www.twilio.com/login"
      );
    } else if (
      CurentUserData.twillioAcessToken != undefined &&
      CurentUserData.twillioAcessToken != ""
    ) {
      //this.service.DevelopConsoleLog("callConnect d.twillioAcessToken",  CurentUserData.twillioAcessToken);
      this.service.Twilioclient.Device.setup(CurentUserData.twillioAcessToken, {
        debug: true,
        enableRingingState: true,
        answerOnBridge: true,
      });

      //this.LockScreen()
    } else {
      this.service.infoToster(
        "Your Twilio account registration has encountered an issue. Please review your Twilio account settings to resolve the problem.",
        "https://www.twilio.com/login"
      );
      //this.LockScreen()
    }
  }

  loadDatePicker() {
    // ranges: {
    // 	Today: [moment(), moment()],
    // 	Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
    // 	'Two Days ago': [moment().subtract(2, 'day'), moment().subtract(2, 'day')]
    // 	'This Week': [
    // 		moment(new Date(this.getSunday(this.viewStartDate))).add(1, 'day') ,
    // 		moment()
    // 	],
    // 	'Last Week': [
    // 		moment(new Date(this.getSunday(this.viewStartDate)))
    // 			.subtract(6, 'days') ,
    // 		moment(new Date(this.getSunday(this.viewStartDate)))

    // 	],
    // 	'Last 7 Days': [
    // 		moment().subtract(6, 'days'),
    // 		moment()
    // 	],
    // 	'This Month': [
    // 		moment().startOf('month'),
    // 		moment().endOf('month')
    // 	],
    // 	'Last Month': [
    // 		moment().subtract(1, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 2 Months': [
    // 		moment().startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'2 Months ago': [
    // 		moment().subtract(2, 'month').startOf('month'),
    // 		moment().subtract(2, 'month').endOf('month')
    // 	],
    // 	'3 Months ago': [
    // 		moment().subtract(3, 'month').startOf('month'),
    // 		moment().subtract(3, 'month').endOf('month')
    // 	],
    // 	'Last 3 Months': [
    // 		moment().subtract(3, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 6 Months': [
    // 		moment().subtract(6, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 9 Months': [
    // 		moment().subtract(9, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 12 Months': [
    // 		moment().subtract(12, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 24 Months': [
    // 		moment().subtract(24, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	],
    // 	'Last 3 years': [
    // 		moment().subtract(36, 'month').startOf('month'),
    // 		moment().subtract(1, 'month').endOf('month')
    // 	]
    // }
    // need to be in EST as defult

    // //https://www.daterangepicker.com/#options

    // startDate: this.service.UtcPickerData?.fromDate  || this.viewStartDate,
    // endDate: this.service.UtcPickerData?.toDate || this.viewStartDate,

    $("#daterangeTop")["daterangepicker"](
      {
        autoApply: true,
        singleDatePicker: true,
        timePicker: false,
        autoUpdateInput: true,
        showDropdowns: false,
        startDate: new Date(this.service.ServerDataDto.fromDate),
        endDate: new Date(this.service.ServerDataDto.fromDate),
      },
      (x, d) => {
        // this.service.pickerPostData = {
        // 	fromDate: this.service.Moment(x._d).format('MM/DD/YYYY HH:mm:ss'),
        // 	toDate: this.service.Moment(d._d).format('MM/DD/YYYY HH:mm:ss')
        // };

        // this.service.registerEvent('GLOBALDATESKC',this,(args:any)=>{

        //  })

        this.service.pickerPostData = {
          fromDate: x._d,
          toDate: d._d,
        };
        this.service.UtcPickerData = {
          fromDate: x._d,
          toDate: d._d,
        };

        this.service.ServerDataDto = {
          fromDate: new Date(x._d).toISOString(),
          toDate: new Date(d._d).toISOString(),
        };

        this.service.SelectedEpochDate = new Date(x._d).getTime();
        this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);
      }
    );

    // $("#daterangeTop")
    //   .data("daterangepicker")
    //   .setStartDate(new Date(this.service.ServerDataDto.fromDate));

    //   $("#daterangeTop")
    //   .data("daterangepicker")
    //   .setStartDate(new Date(this.service.ServerDataDto.fromDate));

    // console.clear();
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + 1; // + (day == 0 ? -6 : 1) adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  LockScreen() {
    //
    //this.service.DevelopConsoleLog("LockScreen Caled");

    var d = this.service.CurentUserData;

    var userInfo = {
      name: "",
      role: "",
      email: "",
      imageUrl: "./assets/demo/img/contacts/no_usr.png",
      userIdVarID: "",
      cellPhone: "",
    };

    if (d == null) {
    } else {
      userInfo = {
        name: d.lineName,
        role: d.role,
        email: d.email,
        imageUrl:
          d.imageUrl == this.service.qdispoAgentImageUrl
            ? "./assets/demo/img/contacts/no_usr.png"
            : d.imageUrl,
        userIdVarID: d.userIdVarID,
        cellPhone: d.cellPhone,
      };
    }

    if (userInfo.role != "Admin") {
      var frlnk = this.service.qdispatchDomain + "/SignOut.aspx";
      this.service.App.LoadMainIframe.emit({ visible: true, src: frlnk });
    }

    this.service.DevelopConsoleLog("userInfo  ", userInfo);

    this.service.LockedApp = true;
    this.service.App.onOpenTopSlide.emit({ type: "OpenlockedScreenComponent" });
    localStorage.setItem("LockedApp", "1");
    this.service.logOut();
  }

  public SelectMenue(page: string) {
    this.service.DevelopConsoleLog("SelectMenue  ", page);
    try {
      $("#daterangeTop").data("daterangepicker").setStartDate(new Date());

      $("#daterangeTop").data("daterangepicker").setEndDate(new Date());
      // $("#daterangeTop")
      // .data("daterangepicker")
      // .setStartDate(new Date(startdate));

      // $("#daterangeTop")
      // .data("daterangepicker")
      // .setEndDate(new Date(startdate))

      this.router.navigate([page], {});
    } catch (e) {
      this.service.DevelopConsoleLog("SelectMenue  ", e);
    }
  }
  selecteddl: number = 1;
  setDatalistSurce(src: number) {
    this.service.DevelopConsoleLog("setDatalistSurce  ", src);
    this.selecteddl = src;
    this.filterDatalists = [];
    if (src == 0) {
      this.getCustomDataLists();
      this.GetDataListNames();
      this.selecteddl = 1;
    } else if (src == 1) {
      this.filterDatalists = this.Datalists;
    } else if (src == 3) {
    } else {
      this.filterDatalists = this.customDatalists;
    }
  }
  customDatalists: any = [];
  Datalists: any = [];
  filterDatalists: any = [];
  getCustomDataLists() {
    //
    this.customDatalists = [];
    this.filterDatalists = [];
    this.vdataListsApiService.GetCustomDataListNames().then((data) => {
      this.customDatalists = data;
      //this.service.DevelopConsoleLog("getDataLists GetCustomDataListNames", data);
    });
  }

  GetDataListNames() {
    //GetCustomDataListNames
    this.filterDatalists = [];
    this.Datalists = [];
    this.vdataListsApiService.GetDataListNames().then((data) => {
      this.Datalists = data;
      this.filterDatalists = this.Datalists;
      //this.service.DevelopConsoleLog("getDataLists GetCustomDataListNames", data);
    });
  }

  GetRoles() {
    this.service.cRoles = [];
    this.employeeApiService.GetRoles().then((data) => {
      this.service.cRoles = data;
      // this.service.BuildObjectItems ()
      // var ClassItem = this.service.Data.BuildObjectClassItems('GetRoles',this.service.cRoles[0]);
      // this.service.DevelopConsoleLog("ClassItem cRoles", ClassItem);
      // this.service.DevelopConsoleLog("GetRoles cRoles", this.service.cRoles);
    });
  }

  GetRolesUsersCount() {
    this.service.cRolesCount = [];
    this.employeeApiService.GetRolesUsersCount().then((data) => {
      this.service.cRolesCount = data;
    });
  }

  public LoadDailySchedule(n: number): void {
    try {
      var params = [
        "height=" + screen.height,
        "width=" + screen.width,
        "fullscreen=yes",
      ].join(",");
      var frlnk =
        this.service.qdispatchDomain +
        "/Control/Dispatch/DailySchedule.aspx?rwndrnd=1";

      if (n == 0) {
        frlnk =
          this.service.qdispatchDomain +
          "/Control/Marketing/MarketingCalendar.aspx?status=veiwall&rwndrnd=1";
      }

      if (n == 2) {
        frlnk =
          this.service.qdispatchDomain +
          "/Control/DataLists/ADataLists.aspx?loadTop=1&rwndrnd=0.6787506018102656";
      }

      this.service.App.LoadMainIframe.emit({ visible: true, src: frlnk });

      // var popup = window.open(frlnk, "DailySchedule", params);
      // popup.moveTo(0, 0);
    } catch (x) {
      this.service.DevelopConsoleLog("LoadDailySchedule", x);
    }
  }

  public LoadTwmlDail(item: any) {
    try {
      var params = [
        "height=" + screen.height,
        "width=" + screen.width,
        "fullscreen=yes",
      ].join(",");
      var frlnk =
        this.service.qdispatchDomain +
        "/ConProUi/Twml/TwmlDail.aspx?DataListVarId=" +
        item.DataListVarId +
        "&CallBtn=1";
      this.service.App.LoadMainIframe.emit({ visible: true, src: frlnk });
      // var popup = window.open(frlnk, "TwmlDail", params);
      // popup.moveTo(0, 0);
    } catch (x) {
      this.service.DevelopConsoleLog("LoadDailyScheduleGroup", x);
    }
  }

  ngOnDestroy() {
    if (this.EventSubWatcher) this.EventSubWatcher.unsubscribe();
  }

  alertNotify: boolean = false;
  orderNotifications: any = [
    {
      title: "New sales order received",
      status: "unread",
      id: "#204",
      time: "10 minutes ago",
      icon: "&#xea6d;",
      color: "red",
    },
  ];

  // Support notifications
  supportNotifications: any = [
    {
      title: "New issue filed by customer",
      status: "unread",
      id: "#721",
      time: "10 seconds ago",
      icon: "&#xea2b;",
      color: "blue",
    },
  ];

  // Mails
  mails: any = [
    {
      image: "15.jpg",
      user: "David Belle",
      content: "Cum sociis natoque penatibus et magnis dis parturient montes",
      time: "10:02 AM",
      status: "unread",
    },
    {
      cap: "AW",
      color: "red",
      user: "Aaron Warner",
      content:
        "Nunc quis diam diamurabitur at dolor elementum, dictum turpis vel",
      time: "02:30 PM",
      status: "read",
    },
    {
      cap: "FM",
      color: "blue",
      user: "Fredric Mitchell Jr.",
      content:
        "Phasellus a ante et est ornare accumsan at vel magnauis blandit turpis at augue ultricies",
      time: "Tue",
      status: "read",
    },
    {
      image: "12.jpg",
      user: "Glenn Jecobs",
      content:
        "Ut vitae lacus sem ellentesque maximus, nunc sit amet varius dignissim, dui est consectetur neque",
      time: "Mon",
      status: "unread",
    },
    {
      image: "11.jpg",
      user: "Bill Phillips",
      content:
        "Proin laoreet commodo eros id faucibus. Donec ligula quam, imperdiet vel ante placerat",
      time: "Fri",
      status: "unread",
    },
    {
      image: "4.jpg",
      user: "Alice Margit",
      content: "Aenean lacinia bibendum nulla sed consectetur",
      time: "May 10",
      status: "read",
    },
    {
      cap: "VH",
      color: "amber",
      user: "Van Hasnon",
      content: "Nullam iolor ieonibh ultricies vehicula magna",
      time: "May 09",
      status: "unread",
    },
    {
      cap: "IA",
      color: "purple",
      user: "Iason Antonius",
      content:
        "Maecenas faucibus mollis interdum Maecenas sed diam eget risus varius blandit sit amet non magna",
      time: "May 08",
      status: "read",
    },
  ];

  // Tasks
  tasks = [
    {
      name: "Page speed test for parent website",
      completed: 95,
      color: "blue",
    },
    {
      name: "Project version 3.2.1 release",
      completed: 80,
      color: "green",
    },
    {
      name: "Severe bug fixes",
      completed: 20,
      color: "amber",
    },
    {
      name: "UI design for proposed landing page",
      completed: 60,
      color: "red",
    },
    {
      name: "Blog article for the month of August",
      completed: 80,
      color: "cyan",
    },
    {
      name: "August newsletter",
      completed: 32,
      color: "teal",
    },
    {
      name: "Github issue #3241",
      completed: 82,
      color: "purple",
    },
    {
      name: "Github issue #3118",
      completed: 45,
      color: "pink",
    },
  ];

  // Languages
  languages: any = {
    current: "English",
    list: [
      {
        language: "French",
        flag: "france",
      },
      {
        language: "German",
        flag: "germany",
      },
      {
        language: "Japanese",
        flag: "japan",
      },
      {
        language: "Chinese",
        flag: "china",
      },
    ],
  };
}
