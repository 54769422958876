/// <reference path="../../node_modules/@types/jquery/index.d.ts" />
/// <reference path="../../node_modules/@types/jquery/index.d.ts" />

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { EventEmitter, Injectable, Optional } from "@angular/core";
import { Router ,ActivatedRoute} from "@angular/router";
//const moment = require('moment');
import { DOCUMENT } from "@angular/common";
import * as toastr from "toastr";
 
import { formatLabelPipe } from 'src/app/pipes/labels/format-label.pipe';

import {
  datadogLogs,
  LoggerConfiguration,
  StatusType,
  HandlerType,
} from "@datadog/browser-logs";
import { firstValueFrom } from "rxjs";

import { Logger } from "./logger.service";

import {
  newToastrDtp,
  Toastr,
  updateToastrDtp,
  UserDto,
} from "./shared/CommonModel";
import {
  Account_Model,
  aspRoles,
  Chat_Model,
  Chat_Type,
  RolesRigts,
  UserInRole,
  XmlSetting_Model,
} from "../app/shared/models/SystemModels";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import packageInfo from "../../package.json";
import moment from "moment";
declare const Twilio: any;
//import * as twilio from 'twilio';
/// GlobalBaseUrl
@Injectable({
  providedIn: "root",
})

//GlobalBaseUrl
export class AppService {
  //   accountSid = 'ACb762d2e4fcc2bc83cdd50c30bddf5392';
  // authToken = 'b68ca529d8460c8a11195d61f246e2b9';
  // client = twilio(this.accountSid, this.authToken);

  //-----------------------
  // Global States
  //-----------------------
  // Theme Layout
  themeLayout: string;

  // Sidebar
  sidebarActive = false;

  // Mobile Search
  searchToggled = false;

  // Sidebar within table
  asideTableActive = false;

  // Sidebar within pages
  asideMobileActive = false;

  // Sidebar Page
  asidePageActive = false;

  // Page loading
  isLoading = false;


  UseMobileGeo = true;
  TwmlPages: any = {
    list: [
      {
        page: "Telemarketing Dashboard",
        url: "/dashboards/datalists",
        icon: "zwicon-tray-import",
      },
      {
        page: "Call Center Dashboard",
        url: "/admin/twilio/callCenter",
        icon: "zwicon-headphone",
      },
      {
        page: "Recordings",
        url: "/admin/setting/twilio",
        icon: "zwicon-play-alt",
      },
      {
        page: "Twilio Numbers",
        url: "/admin/setting/twilio/referals",
        icon: "zwicon-phone", 
      },
      {
        page: "CPC Information",
        url: "/admin/setting/twilio/cpccalls",
        icon: "zwicon-activity",
      },
      {
        page: "SPAM Numbers Report",
        url: "/admin/setting/twilio/spamcalls",
        icon: "zwicon-activity",
      },
    ],
  };

  //-----------------------
  // Colors
  //-----------------------
  colors: any = {
    100: "#f0f7fc",
    200: "#e0f0fb",
    300: "#d4eafa",
    400: "#c3e0f4",
    500: "#b4d6ed",
    600: "#9fc6e1",
    700: "#6c9dbf",
    800: "#36688c",
    900: "#205377",

    white: "#ffffff",
    black: "#000",
    red: "#fd7171",
    blue: "#1e91ff",
    green: "#43d271",
    orange: "#ffa950",
    purple: "#e26cf5",
    cyan: "#26c6da",
    teal: "#26a69a",
    lime: "#d4e157",
    amber: "#ffca28",
    "deep-purple": "#7e57c2",
    indigo: "#5c6bc0",
  };

  //-----------------------
  // Chart.js
  //-----------------------
  // Generate time series data
  generateTimeSeries(baseval: any, count: any, yrange: any) {
    let i = 0;
    const series = [];
    while (i < count) {
      const x = baseval;
      const y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({ x, y });
      baseval += 86400000;
      i++;
    }
    return series;
  }

  // Gradient background
  chartGradientHelper = (
    ctx: any,
    chartArea: any,
    gradientHeight: any,
    colorStart: any,
    colorEnd: any
  ) => {
    let width: number, height: number, gradient: any;
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (gradient === null || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, colorEnd);
      gradient.addColorStop(gradientHeight, colorStart);
    }

    return gradient;
  };

  chartGradientBackground = (context, colorStart, colorEnd) => {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    return chartArea
      ? this.chartGradientHelper(ctx, chartArea, 0.5, colorStart, colorEnd)
      : null;
  };

  // Tooltip
  chartTooltip: any = {
    titleFont: {
      family: "Roboto",
      size: 11,
      weight: "normal",
    },
    titleColor: "#e0f3ff",
    bodyColor: "#B0C6DD",
    bodyFont: {
      family: "Roboto",
      size: 11,
    },
    titleMarginBottom: 3,
    backgroundColor: "#1f2e36",
    padding: 10,
    cornerRadius: 3,
    multiKeyBackground: "rgba(0,0,0,0)",
    displayColors: false,
  };

  // Ticks
  chartTicks: any = {
    font: {
      family: "Roboto",
      size: 10,
    },
  };

  // Legend
  chartLegend: any = {
    position: "bottom",
    labels: {
      color: "#799fba",
      usePointStyle: true,
      boxWidth: 15,
      boxHeight: 7,
      padding: 20,
      font: {
        family: "Roboto",
        lineHeight: 1,
      },
    },
  };

  /////
  selectedAreaView: number = 0;
  selectedAreaVarId:string = "";
  //////////////

  debugMode: boolean = false;

  AllowInQA: boolean = false;

  SelectedMenue: number = 1;
  version = packageInfo.version;

  enabledatadog: boolean = packageInfo.enabledatadog;

  //this.service.qdispoAgentImageUrl http://localhost:64716/#/mobile/login/auth?cellphone=8188095705&mobilepin=4BA483
  qdispatchDomain:string = "https://www.qdispatch.com/";
  qdispoAgentImageUrl:string = "https://www.qdispatch.com/UserImage.aspx?empvr=";
  // -----------------------
  // Re-usable functions
  // -----------------------
  // Scroll to section
  scrollToCategory(id: string) {
    const item = document.getElementById(id);
    const window = document.querySelector(".content__inner");
    const count = item.offsetTop - window.scrollTop - 23;
    window.scrollBy({ top: count, left: 0, behavior: "smooth" });
  }

  public App: AppHelper;
  rootUrl = window["Site_URL"];
  public pickerPostData: {
    fromDate: string;
    toDate: string;
  };

  public reportsPostData: {
    fromDate: string;
    toDate: string;
  };
  public UtcPickerData: {
    fromDate: Date;
    toDate: Date;
  };

  public ServerDataDto: {
    fromDate: string;
    toDate: string;
  };

  public SelectedEpochDate: number = new Date().getTime();

  public reportsUtcPickerData: {
    fromDate: Date;
    toDate: Date;
  };

  public uploadSaveUrl: string = ""; //BaseUrl +
  public selectedMenu: number = 0;
  public selectedTab: number = 0;
  public ViewPageTitle: string = "ViewPageTitle";

  public AllEmployeesDB: any = [];
  public AllCompanysDB: any = [];
  public AllReferalsDB: any = [];

  
  public selectedProjectVarid: string = "1111";
  public newlyCreatedProjectVarid: string = "";
  public CL: string = "";
  public Data: DataHelper;
  public Account: Account_Model = new Account_Model(); // Account_Model;

  public cRoles: aspRoles[]; // Account_Model;
  public cRolesCount: aspRoles[]; // Account_Model;
  public XmlSetting: any = []; // XmlSetting_Model;
  public ChatSetting: any = []; // Chat_Model;

  public ShowSideMenu: boolean = false;

  public _Lusername: string = "";
  public _Lpassword: string = "";

  public _LcontactGuid: string = "";
  public _LpsychicGuid: string = "";
  public _LextID: number = 0;
  public _Lpageid: string = "";
  public _Showbanner: string = "1";
  public toastrs: any = [];
  public ActiveToastrs: any = [];
  public toastrList: any = [];
  public selectedtoastr: any = [];

  // {
  //     "applicationId": "ba0b7cdd-a573-4d55-9e3d-d64f2141ca35",
  //     "accountGuid": "09128a43-e6e7-4f1e-ad81-265a05e9f0a9",
  //     "userGuid": "15f92839-4ccb-4550-9ec0-86753b9a1d11",
  //     "roleId": "e163b520-ee75-45d6-b708-c7dcaa54b520",
  //     "role": "Admin",
  //     "firstName": "",
  //     "lastName": "",
  //     "lineName": "Admin",
  //     "phoneNumber": "8188095705",
  //     "imageUrl": "../../Images/Accounts/3c550629-f9d5-4f2c-9db4-31c0765c4a6a/Users/15f92839-4ccb-4550-9ec0-86753b9a1d11.png",
  //     "pageVarId": "login"
  // }

  public CurentUserData: UserDto = new UserDto(); // = new UserData();

  public InvoiceStatus = [
    "",
    "Draft",
    "Approved",
    "Cleared",
    "Complete",
    "InProcess",
    "Canceled",
  ];

  public pageObjects: any = [];

  public SelectedArea: any = [];
  public AllServicesArea: any = [];

  public get GetIswebform(): boolean {
    let surl = window.location.href;
    if (surl.indexOf("webform") != -1) {
      return true;
    } else {
      return false;
    }
  }

  public get GetIsDevelopment(): boolean {

    return false;
    let surl = window.location.href;


    if (  this.CurentUserData?.userGuid == "15f92839-4ccb-4550-9ec0-86753b9a1d11")
    {
      return true;
    }  else  if (surl.indexOf("nolog") != -1) {
      return false;
    } else if (surl.indexOf("localhost") != -1) {
      return true;
    } else if (surl.indexOf("test-qdapi") != -1) {
      return true;
    } else if (surl.indexOf("qa-qdapi") != -1) {
      return true;
    } else {
      return false;
    }
  }

  public GetRoleAccess(role: string): boolean {
    var d = this.CurentUserData;
    if (d == null) {
      return false;
    } else if (d.role == role) {
      return true;
    } else {
      return false;
    }
  }

  public GetPageRoleAccess(page: string): boolean {
    var d = this.CurentUserData;
    if (d == null) {
      return false;
    }
    return true;
    // if (d.roleId == "e163b520-ee75-45d6-b708-c7dcaa54b520") {
    //   return true;
    // } else {
    //   var redirect = true;
    //   switch (page) {
    //     case "lgCenter":
    //       redirect = false;
    //       break;
    //     default:
    //       redirect = true;
    //   }

    //   if (redirect == true) {
    //     //this.router.navigate(["/app-views/lgCenter"]).then(() => {});
    //   }
    // }
  }

  public get GetUsetTestData(): boolean {
    let surl = window.location.href;
    if (surl.indexOf("nolog") != -1) {
      return true;
    } else if (surl.indexOf("localhost") != -1) {
      return false;
    } else if (surl.indexOf("test-patapp") != -1) {
      return true;
    } else if (surl.indexOf("qa-patapp") != -1) {
      return true;
    } else if (surl.indexOf("rel-patapp") != -1) {
      return true;
    } else {
      return false;
    }
  }

  public LockedApp: boolean = false;

  public IsDevelopment: boolean = this.GetIsDevelopment;

  public UsetTestData: boolean = this.GetUsetTestData;

  public UseToster: boolean = true;
  //phone side panel
  public PhoneSidePanel: boolean = false;
  //alert side panel
  public AlertsSidePanel: boolean = false;

  public _dataPromise: Deferred<boolean> = new Deferred<boolean>();
  public _taskPromise: Deferred<any> = new Deferred<any>();
  public get HasAccountData(): Promise<boolean> {
    return this._dataPromise.promise;
  }

  public get HasTaskData(): Promise<Array<any>> {
    return this._taskPromise.promise;
  }

  //////////////

  constructor(
  
    public route: ActivatedRoute,
    public router: Router,
    public http: HttpClient,
    public logger: Logger,
    private sanitizer: DomSanitizer
  ) {
    this.Account = <Account_Model>{};
    this.XmlSetting = []; //<XmlSetting_Model>{};

    this.ChatSetting = []; //<Chat_Model>{ ChatList: [] };

    this.App = new AppHelper(this);

    this.Data = new DataHelper(http, this.App, this, sanitizer);

    // window['toastr'].options = {
    // 	closeButton: false,
    // 	debug: false,
    // 	newestOnTop: true,
    // 	progressBar: true,
    // 	positionClass: 'toast-top-right',
    // 	preventDuplicates: true,
    // 	onclick: null,
    // 	showDuration: 200,
    // 	hideDuration: 100,
    // 	timeOut: 3000,
    // 	extendedTimeOut: 3000,
    // 	showEasing: 'swing',
    // 	hideEasing: 'linear',
    // 	showMethod: 'fadeIn',
    // 	hideMethod: 'fadeOut'
    // };
    //https://codeseven.github.io/toastr/demo.html
    window["toastr"].options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };

    this.loadAccountDetail();

    // this.pickerPostData = {
    // 	fromDate: this.Moment(this.getSunday(new Date())).format('MM/DD/YYYY HH:mm:ss') ,
    // 	toDate: this.Moment(new Date()).format('MM/DD/YYYY HH:mm:ss'),
    // };

    // this.ServerDataDto  = {
    //     fromDate:  this.getSunday(new Date()).toISOString(),
    //     toDate:new Date().toISOString(),
    // };

    this.pickerPostData = {
      fromDate: this.Moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
      toDate: this.Moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
    };

    this.ServerDataDto = {
      fromDate: this.Moment(new Date()).toDate().toISOString(),
      toDate: this.Moment(new Date()).toDate().toISOString(),
    };

    setTimeout(() => {}, 3500);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + 1; // + (day == 0 ? -6 : 1) adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  Twilioclient: any = Twilio;
  incallstatus: boolean = false;
  TwilioStatus: string = "";

  Moment(input?: Date | string): moment.Moment {
    if (input) {
      return moment(input);
    } else {
      moment.locale("en", {
        relativeTime: {
          future: "in %s",
          past: "%s ago",
          s: "seconds",
          ss: "%ss",
          m: "a min",
          mm: "%dm",
          h: "an hour",
          hh: "%dh",
          d: "a day",
          dd: "%dd",
          M: "a month",
          MM: "%d month",
          y: "a year",
          yy: "%dY",
        },
      });

      return moment();
    }
  }

  Moment_Format(input: Date | string, format: string): moment.Moment {
    moment.locale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "seconds",
        ss: "%ss",
        m: "a min",
        mm: "%dm",
        h: "an hour",
        hh: "%dh",
        d: "a day",
        dd: "%dd",
        M: "a month",
        MM: "%d month",
        y: "a year",
        yy: "%dY",
      },
    });

    return moment(input, format);
  }

  minFromNow = function (a) {
    var duration = moment().diff(this, "minutes");
    return duration;
  };

  loggerGet() {
    return this.logger;
  }

  // //info debug warn error
  LogtoDataDog(msg: string, level: StatusType) {
    if (this.enabledatadog == true) {
      datadogLogs.setLoggerGlobalContext({ app: "LeadMopUI" });

      if (level == StatusType.info) {
        this.logger.info("info: " + msg, { app: "LeadMopUI" });
      } else if (level == StatusType.debug) {
        this.logger.debug("debug: " + msg, { app: "LeadMopUI" });
      } else if (level == StatusType.warn) {
        this.logger.warn("warn: " + msg, { app: "LeadMopUI" });
      } else if (level == StatusType.error) {
        this.logger.error("error: " + msg, { app: "LeadMopUI" });
      }
    }
  }

  loadAccountDetail(): Promise<boolean> {
    window.setTimeout(() => {
      var _token = localStorage.getItem("token");
      var _userDto = localStorage.getItem("userDto");
      var _userTokenDto = localStorage.getItem("userTokenDto");

      //
      // console.log("data.userDto",userDto);
      // console.log("data.userTokenDto",userTokenDto);

      if (_token !== null && _userDto !== null && _userTokenDto !== null) {
        try {
          var userDto = JSON.parse(_userDto);

          this.Account.userInRole = "admin";

          // localStorage.setItem('token', userTokenDto.token);
          // localStorage.setItem('userDto', JSON.stringify(userDto));
          // localStorage.setItem('UserData', JSON.stringify(UserData));
          // localStorage.setItem('userTokenDto', JSON.stringify(userTokenDto));

          //this.CurentUserData = userDto;

          // this.Account.contactGuid = this.CurentUserData.contactGUID;
          // this.Account.userTokenDto = userTokenDto;
          //console.clear();
          //console.log("userDto.c",userDto.);
          this.CurentUserData = userDto;

          this._dataPromise.resolve(true);
        } catch (ex) {
          //console.log("loadAccountDetail.c",ex);

          localStorage.clear();
          //console.log('loadAccountDetail.b','logedout')
          if (this.checkIfMobile() == true) {
          //  this.mobileRouter(2) 
          } else {
           // this.pcRouter(2) 
 
          }

          this._dataPromise.resolve(false);
        }
      } else {
        //console.log('loadAccountDetail.a', 'logedout')
        if (this.checkIfMobile() == true) {
         // this.mobileRouter(1)
        } else {
          //this.pcRouter(1) 
        
        }
        localStorage.clear();
        this._dataPromise.resolve(false);
        this.ShowSideMenu = false;
      }
    }, 1);
    return this._dataPromise.promise;
  }


  mobileRouter(n:number) {

    var q1 = this.route.snapshot.queryParams["cellphone"];
    var q2 = this.route.snapshot.queryParams["mobilepin"];
    this.DevelopConsoleLog("postData q1",q1);
    this.DevelopConsoleLog("postData q2",q2);
    if (q1 == null || q1 == "" || q1 == undefined) {
      q1 = "";
    } else {
      
    }

    if (q2 == null || q2 == "" || q2 == undefined) {
      q2 = "";
    } else {
      
    }
    if (q1 == "" && q2 == "") {
      
       this.router.navigate(["/mobile/login"], {
        queryParams: { rt:'mobileRouter', id:1 },
        replaceUrl: true,
      });
    
    }
    else{


      //http://localhost:64716/#/mobile/login/auth?cellphone=8188095705&mobilepin=763A3D
      this.router.navigate(["/mobile/login/auth"], {
        queryParams: { cellphone:q1,mobilepin:q2, rt:'mobileRouter' , id:2 },
        replaceUrl: true,
      });
  
    }
  
  }


  pcRouter(n:number){

    var q1 = this.route.snapshot.queryParams["cellphone"];
    var q2 = this.route.snapshot.queryParams["mobilepin"];
    this.DevelopConsoleLog("postData q1",q1);
    this.DevelopConsoleLog("postData q2",q2);
    if (q1 == null || q1 == "" || q1 == undefined) {
      q1 = "";
    } else {
      
    }

    if (q2 == null || q2 == "" || q2 == undefined) {
      q2 = "";
    } else {
      
    }
    if (q1 == "" && q2 == "") {
 
      this.router.navigate(["/misc/sample-pages/login"], {
        queryParams: { rt:'pcRouter', id:n },
        replaceUrl: true,
      });
    }
    else{
 
      //http://localhost:64716/#/mobile/login/auth?cellphone=8188095705&mobilepin=763A3D
      this.router.navigate(["/mobile/login"], {
        queryParams: { cellphone:q1,mobilepin:q2 ,rt:'pcRouter', id:n },
        replaceUrl: true,
      });
      
    }
  
  }

  checkIfMobile(): boolean {
    const userAgent = window.navigator.userAgent;
    const mobileDeviceRegex =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileDeviceRegex.test(userAgent);
  }

  redirectToLogin(returnUrl?: string) {
 
    if (this.checkIfMobile() == true) {
      this.router.navigate(["/mobile/login"], {
        queryParams: { returnUrl: returnUrl },
        replaceUrl: true,
      });
    } else {
      try {
        if (returnUrl)
          this.router.navigate(["/misc/sample-pages/login"], {
            queryParams: { returnUrl: returnUrl },
            replaceUrl: true,
          });
        else
          this.router.navigate(["/misc/sample-pages/login"], {
            replaceUrl: true,
          });
      } catch (ex) {
        this.DevelopConsoleLog("redirectToLogi0n.ex", ex);
      }
    }
  }

  goToDashboard() {
    this.router.navigate(["/"]);
  }
  logOut() {
    try {
      // need to logout from server
      this.resetPromise();
      this.App.setCookie("token", "", 0);
      this.Account = <Account_Model>{
        displayName: "",
        userTokenDto: undefined,
        contactGuid: "00000000-0000-0000-0000-000000000000",
      };
      localStorage.clear();
      this.redirectToLogin();
    } catch (ex) {
      this.DevelopConsoleLog("logOut", ex);
    }

    if (this.checkIfMobile() == true) {
      this.router.navigate(["/mobile/login"]);
    } else {
      this.pcRouter(3) 
    }
  }
  clearMobileUser() {
    try {
      // need to logout from server
      this.resetPromise();
      this.App.setCookie("token", "", 0);
      this.Account = <Account_Model>{
        displayName: "",
        userTokenDto: undefined,
        contactGuid: "00000000-0000-0000-0000-000000000000",
      };
      localStorage.clear(); 
    } catch (ex) {
      this.DevelopConsoleLog("clearUser", ex);
    }
 
  }

  mobilelogOut() {
    try {
      // need to logout from server
      this.resetPromise();
      this.App.setCookie("token", "", 0);
      this.Account = <Account_Model>{
        displayName: "",
        userTokenDto: undefined,
        contactGuid: "00000000-0000-0000-0000-000000000000",
      };
      localStorage.clear();
      this.router.navigate(["/mobile/login"], {
        queryParams: { rt:'mobilelogOut' },
        replaceUrl: true,
      });
    } catch (ex) {
      this.DevelopConsoleLog("mobilelogOut", ex);
    }
    this.router.navigate(["/mobile/login"], {
      queryParams: { rt:'mobilelogOut' },
      replaceUrl: true,
    });
  }

  ClearToken() {
    this.App.setCookie("token", "", 0);
    this.Account = <Account_Model>{
      displayName: "",
      userTokenDto: undefined,
      contactGuid: "00000000-0000-0000-0000-000000000000",
    };
  }

  resetPromise() {
    this._dataPromise = new Deferred<boolean>();
  }

  GoTo_ScrollTop(wind: Window) {
    try {
      wind.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    } catch (ex) {}
  }

  ShowHide_LeftMenu() {}

  toggleLeftPanel() {}

  navigate(pathurl: string) {
    this.router.navigate([pathurl]);
  }


  
  
  DevelopConsoleLog(title: string, obj: any, obj2?: any, obj3?: any) {
    if (
      this.IsDevelopment == true ||
      this.CurentUserData.userGuid == "15f92839-4ccb-4550-9ec0-86753b9a1d11"
    ) {
      if (obj3) {
        console.log(title, obj, obj2, obj3);
      } else if (obj2) {
        console.log(title, obj, obj2);
      } else {
        console.log(title, obj);
      }
    }
  }

  DevelopConsoleLogTest(title: string, obj: any, obj2?: any, obj3?: any) {
    if (this.IsDevelopment == true) {
      if (obj3) {
        //console.log(title, obj, obj2, obj3);
      } else if (obj2) {
        //console.log(title, obj, obj2);
      } else {
        //console.log(title, obj);
      }
    }
  }

  // ShowToster(toastrType: string, pageid: string, toastrId: string, description: string, title: string = '') {
  // 	console.log('ShowToster', this.ActiveToastrs);

  // 	const found = this.ActiveToastrs.find((obj) => {
  // 		return obj.toastrId === toastrId;
  // 	});

  // 	{
  // 		this.ActiveToastrs.push({ pageid: pageid, toastrId: toastrId, title: title, description: description });

  // 		const indexToastr = this.ActiveToastrs.length - 1;
  // 		//check if page id and error id exists in the toastr object
  // 		this.selectedtoastr = this.toastrs.find((x) => x.pageid == pageid && x.toastrId == toastrId);

  // 		//console.log('this.toastrData -> 222', this.selectedtoastr);

  // 		if (this.selectedtoastr == undefined) {
  // 			//If not, then add that data in DB, fetch and display the toastr from DB.
  // 			//pageid, toastrType, ToastrId,title, description
  // 			let nToastr: newToastrDtp = {
  // 				pageid: pageid,
  // 				toastrType: toastrType,
  // 				toastrId: toastrId,
  // 				title: title,
  // 				description: description
  // 			};

  // 			this.selectedtoastr = this.toastrs.find((x) => x.pageid == pageid && x.toastrId == toastrId);
  // 			//console.log('this.toastrData -> 222', this.selectedtoastr);
  // 		}

  // 		//If yes, means data is already in DB, fetch and display the toastr from DB.
  // 		if (this.selectedtoastr != undefined) {
  // 			toastr.options = {
  // 				closeButton: this.selectedtoastr.closeButton,
  // 				debug: false,
  // 				newestOnTop: this.selectedtoastr.newestOnTop,
  // 				progressBar: this.selectedtoastr.progressBar,
  // 				positionClass: this.selectedtoastr.positionClass,
  // 				preventDuplicates: this.selectedtoastr.preventDuplicates,
  // 				onclick: null,
  // 				showDuration: this.selectedtoastr.showDuration,
  // 				hideDuration: this.selectedtoastr.hideDuration,
  // 				timeOut: this.selectedtoastr.timeOut,
  // 				extendedTimeOut: this.selectedtoastr.extendedTimeOut,
  // 				showEasing: this.selectedtoastr.showEasing,
  // 				hideEasing: this.selectedtoastr.hideEasing,
  // 				showMethod: this.selectedtoastr.showMethod,
  // 				hideMethod: this.selectedtoastr.hideMethod
  // 			};
  // 			var refreshIntervalId = setInterval(() => {
  // 				if (indexToastr > -1) {
  // 					this.ActiveToastrs.splice(indexToastr, 1);
  // 				}

  // 				clearInterval(refreshIntervalId);
  // 			}, this.selectedtoastr.timeOut);

  // 			toastr[this.selectedtoastr.toastrType](this.selectedtoastr.description, '');
  // 		} else {
  // 			toastr.options = {
  // 				closeButton: true,
  // 				debug: true,
  // 				newestOnTop: true,
  // 				progressBar: true,
  // 				positionClass: 'toast-top-right',
  // 				preventDuplicates: true,
  // 				onclick: null,
  // 				showDuration: '300',
  // 				hideDuration: '500',
  // 				timeOut: '5000',
  // 				extendedTimeOut: '5000',
  // 				showEasing: 'swing',
  // 				hideEasing: 'linear',
  // 				showMethod: 'fadeIn',
  // 				hideMethod: 'fadeOut'
  // 			};

  // 			var refreshIntervalId = setInterval(() => {
  // 				console.log('ShowToster ActiveToastrs index 1', indexToastr);

  // 				if (indexToastr > -1) {
  // 					this.ActiveToastrs.splice(indexToastr, 1);
  // 				}

  // 				console.log('toastrId....', toastrId);
  // 				clearInterval(refreshIntervalId);
  // 			}, 5000);

  // 			toastr[toastrType](description, '');
  // 		}
  // 	}
  // }

  // ValidateToster(toastrType: string, pageid: string, toastrId: string, description: string, title: string = '') {

  // 	const found = this.toastrs.find((obj) => {
  // 		return obj.toastrId === toastrId;
  // 	});

  // 	if (found !== undefined) {
  // 	} else {
  // 		this.ActiveToastrs.push({ pageid: pageid, toastrId: toastrId, title: title, description: description });

  // 		const indexToastr = this.ActiveToastrs.length - 1;

  // 		this.selectedtoastr = this.toastrs.find((x) => x.pageid == pageid && x.toastrId == toastrId);

  // 		if (this.selectedtoastr == undefined) {

  // 			let nToastr: newToastrDtp = {
  // 				pageid: pageid,
  // 				toastrType: toastrType,
  // 				toastrId: toastrId,
  // 				title: title,
  // 				description: description
  // 			};

  // 			this.selectedtoastr = this.toastrs.find((x) => x.pageid == pageid && x.toastrId == toastrId);
  // 		}
  // 	}
  // }

  infoToster(msg: string, _title?: string) {
    window["toastr"]["info"](msg);
  }

  warningToster(msg: string, _title?: string) {
    window["toastr"]["warning"](msg);
  }
  DevelopSuccess(msg: string, _title?: string) {}
  DevelopError(msg: string, _title?: string) {}

  DevelopInfo(msg: string, _title?: string) {}
  DevelopWarn(msg: string, _title?: string) {}

  success(msg: string, _title?: string) {}

  errorMsg(msg: string, _title?: string) {}

  error(msg: string, _title?: string) {}

  info(msg: string, _title?: string) {}
  warn(msg: string, _title?: string) {}

  //Start Chat & Call Box
  showChatBox(type, name, onlinetype) {
    let lst = this.ChatSetting.ChatList;
    let obj: Chat_Type = null;
    if (lst.length > 0) {
      obj = lst.find((d) => d.Name == name);
    }

    if (type == "chat") {
      if (obj) {
        obj.Is_Show = true;
        obj.Is_Minimize = false;
      } else {
        obj = <Chat_Type>{};
        obj.Is_Show = true;
        obj.Name = name;
        obj.Online_Type = onlinetype;
        obj.Type = type;
        obj.uniqeID = lst.length + 1;
        obj.Is_Minimize = false;

        this.ChatSetting.ChatList.push(obj);
      }
    } else {
      obj = <Chat_Type>{};
      obj.Is_Show = true;
      obj.Name = name;
      obj.Online_Type = onlinetype;
      obj.Type = type;
      obj.uniqeID = lst.length + 1;
      obj.Is_Minimize = false;

      this.ChatSetting.ChatList.push(obj);
    }
  }
  minimizeChat(uniqeID, Is_Minimize) {
    let lst = this.ChatSetting.ChatList.find((d) => d.uniqeID == uniqeID);
    if (lst) {
      lst.Is_Minimize = !Is_Minimize;
    }
  }
  closeChat(uniqeID) {
    this.ChatSetting.ChatList = this.ChatSetting.ChatList.filter(
      (d) => d.uniqeID != uniqeID
    );
  }
  //End Chat & Call Box
  // 18003751126  tm busniss
  CloseAllModels() {}

  HideAllAsides() {}

  public findChoices(searchText: string) {
    return [
      "FirstName",
      "LastName",
      "Location",
      "AddressNumber",
      "Address",
      "City",
      "ZipCode",
      "AppointmentDate",
      "AppointmentTime",
      "AppointmentNotes",
      "ClientUrl",
      "RepJobUrl",
      "ReplyCode",
      "Service",
      "CompanyName",
      "BusinessPhone",
    ].filter((item) => item.toLowerCase().includes(searchText.toLowerCase()));
  }
  public getChoiceLabel(choice: string) {
    return `@${choice} `;
  }
}

class AppHelper {
  public ShowLoader: boolean;
  public ShowFullLoader: boolean;

  public ViewPage: string = "";

  public RunSearch: EventEmitter<any> = new EventEmitter();
  public SelectJsonMap: EventEmitter<any> = new EventEmitter();
  public onGlobalDateChange: EventEmitter<any> = new EventEmitter();

  public LoadMainIframe: EventEmitter<any> = new EventEmitter();

  public onTick: EventEmitter<any> = new EventEmitter();

  public LoadLeadMap: EventEmitter<any> = new EventEmitter();
  public LoadTwmlPage: EventEmitter<any> = new EventEmitter();

  public CloseNgModel: EventEmitter<any> = new EventEmitter();

  public onOpenTopSlide: EventEmitter<any> = new EventEmitter();






  
  public LoadDataListsProspects : EventEmitter<any> = new EventEmitter();



  // public RefreshData: EventEmitter<any> = new EventEmitter()
  // public changeAnimation: EventEmitter<any> = new EventEmitter();
  // public searchFilter: EventEmitter<any> = new EventEmitter();
  // public normalRefreshGrid: EventEmitter<any> = new EventEmitter();
  // public clearandRefreshGrid: EventEmitter<any> = new EventEmitter();
  // public showhideColumnFilter: EventEmitter<any> = new EventEmitter();
  // public clearAllCheckbox: EventEmitter<any> = new EventEmitter();
  //
  // // open close tasts in popup
  // public onOpenTask: EventEmitter<any> = new EventEmitter();

  // public closeOpenTask: EventEmitter<any> = new EventEmitter();
  // public OpenWorkFlowTasks: EventEmitter<any> = new EventEmitter();

  // public onSearch: EventEmitter<any> = new EventEmitter();

  // public onOpenClientCenter: EventEmitter<any> = new EventEmitter();

  // public ReloadInvoice: EventEmitter<any> = new EventEmitter();

  // //open invoice in popup
  // public OpenInvoice: EventEmitter<any> = new EventEmitter();
  // public CloseInvoice: EventEmitter<any> = new EventEmitter();

  // public onPrintInvoice: EventEmitter<any> = new EventEmitter();
  // public onOpenModal: EventEmitter<any> = new EventEmitter();

  // public onWindowSlider: EventEmitter<any> = new EventEmitter();

  // public onRebindNeeded: EventEmitter<any> = new EventEmitter();
  // public onOpenWorkflow: EventEmitter<any> = new EventEmitter();
  // public onOpenWorkflowTask: EventEmitter<any> = new EventEmitter(); //
  public PaletteSettings: any = {
    palette: [
      "#000000",
      "#7f7f7f",
      "#880015",
      "#ed1c24",
      "#ff7f27",
      "#fff200",
      "#22b14c",
      "#00a2e8",
      "#FF6F61",
      "#6B5B95",
      "#88B04B",
      "#F7CAC9",
      "#92A8D1",
      "#955251",
      "#B565A7",
      "#3f48cc",
      "#a349a4",
      "#ffffff",
      "#c3c3c3",
      "#b97a57",
      "#ffaec9",
      "#ffc90e",
      "#efe4b0",
      "#b5e61d",
      "#99d9ea",
      "#7092be",
      "#c8bfe7",
      "#009B77",
      "#DD4124",
      "#D65076",
      "#45B8AC",
      "#EFC050",
      "#5B5EA6",
      "#9B2335",
      "#DFCFBE",
      "#55B4B0",
      "#98B4D4",
      "#7FCDCD",
      "#BC243C",
      "#C3447A",
    ],
    columns: 5,
    tileSize: 30,
  };

  public get GlobalBaseUrl(): string {

     //return "https://localhost:44354";
     
     //    if(this.service.CurentUserData.roleId =='e163b520-ee75-45d6-b708-c7dcaa54b520'){
     // //admin
     //    }
     //    else if(this.service.CurentUserData.roleId =='bdc13b0f-e7a5-4d7b-8052-ea3a77891209'){
     //     //referral
     //    }
     //    else{
     //     this.service.logOut();
     //     return '';
     //    }
 
     let surl = window.location.href;
     if (surl.indexOf("localhost") != -1) {
       this.service.debugMode = true;
       this.service.AllowInQA = true;
       //return "https://www.eso-system.net";
      return "https://localhost:44354";
     } else if (surl.indexOf("test-qdqui") != -1) {
       this.service.debugMode = true;
       this.service.AllowInQA = true;
       return "https://www.eso-system.net";
     } else if (surl.indexOf("qa-qdqui") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = true;
       return "https://www.eso-system.net";
     } else if (surl.indexOf("rel-qdqui") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "https://www.eso-system.net";
     } else if (surl.indexOf("qdqui") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "https://www.eso-system.net";
     } else if (surl.indexOf("leadmop") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "https://www.eso-system.net";
     } else {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "https://www.eso-system.net";
     }
   }


   public get WebSoketGlobalBaseUrl(): string {

   
     let surl = window.location.href;
     if (surl.indexOf("localhost") != -1) {
       this.service.debugMode = true;
       this.service.AllowInQA = true;
       //return "https://www.eso-system.net";
       return "wss://localhost:44354";
     } else if (surl.indexOf("test-qdqui") != -1) {
       this.service.debugMode = true;
       this.service.AllowInQA = true;
       return "wss://www.eso-system.net";
     } else if (surl.indexOf("qa-qdqui") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = true;
       return "wss://www.eso-system.net";
     } else if (surl.indexOf("rel-qdqui") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "wss://www.eso-system.net";
     } else if (surl.indexOf("qdqui") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "wss://www.eso-system.net";
     } else if (surl.indexOf("leadmop") != -1) {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "wss://www.eso-system.net";
     } else {
       this.service.debugMode = false;
       this.service.AllowInQA = false;
       return "wss://www.eso-system.net";
     }
   }

   
  

  public get GetEnv(): string {
    let surl = window.location.href;
    if (surl.indexOf("localhost") != -1) {
      return "development";
    } else if (surl.indexOf("test-qdapi") != -1) {
      return "test";
    } else if (surl.indexOf("qa-qdapi") != -1) {
      return "qa";
    } else if (surl.indexOf("rel-qdapi") != -1) {
      return "release";
    } else if (surl.indexOf("qdapi") != -1) {
      return "production";
    } else {
      return "development";
    }
  }

  public _appData: any = {};
  private _ipaddress: string;
  private _APIDomain: string;

  public get TimeZone(): number {
    return new Date().getTimezoneOffset() / 60;
  }
  public get SessionID(): string {
    if (!this.getCookie("sessionID")) {
      this.setCookie("sessionID", this.CreateGuid(), new Date(), true);
    }
    return this.getCookie("sessionID");
  }
  public get IP_Address(): string {
    return this._ipaddress;
  }
  public set IP_Address(value: string) {
    this._ipaddress = value;
  }

  public get APIDomain(): string {
    return this._APIDomain;
  }
  public set APIDomain(value: string) {
    this._APIDomain = value;
  }
  // getting  value  lose data after refresh
  GetData<T>(key: string): T {
    return <T>this._appData[key];
  }
  // saving  value  lose data after refresh
  SetData(key: string, data: any) {
    if (data == null || data == undefined) {
      delete this._appData[key];
    } else {
      this._appData[key] = data;
    }
  }

  // getting session value remain in browser after refrash page
  GetSessionData<T>(key: string): T {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch {
      return <any>sessionStorage.getItem(key);
    }
  }

  GetTwilioRecording() {}

  // saving item in to session value remain in browser after refrash page
  SetSessionData(key: string, data: any) {
    if (data == null || data == undefined) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(
        key,
        typeof data === "string" ? data : JSON.stringify(data)
      );
    }
  }

  // Removing value remain in browser after refrash page
  RemoveSessionData(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch {}
  }

  setCookie(cname, cvalue, date: Date | number, isSession?) {
    let d = new Date();
    if (date instanceof Date) {
      d = <Date>date;
    } else {
      d.setTime(d.getTime() + <number>date * 24 * 60 * 60 * 1000);
    }
    if (!isSession) {
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    } else {
      document.cookie = cname + "=" + cvalue + ";path=/";
    }
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  CreateGuid() {
    let _p8 = (s?) => {
      var p = (Math.random().toString(16) + "000000000").substr(2, 8);
      return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    };
    return _p8() + _p8(true) + _p8(true) + _p8();
  }

  constructor(private service: AppService) {}

  CreateBrowserActivity(event: string, dataattr: string) {
    let parm = {
      AccountGuid: "00000000-0000-0000-0000-000000000000",
      ContactGuid: this.service.Account.userTokenDto.contactGuid,
      SessionId: this.service.App.SessionID,
      Page: event,
      Date: this.service.Moment(new Date()).format("MM/DD/YYYY HH:mm:ss"),
      DataAttr: dataattr,
    };
  }

  unmaskPhone(value): any {
    if (value) {
      return value.replace(/\D+/g, "");
    }
    return value;
  }


  convertToDuration(seconds) {

    if(seconds < 0){
      return   '--:--'  ;
    }
    var cminutes = Math.floor(seconds / 60);
    var secs = seconds % 60; 
    var minutes = cminutes % 60;
    var _houres = Math.floor(cminutes / 60);
    var houres = _houres % 60;
    // Convert to two-digit format
    var formattedHoures = houres.toString().padStart(2, '0');
    var formattedMinutes = minutes.toString().padStart(2, '0');
    var formattedSeconds = secs.toString().padStart(2, '0');
    
    return formattedHoures + ':' + formattedMinutes + ':' + formattedSeconds;
    }

     
    convertToMinutesDuration(seconds) {

      if(seconds < 0){
        return   '--:--'  ;
      }
      var cminutes = Math.floor(seconds / 60);
      var secs = seconds % 60; 
      var minutes = cminutes % 60;
      var _houres = Math.floor(cminutes / 60);
      var houres = _houres % 60;
      // Convert to two-digit format
      var formattedHoures = houres.toString().padStart(2, '0');
      var formattedMinutes = minutes.toString().padStart(2, '0');
      var formattedSeconds = secs.toString().padStart(2, '0');
      //formattedHoures + ':' + 
      return formattedMinutes + ':' + formattedSeconds;
      }

  convertToHoursDuration(seconds) {

    if(seconds < 0){
      return   '--:--'  ;
    }
    var cminutes = Math.floor(seconds / 60);
    var secs = seconds % 60; 
    var minutes = cminutes % 60;
    var _houres = Math.floor(cminutes / 60);
    var houres = _houres % 60;
    // Convert to two-digit format
    var formattedHoures = houres.toString().padStart(2, '0');
    var formattedMinutes = minutes.toString().padStart(2, '0');
    var formattedSeconds = secs.toString().padStart(2, '0');
    
    return formattedHoures + ':' + formattedMinutes;// + ':' + formattedSeconds;
    }

    
}

class DataHelper {
  public defered = new Deferred<boolean>();
  public objDefered: any = {};
  public objEvents: any = {};
  public conn: any;

  public get IsConnected(): boolean {
    return false;
  }

  public get DevelopmentEnv(): boolean {
    let surl = window.location.href;
    if (surl.indexOf("localhost") != -1) {
      return true;
    } else {
      return false;
    }
  }

  constructor(
    public http: HttpClient,
    public App: AppHelper,
    private service: AppService,
    private sanitizer: DomSanitizer
  ) {}

  public init(): Promise<any> {
    return this.defered.promise;
  }

  ExecuteAction<T>(...args: any[]): Promise<T> {
    let action: string = args[0].action;
    let deffred = new Deferred<T>();
    this.objDefered[action.toLowerCase()] = deffred;

    let id;
    let timeout = new Promise<T>((_resolve, reject) => {
      id = setTimeout(
        (d, a) => {
          clearTimeout(id);
          if (d[a]) {
            reject(null);
          }
        },
        50000,
        this.objDefered,
        action
      );
    });

    return Promise.race([deffred.promise, timeout])
      .then((result) => {
        window.clearTimeout(id);
        return result;
      })
      .catch(() => {
        return null;
      });
  }

  registerReceiver<T>(action: string): EventEmitter<T> {
    action = action.toLowerCase();
    let event = this.objEvents[action];
    if (!event) {
      event = new EventEmitter<T>();
      this.objEvents[action] = event;
    }
    return event;
  }

  startConnection(): JQueryPromise<any> {
    return null;
  }

  stopConnection() {}

  callAspxPage(username: string, password: string) {
    const url = "https://localhost:44378/SignIn.aspx"; //Username=' + username;// + '&Password=' + password;
    // const username = 'myusername';
    // const password = 'mypassword';

    const form = document.createElement("form");
    form.action = url;
    form.target = "_blank";
    form.method = "GET";

    // Set the headers as hidden form fields
    // const usernameField = document.createElement('input');
    // usernameField.type = 'hidden';
    // usernameField.name = 'Username';
    // usernameField.value = username;
    // form.appendChild(usernameField);

    // const passwordField = document.createElement('input');
    // passwordField.type = 'hidden';
    // passwordField.name = 'Password';
    // passwordField.value = password;
    // form.appendChild(passwordField);

    document.body.appendChild(form);

    form.submit();

    document.body.removeChild(form);
  }

  iframeUrl: SafeResourceUrl;
  iframeHeaders: { [header: string]: string };

  ExecuteAPI_Get_Login<T>(Username: string, password: string) {
    console.log("ExecuteAPI_Get_Login", Username);
    console.log("ExecuteAPI_Get_Login", password);

    let headers = new HttpHeaders({
      Username: Username,
      Password: password,
    });
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://localhost:44378/SignIn.aspx"
    );
    this.iframeHeaders = {
      Username: Username,
      Password: password,
    };

    // this.http.get('https://localhost:44378/SignIn.aspx', { headers }).subscribe(
    //   (response) => {
    //     // Handle the response
    //   },
    //   (error) => {
    //     // Handle the error
    //   }
    // );

    // Build the header string
    const headerString = Array.from(headers.keys())
      .map((key) => `${key}: ${headers.get(key)}`)
      .join("\r\n");

    // Open the new window/tab with the ASPX page and pass the headers
    //window.open('https://localhost:44378/SignIn.aspx', '_blank', 'headers=' + encodeURIComponent(headerString));
  }

  ExecuteAPI_Get_Normal<T>(action: string): Promise<T> {
    let promise: Promise<T>;
    this.service.LogtoDataDog(
      "ExecuteAPI_Get_Normal: " + action,
      StatusType.info
    );
    this.service.LogtoDataDog(
      "ExecuteAPI_Get_Normal: " + action,
      StatusType.info
    );

    if (localStorage.getItem("token")) {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          userTokenDto: localStorage.getItem("userTokenDto"),
        }),
      };
      promise = firstValueFrom(this.http.get<T>(action, httpOptions)); //toPromise<T>();
    } else {
      promise = firstValueFrom(this.http.get<T>(action)); //.toPromise<T>();
    }

    promise.catch((err: HttpErrorResponse) => {
      this.service.LogtoDataDog(
        "ExecuteAPI_Get_Normal: " + action,
        StatusType.info
      );
      if (err.status == 401) {
        this.service.logOut();
      }
    });
    return promise;
  }

  ExecutePublicAPI_Get<T>(url: string): Promise<T> {
    let promise: Promise<T>;
    //console.log('ExecutePublicAPI_Get', url)

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "text/html",
      }),
    };

    promise = firstValueFrom(this.http.get<T>(url, httpOptions)); //.toPromise<T>();

    promise.catch((err: HttpErrorResponse) => {
      if (err.status == 401) {
        this.service.logOut();
      }
    });

    return promise;
  }

  ExecuteAPI_Get<T>(action: string): Promise<T> {
    let promise: Promise<T>;

    if (this.IsConnected == false) {
    }

    action =
      (this.App.APIDomain || this.service.App.GlobalBaseUrl) + "/0/" + action;

    //console.log('ExecuteAPI_Get action', action);
    //console.log('ExecuteAPI_Get token', localStorage.getItem("token"))
    if (localStorage.getItem("token")) {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          userTokenDto: localStorage.getItem("userTokenDto"),
        }),
      };

      promise = firstValueFrom(this.http.get<T>(action, httpOptions)); //.toPromise<T>();
    } else {
      promise = firstValueFrom(this.http.get<T>(action)); //.toPromise<T>();
    }

    // console.log('ExecuteAPI_Get promise', promise)

    // this.service.LogtoDataDog('ExecuteAPI_Get: ' + action ,  JSON.stringify(promise) )
    promise.catch((err: HttpErrorResponse) => {
      this.service.LogtoDataDog("ExecuteAPI_Get: " + action, StatusType.info);

      if (err.status == 401) {
        this.service.logOut();
      }
    });

    return promise;
  }
  ExecuteAPI_Post<T>(action: string, params: any = {}): Promise<T> {
    let promise: Promise<T>;

    try {
      this.service.LogtoDataDog("ExecuteAPI_Post: " + action, StatusType.info);
      this.service.LogtoDataDog("ExecuteAPI_Post: " + action, StatusType.info);

      action =
        (this.App.APIDomain || this.service.App.GlobalBaseUrl) + "/0/" + action;
      if (localStorage.getItem("token")) {
        let httpOptions = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
            userTokenDto: localStorage.getItem("userTokenDto"),
          }),
        };
        promise = firstValueFrom(
          this.http.post<T>(action, params, httpOptions)
        ); //.toPromise<T>();
      } else {
        promise = firstValueFrom(this.http.post<T>(action, params)); //.toPromise<T>();
      }
      //console.log('ExecuteAPI_Post promise', promise)
      promise.catch((err: HttpErrorResponse) => {
        this.service.LogtoDataDog(
          "ExecuteAPI_Post: " + action,
          StatusType.info
        );
        if (err.status == 401) {
          this.service.logOut();
        }
      });
      try {
        //this.service.Data.GeActionItems(action, promise);
      } catch (ex) {}

      return promise;
    } catch (ex) {
      console.log("ExecuteAPI_Post", ex);
      return promise;
    }
  }

  ExecuteAPI_Post_Special<T>(action: string, params: any = {}): Promise<T> {
    let promise: Promise<T>;
    this.service.LogtoDataDog(
      "ExecuteAPI_Post_Special: " + action,
      StatusType.info
    );
    this.service.LogtoDataDog(
      "ExecuteAPI_Post_Special: " + action,
      StatusType.info
    );
    if (localStorage.getItem("token")) {
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          userTokenDto: localStorage.getItem("userTokenDto"),
        }),
      };
      promise = firstValueFrom(this.http.post<T>(action, params, httpOptions)); //.toPromise<T>();
    } else {
      promise = firstValueFrom(this.http.post<T>(action, params)); //.toPromise<T>();
    }

    promise.catch((err: HttpErrorResponse) => {
      this.service.LogtoDataDog(
        "ExecuteAPI_Post_Special: " + action,
        StatusType.info
      );
      if (err.status == 401) {
        this.service.logOut();
      }
    });
    return promise;
  }

  ExecuteAPI_Post_Local<T>(action: string, params: any = {}): Promise<T> {
    let promise: Promise<T>;
    try {
      this.service.LogtoDataDog(
        "ExecuteAPI_Post_Local: " + action,
        StatusType.info
      );

      let url = action;
      promise = firstValueFrom(this.http.post<T>(url, params, httpOptions)); //.toPromise<T>();
      promise.catch((err: HttpErrorResponse) => {
        this.service.LogtoDataDog(
          "ExecuteAPI_Post_Local: " + action,
          StatusType.info
        );
        if (err.status == 401) {
          this.service.logOut();
        }
      });
      //console.log('ExecuteAPI_Post_Local promise', promise)
      return promise;
    } catch (ex) {
      //console.log('ExecuteAPI_Post_Local', ex)
      return promise;
    }
  }

  GetObjectItems(objectName, resp) {
    try {
      //console.log('GetObjectItems:resp', resp)

      let formitems = objectName + "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          formitems +=
            str + ': string = "00000000-0000-0000-0000-000000000000"; \n';
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += str + ": Date = new Date(); \n";
        } else if (val === true || val === false) {
          formitems += str + ": boolean = false; \n";
        } else if (Number(val)) {
          //isNumeric

          formitems += str + ": number = 0; \n";
        } else {
          formitems += str + ': string = ""; \n';
        }
      });
      if (this.DevelopmentEnv == true) {
      }

      formitems += "\n \n";
      if (this.service.IsDevelopment == true) {
      }
      //console.log("GetObjectItems:formitems", formitems);
      return formitems;
    } catch (ex) {
      console.log("GetObjectItems:catch", ex);
    }
  }

  GetEditableObjectItems(objectName, resp) {
    try {
      //console.log('GetObjectItems:resp', resp)

      let formitems = objectName + "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += str + ": Date = new Date(); \n";
        } else if (val === true || val === false) {
          formitems += str + ": boolean = false; \n";
        } else if (Number(val)) {
          //isNumeric

          formitems += str + ": number = 0; \n";
        } else {
          formitems += str + ': string = ""; \n';
        }
      });
      if (this.DevelopmentEnv == true) {
      }

      formitems += "\n \n";
      if (this.service.IsDevelopment == true) {
      }
      //console.log("GetObjectItems:formitems", formitems);
      return formitems;
    } catch (ex) {
      console.log("GetObjectItems:catch", ex);
    }
  }

  sortObj(obj) {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  }

  BuildObjectClassItems(objectName, resp) {
    try {
      if (this.service.IsDevelopment == true) {
      }

      resp = this.sortObj(resp);

      let formitems = objectName + "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          formitems +=
            str + ' : string = "00000000-0000-0000-0000-000000000000"; \n';
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += str + " : Date = new Date(); \n";
        } else if (val === true || val === false) {
          formitems += str + " : boolean = false; \n";
        } else if (Number(val)) {
          //isNumeric

          formitems += str + " : number = 0; \n";
        } else {
          formitems += str + ' : string = ""; \n';
        }
      });
      if (this.DevelopmentEnv == true) {
      }

      formitems += "\n \n";

      return formitems;
    } catch (ex) {
      return ex;
    }
  }

  BuildObjectItems(objectName, resp) {
    try {
      if (this.service.IsDevelopment == true) {
      }

      let formitems = "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          formitems +=
            '"' +
            str +
            '" : string = "00000000-0000-0000-0000-000000000000"; \n';
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += '"' + str + '" : Date = new Date(); \n';
        } else if (val === true || val === false) {
          formitems += '"' + str + '" : boolean = false; \n';
        } else if (Number(val)) {
          //isNumeric

          formitems += '"' + str + '" : number = 0; \n';
        } else {
          formitems += '"' + str + '" : string = ""; \n';
        }
      });
      if (this.DevelopmentEnv == true) {
      }

      formitems += "\n \n";

      return formitems;
    } catch (ex) {
      return ex;
    }
  }

  GeActionItems(action, data) {
    try {
      let resp = {};
      if (Array.isArray(data)) {
        resp = data[0];
      } else {
        resp = data;
      }
      let formitems = "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          formitems +=
            str + ': string = "00000000-0000-0000-0000-000000000000"; \n';
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += str + ": Date = new Date(); \n";
        } else if (val === true || val === false) {
          formitems += str + ": boolean = false; \n";
        } else if (Number(val)) {
          //isNumeric

          formitems += str + ": number = 0; \n";
        } else {
          formitems += str + ': string = ""; \n';
        }
      });

      console.log(
        "GeActionItems \n \n" + action + "\n \n" + formitems + "\n \n"
      );
      return "GeActionItems \n \n" + action + "\n \n" + formitems + "\n \n";
    } catch (ex) {
      console.log("GeActionItems " + action + " " + ex);
      return "GeActionItems \n \n" + action + "\n \n" + ex + "\n \n";
    }
  }

  GeClassItems(tbname, data) {
    try {
      let resp = {};
      if (Array.isArray(data)) {
        resp = data[0];
      } else {
        resp = data;
      }
      let formitems = "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          formitems +=
            str + ': string = "00000000-0000-0000-0000-000000000000"; \n';
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += str + ": Date = new Date(); \n";
        } else if (val === true || val === false) {
          formitems += str + ": boolean = false; \n";
        } else if (Number(val)) {
          //isNumeric

          formitems += str + ": number = 0; \n";
        } else {
          formitems += str + ': string = ""; \n';
        }
      });
      console.log("GeClassItems \n \n" + formitems + "\n \n");
      return "GeClassItems \n \n" + tbname + "\n \n" + formitems + "\n \n";
    } catch (ex) {
      return "GeClassItems \n \n" + ex + "\n \n";
    }
  }

  GeColumnsItems(data) {
    try {
      let resp = {};
      if (Array.isArray(data)) {
        resp = data[0];
      } else {
        resp = data;
      }
      let ColumnsItems = [];
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          ColumnsItems.push({ field: str, title: str, type: "guid" });
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "created" ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase().indexOf("utc") > 0 ||
          str.toLowerCase().indexOf("pst") > 0
        ) {
          ColumnsItems.push({ field: str, title: str, type: "date" });
        } else if (val === true || val === false) {
          ColumnsItems.push({ field: str, title: str, type: "bit" });
        } else if (Number(val)) {
          //isNumeric

          ColumnsItems.push({ field: str, title: str, type: "number" });
        } else {
          ColumnsItems.push({ field: str, title: str, type: "text" });
        }
      });

      return ColumnsItems;
    } catch (ex) {
      return [];
    }
  }

  GeColumnsFlters(data: any, inputValue: string) {
    try {
      let resp = {};
      if (Array.isArray(data)) {
        resp = data[0];
      } else {
        resp = data;
      }
      let ColumnsItems = [];
      jQuery.each(resp, function (str: string, val) {
        if (
          str.toLowerCase().indexOf("guid") > 0 ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "created" ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase().indexOf("utc") > 0 ||
          str.toLowerCase().indexOf("pst") > 0
        ) {
          ColumnsItems.push({
            field: str,
            operator: "contains",
            value: inputValue,
          });
        } else if (val === true || val === false) {
        } else if (Number(val)) {
          //isNumeric

          ColumnsItems.push({
            field: str,
            operator: "contains",
            value: inputValue,
          });
        } else {
          ColumnsItems.push({
            field: str,
            operator: "contains",
            value: inputValue,
          });
        }
      });

      return ColumnsItems;
    } catch (ex) {
      return [];
    }
  }

  GetObjectValueItems(objName, data) {
    try {
      let resp = {};
      if (Array.isArray(data)) {
        resp = data[0];
      } else {
        resp = data;
      }
      let formitems = "\n";
      jQuery.each(resp, function (str: string, val) {
        formitems += objName + "." + str + "\n";
      });

      return formitems;
    } catch (ex) {
      return ex;
    }
  }

  GetFormFbItems(fbname: string, resp: any) {
    try {
      let formitems = "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (val === true || val === false) {
          formitems += "'" + str + "'" + ":[" + val + "], \n";
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase() == "qbtimecreated"
        ) {
          formitems += "'" + str + "'" + ':[""], \n';
        } else if (Number(val)) {
          formitems += "'" + str + "'" + ": [0], \n";
        } else if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
          formitems +=
            "'" + str + "'" + ':["00000000-0000-0000-0000-000000000000"], \n';
        } else {
          formitems += "'" + str + "'" + ':[""], \n';
        }
      });
      console.log(
        "GetFormFbItems \n \n" + fbname + " \n \n" + formitems + "\n \n"
      );
      return "GetFormFbItems \n \n" + fbname + " \n \n" + formitems + "\n \n";
    } catch (ex) {
      return "GetFormFbItems error \n \n" + fbname + " \n \n" + ex + "\n \n";
    }
  }

  isValidDate(value) {
    var dateWrapper = new Date(value);
    return !isNaN(dateWrapper.getDate());
  }

  GetKendoUiGridItems(resp) {
    try {
      let kendoitems = "\n \n";
      jQuery.each(resp, function (str: string, val) {
        if (val === true || val === false) {
          kendoitems +=
            ' <kendo-grid-column [headerClass]="table-header" field="' +
            str +
            '" title="' +
            str +
            '" class="k-text-center" width="60"> </kendo-grid-column>\n';
        } else if (
          str.toLowerCase().indexOf("date") > 0 ||
          str.toLowerCase() == "created" ||
          str.toLowerCase() == "updated" ||
          str.toLowerCase() == "inserted" ||
          str.toLowerCase().indexOf("utc") > 0 ||
          str.toLowerCase().indexOf("pst") > 0
        ) {
          kendoitems +=
            ' <kendo-grid-column [headerClass]="table-header" field="' +
            str +
            '" title="' +
            str +
            '"    class="k-text-center"  width="120" ><ng-template kendoGridCellTemplate let-dataItem>{{dataItem.' +
            str +
            ' | date: "MM/dd/yy hh:mm"}} </ng-template> </kendo-grid-column>\n';
        } else if (Number(val)) {
          kendoitems +=
            ' <kendo-grid-column [headerClass]="table-header" field="' +
            str +
            '" title="' +
            str +
            '" class="k-text-center" width="90"> </kendo-grid-column>\n';
        } else if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
        } else {
          kendoitems +=
            ' <kendo-grid-column [headerClass]="table-header" field="' +
            str +
            '" title="' +
            str +
            '"  width="90"> </kendo-grid-column>\n';
        }
      });

      return "GetKandoUiGridItems \n \n" + kendoitems + "\n \n";
    } catch (ex) {
      return "GetKandoUiGridItems error \n \n" + ex + "\n \n";
    }
  }

  GetHtmlTable(obgName: string, db) {
    try {
      let htmlresponse = '<table class="table table-stariped">\n';
      htmlresponse += "<thead>\n";
      htmlresponse += "<tr>\n";
      jQuery.each(db, function (str: string, val) {
        var dt = moment(val, this.formats, true).isValid();
        if (Number(val)) {
          dt = false;
        }
        let isGuid = false;
        let sval = "" + val;
        let isuid = sval.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        );
        // console.log("GetHtmlList:" + sval ,isuid  );
        if (isuid === null) {
          isGuid = false;
        } else {
          isGuid = true;
        }

        if (
          isGuid == true ||
          str.toLowerCase().indexOf("sid") > 0 ||
          str.toLowerCase().indexOf("varid") > 0
        ) {
        } else {
          htmlresponse += "<th>" + str + "</th>" + "\n";
        }
      });

      htmlresponse += "</tr>\n";
      htmlresponse += "</thead>\n";

      htmlresponse +=
        '\n<tr *ngFor="let item of ' + obgName.toString() + '; let i = index">';
      jQuery.each(db, function (str: string, val) {
        var dt = moment(val, this.formats, true).isValid();
        if (Number(val)) {
          dt = false;
        }
        let isGuid = false;
        let sval = "" + val;
        let isuid = sval.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        );
        // console.log("GetHtmlList:" + sval ,isuid  );
        if (isuid === null) {
          isGuid = false;
        } else {
          isGuid = true;
        }

        if (
          isGuid == true ||
          str.toLowerCase().indexOf("sid") > 0 ||
          str.toLowerCase().indexOf("varid") > 0
        ) {
        } else if (val === true || val === false) {
          htmlresponse += "<td>{{item." + str + "}}</td>" + "\n";
        } else if (dt == true) {
          htmlresponse +=
            "<td>{{item." + str + " | date : 'hh:mm'}}</td>" + "\n";
        } else if (Number(val)) {
          htmlresponse += "<td>{{item." + str + "}}</td>" + "\n";
        } else {
          htmlresponse += "<td>{{item." + str + "}}</td>" + "\n";
        }
      });

      htmlresponse += "   </tr>\n</table>";
      console.log("GetHtmlTable \n \n" + htmlresponse + "\n \n");
      return "GetHtmlTable \n \n" + htmlresponse + "\n \n";
    } catch (ex) {
      return "GetHtmlTable \n \n" + ex + "\n \n";
    }
  }

  formats = [moment.ISO_8601, "MM/DD/YYYY  :)  HH*mm*ss"];

  isUUID(uuid) {
    let s = "" + uuid;
    let isuid = s.match(
      "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
    );
    if (isuid === null) {
      return false;
    }
    return true;
  }

  // comments: [""],
  // email: ["", Validators.compose([Validators.email])],
  // cellPhone: [
  //   "",
  //   Validators.compose([Validators.pattern("^[2-9][0-9]{9}$")]),
  // ],
  // firstName: ["", Validators.required],

  // latitude: [
  //   "0.00",
  //   Validators.compose([Validators.pattern(this.latPattern)]),
  // ],

  //firstName: ["ben" + this.cnm.toString(), Validators.required],

  LoadFormElement(obgName: string, db) {
    try {
      let formCode = "";
      let i = 0;
      jQuery.each(db, function (str: string, val) {
        var dt = moment(val, this.formats, true).isValid();
        if (Number(val)) {
          dt = false;
        }
        let isGuid = false;
        let sval = "" + val;
        let isuid = sval.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        );
        // console.log("GetHtmlList:" + sval ,isuid  );
        if (isuid === null) {
          isGuid = false;
        } else {
          isGuid = true;
        }

        if (
          isGuid == true ||
          str.toLowerCase().indexOf("sid") > 0 ||
          str.toLowerCase().indexOf("varid") > 0
        ) {
        } else if (val === true || val === false) {
          formCode +=
            str +
            " : [" +
            obgName +
            "." +
            str +
            " , Validators.required]," +
            "\n";
        } else if (dt == true) {
          formCode +=
            str +
            " : [" +
            obgName +
            "." +
            str +
            " , Validators.required]," +
            "\n";
        } else if (Number(val)) {
          formCode +=
            str +
            " : [" +
            obgName +
            "." +
            str +
            " , Validators.required]," +
            "\n";
        } else {
          if (val == "") {
            formCode += str + " : [" + obgName + "." + str + " ]," + "\n";
          } else {
            formCode +=
              str +
              " : [" +
              obgName +
              "." +
              str +
              " , Validators.required]," +
              "\n";
          }
        }
        i++;
      });

      console.log("GetFormElement \n \n" + formCode + "\n \n");
      return "GetFormElement \n \n" + formCode + "\n \n";
    } catch (ex) {
      return "GetFormElement \n \n" + ex + "\n \n";
    }
  }

  GetFormElement(obgName: string, db) {
    try {
      let formCode = "";

      jQuery.each(db, function (str: string, val) {
        var dt = moment(val, this.formats, true).isValid();
        if (Number(val)) {
          dt = false;
        }
        let isGuid = false;
        let sval = "" + val;
        let isuid = sval.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        );
        // console.log("GetHtmlList:" + sval ,isuid  );
        if (isuid === null) {
          isGuid = false;
        } else {
          isGuid = true;
        }

        if (
          isGuid == true ||
          str.toLowerCase().indexOf("sid") > 0 ||
          str.toLowerCase().indexOf("varid") > 0
        ) {
        } else if (val === true || val === false) {
          formCode += str + ' : ["", Validators.required],' + "\n";
        } else if (dt == true) {
          formCode += str + ' : ["", Validators.required],' + "\n";
        } else if (Number(val)) {
          formCode += str + ' : ["", Validators.required],' + "\n";
        } else {
          if (val == "") {
            formCode += str + ' : [""],' + "\n";
          } else {
            formCode += str + ' : ["", Validators.required],' + "\n";
          }
        }
      });

      console.log("GetFormElement \n \n" + formCode + "\n \n");
      return "GetFormElement \n \n" + formCode + "\n \n";
    } catch (ex) {
      return "GetFormElement \n \n" + ex + "\n \n";
    }
  }

  //this.formatLabelPipe.transform
  GetFormInputsElement(obgName: string, db) {
    try {
      let formCode = "";

      jQuery.each(db, function (str: string, val) {
        let label = str
          .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
          .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
          .replace(/\b([a-z])/g, function (_, initial) {
            return initial.toUpperCase();
          });

        if (val != "") {
          var dt = moment(val, this.formats, true).isValid();
          if (Number(val)) {
            dt = false;
          }
          let isGuid = false;
          let sval = "" + val;
          let isuid = sval.match(
            "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
          );
          // console.log("GetHtmlList:" + sval ,isuid  );
          if (isuid === null) {
            isGuid = false;
          } else {
            isGuid = true;
          }

          if (
            isGuid == true ||
            str.toLowerCase().indexOf("sid") > 0 ||
            str.toLowerCase().indexOf("varid") > 0
          ) {
          } else if (val === true || val === false) {
            formCode += `<div class="form-group">
      <label for="${str}">${label}</label>
      <input type="text" id="${str}" class="form-control" formControlName="${str}">
      </div>\n`;

            // formCode += ' <div class="form-group">'
            // + '<label for="' + str + '">{{ '+ this.formatLabel(str)  + ' | formatLabel }}</label>'
            // + '<input type="text" id="' + str + '" class="form-control" formControlName="' + str + '">'
            // + '</div>' + "\n";
          } else if (dt == true) {
            formCode += `<div class="form-group">
                <label for="${str}">${label}</label>
                <input type="date" id="${str}" class="form-control" formControlName="${str}">
                </div>\n`;

            // formCode += ' <div class="form-group">'
            //   + '<label for="' + str + '">' + this.formatLabel(str)  + '</label>'
            //   + '<input type="date" id="' + str + '" class="form-control" formControlName="' + str + '">'
            //   + '</div>' + "\n";
          } else if (Number(val)) {
            formCode += `<div class="form-group">
                <label for="${str}">${label}</label>
                <input type="number" id="${str}" class="form-control" formControlName="${str}">
                </div>\n`;

            // formCode += ' <div class="form-group">'
            // + '<label for="' + str + '">' + this.formatLabel(str)  + '</label>'
            // + '<input type="number" id="' + str + '" class="form-control" formControlName="' + str + '">'
            // + '</div>' + "\n";
          } else {
            formCode += `<div class="form-group">
                <label for="${str}">${label}</label>
                <input type="text" id="${str}" class="form-control" formControlName="${str}">
                </div>\n`;

            // formCode += ' <div class="form-group">'
            // + '<label for="' + str + '">' + this.formatLabel(str)  + '</label>'
            // + '<input type="text" id="' + str + '" class="form-control" formControlName="' + str + '">'
            // + '</div>' + "\n";
          }
        }
      });

      console.log("GetFormElement \n \n" + formCode + "\n \n");
      return "GetFormElement \n \n" + formCode + "\n \n";
    } catch (ex) {
      return "GetFormElement \n \n" + ex + "\n \n";
    }
  }

  GetHtmlList(obgName, tagName, db) {
    try {
      let htmlresponse =
        '<ul class="list-group" *ngFor="let ' +
        tagName +
        " of " +
        obgName +
        '; let i = index">\n';
      jQuery.each(db, function (str: string, val) {
        var dt = moment(val, this.formats, true).isValid();
        if (Number(val)) {
          dt = false;
        }
        let isGuid = false;
        let sval = "" + val;
        let isuid = sval.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        );
        // console.log("GetHtmlList:" + sval ,isuid  );
        if (isuid === null) {
          isGuid = false;
        } else {
          isGuid = true;
        }

        if (val === true || val === false) {
          htmlresponse +=
            "<li class='list-group-item'><span class='text-muted'>" +
            str.replace(/([A-Z])/g, " $1").trim() +
            "</span> : {{" +
            tagName +
            "." +
            str +
            "}}</li>" +
            "\n";
        } else if (dt == true) {
          htmlresponse +=
            "<li class='list-group-item'><span class='text-muted'>" +
            str.replace(/([A-Z])/g, " $1").trim() +
            "</span> : {{" +
            tagName +
            "." +
            str +
            " | date : 'MM/dd/yy hh:mm'}}</li>" +
            "\n";
        } else if (
          str.toLowerCase().indexOf("varid") > 0 ||
          str.toLowerCase().indexOf("guid") > 0 ||
          isGuid == true
        ) {
        } else if (Number(val)) {
          htmlresponse +=
            "<li class='list-group-item'><span class='text-muted'>" +
            str.replace(/([A-Z])/g, " $1").trim() +
            "</span> : {{" +
            tagName +
            "." +
            str +
            "}}</li>" +
            "\n";
        } else if (
          str.toLowerCase().indexOf("guid") > 0 ||
          str.toLowerCase() == "userid" ||
          val == "00000000-0000-0000-0000-000000000000"
        ) {
        } else {
          htmlresponse +=
            "<li class='list-group-item'><span class='text-muted'>" +
            str.replace(/([A-Z])/g, " $1").trim() +
            "</span> : {{" +
            tagName +
            "." +
            str +
            "}}</li>" +
            "\n";
        }
      });

      htmlresponse += "</ul>";
      console.log("GetHtmlList \n \n" + htmlresponse + "\n \n");
      return "GetHtmlList \n \n" + htmlresponse + "\n \n";
    } catch (ex) {
      console.log("GetHtmlList exex" + ex);
      return "GetHtmlList \n \n" + ex + "\n \n";
    }
  }

  GetHtmlCard(obgName, tagName, db) {
    try {
      let htmlresponse =
        '<div class="card   shadow-0 mb-g shadow-sm-hover  mb-1">\n';
      htmlresponse +=
        '<div class="card-header p-1 d-flex align-items-center flex-wrap" >\n';

      htmlresponse += obgName;

      htmlresponse += "</div>\n";

      htmlresponse +=
        '\n<div class="row no-gutters" *ngFor="let ' +
        tagName +
        " of " +
        obgName +
        '; let i = index">';
      jQuery.each(db, function (str: string, val) {
        htmlresponse += '<div class="col-2 pl-1" >' + "\n";

        htmlresponse += '<div class="form-group form-group-sm p-1" >' + "\n";
        htmlresponse +=
          '<label class="form-label" >' + str + " </label>" + "\n";
        htmlresponse += "<label>{{" + tagName + "." + str + "}}</label>" + "\n";
        htmlresponse += "</div>" + "\n";

        htmlresponse += "</div>" + "\n";
      });

      htmlresponse += "</div>" + "\n";
      htmlresponse += "</div>\n";
      console.log("GetHtmlCard \n \n" + htmlresponse + "\n \n");
      return "GetHtmlCard \n \n" + htmlresponse + "\n \n";
    } catch (ex) {
      console.log("GetHtmlCard  " + ex);

      return "GetHtmlCard \n \n" + ex + "\n \n";
    }
  }

  GetFormInputs(ModelName, tagid, resp) {
    try {
      let formitems = "\n \n";
      formitems = '<div class="row no-gutters"> \n';
      let rowid = "";
      jQuery.each(resp, function (str: string, cval) {
        if (str == "id") {
          rowid += '<div  class="col-12">{{' + str + "}}</div> \n";
        }
      });

      formitems +=
        '<div  class="col-12"><header  class="py-2 font-weight-bold"><i  class="fa fa-question-circle"></i> ' +
        tagid +
        " " +
        rowid +
        "  Info  </header></div> \n";

      jQuery.each(resp, function (str: string, cval) {
        if (str == "id") {
          formitems += '<div  class="col-12">' + cval + "  </div> \n";
        } else if (Number(cval)) {
          formitems +=
            '<div class="col-xl-6 col-lg-6 col-md-6"> \n' +
            '<div class="form-group" > \n' +
            '<label class="form-label" > ' +
            str +
            " </label> \n" +
            '<div class="input-group input-group-sm" > \n' +
            '<input class="form-control" formControlName="' +
            str +
            '" placeholder="" type="number"  [(ngModel)]="' +
            ModelName +
            "." +
            str +
            '" name="' +
            str +
            '" > \n' +
            '<div class="input-group-append" > \n' +
            '<span class="input-group-text transfer-0 border-left-0" > \n' +
            '<i class="far fa-clipboard-list" > </i> \n' +
            "</span> \n" +
            "</div> \n" +
            "</div> \n" +
            "</div> \n" +
            "</div> \n";
        } else {
          formitems +=
            '<div class="col-xl-6 col-lg-6 col-md-6"> \n' +
            '<div class="form-group" > \n' +
            '<label class="form-label" > ' +
            str +
            " </label> \n" +
            '<div class="input-group input-group-sm" > \n' +
            '<input class="form-control" formControlName="' +
            str +
            '" placeholder="" type="text"  [(ngModel)]="' +
            ModelName +
            "." +
            str +
            '" name="' +
            str +
            '" > \n' +
            '<div class="input-group-append" > \n' +
            '<span class="input-group-text transfer-0 border-left-0" > \n' +
            '<i class="far fa-clipboard-list" > </i> \n' +
            "</span> \n" +
            "</div> \n" +
            "</div> \n" +
            "</div> \n" +
            "</div> \n";
        }
      });
      formitems += "</div> \n";
      formitems += '<div class="row no-gutters"> \n';

      formitems +=
        '<div  class="col-12"><header  class="py-2 font-weight-bold"><i  class="fa fa-question-circle"></i>   Options </header></div>';
      jQuery.each(resp, function (str: string, val) {
        if (val === true || val === false) {
          formitems +=
            '<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 "> \n' +
            '<div class="form-group">  \n' +
            '<div class="custom-control custom-checkbox" > \n' +
            '<input type="checkbox" class="custom-control-input" id="' +
            tagid +
            "-" +
            str +
            '" formControlName="' +
            str +
            '" checked="" > \n' +
            '<label class="custom-control-label" for= "' +
            tagid +
            "-" +
            str +
            '" > ' +
            str +
            " </label>\n" +
            "</div>\n" +
            "</div> \n" +
            "</div>\n";
        }
      });
      formitems += "</div> \n";
      console.log("GetFormInputs \n \n" + formitems + "\n \n");
      return "GetFormInputs \n \n" + formitems + "\n \n";
    } catch (ex) {
      console.log("GetFormInputs" + ex);
      return "GetFormInputs \n \n" + ex + "\n \n";
    }
  }
}

export class Deferred<T> {
  promise: Promise<T>;
  resolve: (value?: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

class Guid {
  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  static Guid() {
    return "00000000-0000-0000-0000-000000000000";
  }
}
