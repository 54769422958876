import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from "src/app/app.service";
@Pipe({
  name: 'formatLabel'
})
export class formatLabelPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return value;
    }

    value = value.replace(/([A-Z])/g, ' $1');
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
