import { AvatarModule } from './components/avatar/avatar.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,FormGroup,FormControl,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UserInfoPipeModule } from 'src/app/pipes/users/user-info.module';
import { VarIdDecriptPipeModule } from 'src/app/pipes/varidlabel/varidlabel.module';
import { FormatLabelPipeModule } from 'src/app/pipes/labels/format-label.module';
import { FormatPhonePipeModule } from 'src/app/pipes/labels/format-phone.module';

import { UtcToLocalPipeModule } from 'src/app/pipes/utcToLocalPipe/UtcToLocalPipe.module';
import { AppService } from "src/app/app.service";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Layout1Component } from './components/layouts/layout1/layout1.component';
import { Layout2Component } from './components/layouts/layout2/layout2.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { MainIframeComponent } from './components/iframe/iframe.component';
import { LogoComponent } from './components/sidebar/logo/logo.component';
import { SearchComponent } from './components/header/search/search.component';
import { UserComponent } from './components/header/user/user.component';
import { TopNavComponent } from './components/header/top-nav/top-nav.component';
import { AdminTopNavComponent } from './components/header/top-nav/admin-top-nav.component';
import { ReferralTopNavComponent } from './components/header/top-nav/referral-top-nav.component';
import { DispatchTopNavComponent } from './components/header/top-nav/dispatch-top-nav.component';
import { lgTopNavComponent } from './components/header/top-nav/lg-top-nav.component';

import { NgbDropdownModule, NgbNavModule, NgbProgressbarModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderToggleComponent } from './components/header/header-toggle/header-toggle.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MomentModule } from 'ngx-moment';


//import { NewClientFormComponent } from 'src/app/shared/forms/newclient/NewClientFormComponent'
 

 
@NgModule({
	declarations: [
		AppComponent,
		Layout1Component,
		Layout2Component,
		SidebarComponent,
		HeaderComponent,
		MainIframeComponent,
		LogoComponent,
		SearchComponent,
		UserComponent,
		TopNavComponent,
		AdminTopNavComponent,
		ReferralTopNavComponent,
		HeaderToggleComponent,
		DispatchTopNavComponent,
		lgTopNavComponent 
	 
		
	],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserAnimationsModule, 
		HttpClientModule,
		AppRoutingModule,
		NgbDropdownModule,
		GoogleMapsModule,
		NgbNavModule,
		NgbProgressbarModule,
  		NgbModule,
		NgScrollbarModule,
		AvatarModule,
		MomentModule,
		UserInfoPipeModule,
		VarIdDecriptPipeModule,
		FormatLabelPipeModule,
		FormatPhonePipeModule,
		UtcToLocalPipeModule
	],
	providers: [
		AppService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
