<div class="top-nav d-flex">
	<!-- Mobile search toggle icon -->
	<div class="top-nav__item d-md-none">
		<i class="zwicon zwicon-search" (click)="service.searchToggled = true"></i>
	</div>
	<!-- End mobile search toggle icon -->

	<!-- Notifications -->


	<!-- <div class="d-flex date-range-picker date-picker" style="margin-top:5px;">

		<div class="align-self-starts w-100 d-flex align-items-center">
			


		</div>
		 <div class="ml-auto">
			<i class="zwicon zwicon-chevron-down date-arrow" style="font-size: 4vh;"></i>
		</div>  

	</div> -->


	<!-- <div class="top-nav__item  ">
		{{this.viewStartDate}}
	</div>
	<div class="top-nav__item  ">
		{{ this.service.ServerDataDto.fromDate }} </div> -->


	<!-- 
	<div class="top-nav__item  ">
		  <span id="daterangeTop" style="background-color: transparent;border-color:transparent !important;  
			border-width: 0px !important; border: none !important; cursor: pointer;" readonly style="margin-top:5px;" ><i class="zwicon zwicon-calendar" ></i></span> 
 

	</div> -->



 
	<div class="top-nav__item align-items-center mr-2" (click)="LockScreen()" >
		<i class="zwicon zwicon-lock"></i>
		<small class="icon_text">Lock</small>
	</div>


	<div class="top-nav__item align-items-center" >
		<i id="daterangeTop" class="zwicon zwicon-calendar"></i>

		<small class="icon_text">{{service.ServerDataDto.fromDate | date : "MM/dd/yy"}}</small>
	</div>



	<!-- -->
	<div class="top-nav__item align-items-center" (click)="LoadDailySchedule(1)" *ngIf="this.userAccesses.Appointments == true && this.service.IsDevelopment == true" >
		<i class="zwicon zwicon-cell-full"></i>
		<small class="icon_text">Appts</small>
	</div>

	<div class="top-nav__item align-items-center" (click)="LoadDailySchedule(0)" *ngIf="this.userAccesses.Leads == true && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-cell-empty"></i>
		<small class="icon_text">Leads</small>
	</div>


	<div class="top-nav__item align-items-center"   *ngIf="this.userAccesses.Schedule && this.service.IsDevelopment == true"
		(click)="SelectMenue('/dashboards/schedule')">
		<i class="zwicon zwicon-three-h"></i>
		<small class="icon_text">Schedulexc2</small>
	</div>

	<!-- *ngIf="this.userAccesses.Clients == true" -->
	<div class="top-nav__item align-items-center" (click)="SelectMenue('/dashboards/clients')" *ngIf="this.userAccesses.Clients == true" >
		<i class="zwicon zwicon-cardboard-vr"></i>
		<small class="icon_text">Clients</small>
	</div>


	<div class="top-nav__item align-items-center" (click)="SelectMenue('/dashboards/area')" *ngIf="this.userAccesses.Area == true && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-grid"></i>
		<small class="icon_text">Area</small>
	</div>
	<div class="top-nav__item align-items-center" (click)="SelectMenue('/app-views/team')" *ngIf="this.userAccesses.Team == true && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-users"></i>
		<small class="icon_text">Team</small>
	</div>
	<!-- zwicon-task --> 		<!-- (click)="LoadDailySchedule(2) -->

 
	<div class="top-nav__item align-items-center" (click)="SelectMenue('//app-views/datalists')" *ngIf="this.userAccesses.Telemarketing == true && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-call-out"></i> 
		<small class="icon_text">Telemarketing</small>
	</div>


	<div class="top-nav__item align-items-center" (click)="SelectMenue('/app-views/lgCenter')" *ngIf="this.userAccesses.LGCenter && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-earth-alt"></i>
		<small class="icon_text">LGCenter</small>
	</div>


	<div class="top-nav__item align-items-center" (click)="SelectMenue('/app-views/contacts')" *ngIf="this.userAccesses.Contacts && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-persona"></i>
		<small class="icon_text">Contacts</small>
	</div>


	<div class="top-nav__item align-items-center" (click)="SelectMenue('/app-views/smsCenter')" *ngIf="this.userAccesses.Sms && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-comment"></i>
		<small class="icon_text">SMS</small>
	</div>

 

	<!-- Calls ccccccccccc -->
	<div class="top-nav__item d-none d-sm-block" ngbDropdown *ngIf="this.userAccesses.Calls && this.service.IsDevelopment == true" >
 
			<i ngbDropdownToggle  class="zwicon zwicon-phone" title="outbound calls reports"></i>
			<small ngbDropdownToggle class="icon_text">Calls</small>
 

		<div ngbDropdownMenu >
			<a ngbDropdownItem routerLink="./" *ngFor="let item of this.service.TwmlPages.list" (click)="SelectMenue(item.url)">
			 
				<i [ngClass]="['zwicon',  item.icon]"></i> <span> {{ item.page }} </span>
		
			</a>
		</div>
	</div>
	<!-- End Calls -->


	<!-- <div class="top-nav__item align-items-center" (click)="SelectMenue('/admin/twilio/callCenter')">

		<small class="icon_text">Calls</small>
	</div> -->

	<!-- <div class="top-nav__item align-items-center" (click)="SelectMenue('/admin/setting/twilio/calls')">
		<i class="zwicon zwicon-headphone" title="outbound calls reports"></i>
		<small class="icon_text">Calls1</small>
	</div> -->


	<!-- <div class="top-nav__item align-items-center" (click)="SelectMenue('/admin/setting/twilio/recording')">
		<i class="zwicon zwicon-play-alt" title="calls recordings"></i>
		<small class="icon_text">Recordings</small>


	</div> -->

	<!-- <div class="top-nav__item align-items-center" (click)="SelectMenue('/admin/setting/twilio/numbers')">
		<i class="zwicon zwicon-phone" title="outbound DID use reportd"></i>
		<small class="icon_text">DID</small>

	</div> -->


	<div class="top-nav__item align-items-center" (click)="SelectMenue('/admin/center')" *ngIf="this.userAccesses.Admin">
		<i class="zwicon zwicon-cog"></i>
		<small class="icon_text">Admin</small>
	</div>

	<!-- && this.service.IsDevelopment == true -->
	<div class="top-nav__item  align-items-center" [class.top-nav__notify]="alertNotify" ngbDropdown *ngIf="this.userAccesses.Notify && this.service.IsDevelopment == true " >
		<i class="zwicon"  [class.bell]="alertNotify"  [class.zwicon-chat]="!alertNotify"   ngbDropdownToggle></i>
		<small class="icon_text" ngbDropdownToggle>Chat</small>
		<div ngbDropdownMenu>
			<div class="bg-gradient-plum rounded-1 p-3 text-white mb-1">
				<div class="mb-2.5 d-flex align-items-center">
					<div class="fw-bold text-center flex-grow-1 ps-9">Notifications</div>

					<div ngbDropdown placement="bottom-right">
						<button type="button" ngbDropdownToggle
							class="icon bg-white-10-hover zwicon zwicon-more-v text-white">
							<span class="visually-hidden">More</span>
						</button>
						<div ngbDropdownMenu>
							<a routerLink="./" ngbDropdownItem>
								<i class="zwicon zwicon-window"></i>
								Open notifications
							</a>
							<a routerLink="./" ngbDropdownItem>
								<i class="zwicon zwicon-checkmark-circle"></i>
								Mark all as read
							</a>
							<a routerLink="./" ngbDropdownItem>
								<i class="zwicon zwicon-cog"></i>
								Notifications settings
							</a>
						</div>
					</div>
				</div>

				<div ngbNav #notifications="ngbNav" class="top-nav__tab">
					<ng-container ngbNavItem>
						<a ngbNavLink class="fw-bold">Telemarketing<i></i></a>

						<ng-template ngbNavContent>
							<a *ngFor="let list of orderNotifications" (click)="SelectMenue('/application/messages')"
								class="d-flex align-items-center p-2.5 rounded bg-100-hover">
								<app-avatar class="me-3" [type]="'icon'" [color]="list.color" [glyph]="list.icon"
									[glyphTheme]="'dark'"></app-avatar>

								<div class="flex-grow-1 overflow-hidden">
									<div class="text-headings mb-0.5 text-truncate"
										[class.fw-bold]="list.status == 'unread'">
										{{ list.id + " " + list.title }}
									</div>
									<div class="fs-7 text-muted">{{ list.time }}</div>
								</div>
							</a>
						</ng-template>
					</ng-container>

					<ng-container ngbNavItem>
						<a ngbNavLink class="fw-bold">Dispatch<i></i></a>
						<ng-template ngbNavContent>
							<a *ngFor="let list of supportNotifications"  (click)="SelectMenue('/application/messages')"
								class="d-flex align-items-center p-2.5 rounded bg-100-hover">
								<app-avatar class="me-3" [type]="'icon'" [color]="list.color" [glyph]="list.icon"
									[glyphTheme]="'dark'"></app-avatar>
								<div class="flex-grow-1 overflow-hidden">
									<div class="text-headings mb-0.5 text-truncate"
										[class.fw-bold]="list.status == 'unread'">
										{{ list.id + " " + list.title }}
									</div>
									<div class="fs-7 text-muted">{{ list.time }}</div>
								</div>
							</a>
						</ng-template>
					</ng-container>

					<ng-container ngbNavItem>
						<a ngbNavLink class="fw-bold">Others</a>
						<ng-template ngbNavContent>
							<div class="py-8 text-center">
								<div class="d-grid place-items-center">
									<img src="/assets/img/misc/info-bg.svg" alt="" />
									<i class="zwicon zwicon-bell-slash position-absolute display-4 text-headings"></i>
								</div>

								<div class="mt-3 text-muted fs-7">All caught up!</div>
							</div>
						</ng-template>
					</ng-container>
				</div>
			</div>

			<ng-scrollbar visibility="hover">
				<div [ngbNavOutlet]="notifications"></div>
			</ng-scrollbar>
		</div>
	</div>
	<!-- End notifications -->







 



	


	<!-- Mails -->
	 <div class="top-nav__item align-items-center" ngbDropdown *ngIf="this.userAccesses.Email && this.service.IsDevelopment == true">
		<i class="zwicon zwicon-mail" ngbDropdownToggle></i>
		<small class="icon_text" ngbDropdownToggle>Email</small>
		<div ngbDropdownMenu>
			<div class="bg-gradient-green rounded-1 p-3 text-white mb-1">
				<div class="mb-2.5 d-flex align-items-center">
					<div class="fw-bold text-center flex-grow-1 ps-9">Mails</div>

					<div ngbDropdown placement="bottom-right">
						<button type="button" ngbDropdownToggle
							class="icon bg-white-10-hover zwicon zwicon-more-v text-white">
							<span class="visually-hidden">More</span>
						</button>
						<div ngbDropdownMenu>
							<a routerLink="./" ngbDropdownItem>
								<i class="zwicon zwicon-window"></i>
								Open mails
							</a>
							<a routerLink="./" ngbDropdownItem>
								<i class="zwicon zwicon-checkmark-circle"></i>
								Mark all as read
							</a>
							<a routerLink="./" ngbDropdownItem>
								<i class="zwicon zwicon-cog"></i>
								Mails settings
							</a>
						</div>
					</div>
				</div>

				<nav ngbNav #mail="ngbNav" class="top-nav__tab">
					<ng-container ngbNavItem>
						<a ngbNavLink class="fw-bold">Everything</a>

						<ng-template ngbNavContent>
							<a *ngFor="let mail of mails; let i = index" routerLink="./"
								class="d-flex align-items-center p-2.5 rounded bg-100-hover">
								<app-avatar class="me-3" [id]="['mail-' + i]" [color]="mail.color" [cap]="mail.cap"
									[type]="mail.image ? 'img' : 'cap'"
									[img]="['./assets/demo/img/contacts/' + mail.image]"></app-avatar>

								<div class="flex-grow-1 overflow-hidden">
									<div class="text-headings mb-0.5 text-truncate"
										[class.fw-bold]="mail.status == 'unread'">
										{{ mail.user }}
										<span class="fs-8 text-muted ms-2 fw-normal">{{ mail.time }}</span>
									</div>
									<div class="fs-7 text-muted text-truncate">{{ mail.content }}</div>
								</div>
							</a>
						</ng-template>
					</ng-container>

					<ng-container ngbNavItem>
						<a ngbNavLink class="fw-bold">Unread</a>

						<ng-template ngbNavContent>
							<ng-container *ngFor="let mail of mails; let i = index">
								<a *ngIf="mail.status == 'unread'" routerLink="./"
									class="d-flex align-items-center p-2.5 rounded-2 bg-100-hover">
									<app-avatar class="me-3" [id]="['mail-unread-' + i]" [color]="mail.color"
										[cap]="mail.cap" [type]="mail.image ? 'img' : 'cap'"
										[img]="['./assets/demo/img/contacts/' + mail.image]"></app-avatar>

									<div class="flex-grow-1 overflow-hidden">
										<div class="text-headings mb-0.5 text-truncate"
											[class.fw-bold]="mail.status == 'unread'">
											{{ mail.user }}
											<span class="fs-8 text-muted ms-2 fw-normal">{{ mail.time }}</span>
										</div>
										<div class="fs-7 text-muted text-truncate">{{ mail.content }}</div>
									</div>
								</a>
							</ng-container>
						</ng-template>
					</ng-container>
				</nav>
			</div>

			<ng-scrollbar visibility="hover">
				<div [ngbNavOutlet]="mail"></div>
			</ng-scrollbar>
		</div>
	</div>  
	<!-- End mails -->

	<!-- Tasks *ngIf="this.service.IsDevelopment == true" -->
	<div class="top-nav__item d-none d-sm-block align-items-center" ngbDropdown (click)="setDatalistSurce(0)"  *ngIf="  this.service.IsDevelopment == true">
		<i class="zwicon zwicon-headphone" ngbDropdownToggle></i>
		<small class="icon_text" ngbDropdownToggle>TwML</small>
		<div ngbDropdownMenu>
			<div class="bg-gradient-cyan rounded-1 p-2 text-white d-flex align-items-center mb-1">
		
					<!-- Data Lists -->

					<!-- <div class="top-nav__item align-items-center" >
						<i class="zwicon zwicon-call-out"></i> 
						<small class="icon_text">Telemarketing</small>
					</div> -->
				

					<!-- <a class="btn btn-default" (click)="setDatalistSurce(2)" [class.active]="selecteddl==2">
						Custom
					</a>
					<a class="btn btn-default" (click)="setDatalistSurce(1)" [class.active]="selecteddl==1">
						Marketing
					</a> -->
					<!-- <div class="text-center flex-grow-1 ps-9">
					<a class="btn btn-default" (click)="setDatalistSurce(0)">
						<i class="zwicon fs-5 zwicon-refresh-double"></i> Refresh
					</a>
				</div> -->

				<!-- <div ngbDropdown placement="bottom-right">
					<button type="button" ngbDropdownToggle
						class="icon bg-white-10-hover zwicon zwicon-more-v text-white">
						<span class="visually-hidden">More</span>
					</button>
					<div ngbDropdownMenu>
						<a  ngbDropdownItem>
							<i class="zwicon zwicon-window"></i>
							Open tasks
						</a>
						<a   ngbDropdownItem>
							<i class="zwicon zwicon-checkmark-circle"></i>
							 Completed tasks
						</a>
						<a   ngbDropdownItem>
							<i class="zwicon zwicon-cog"></i>
							My Tasks
						</a>
					</div>
				</div> -->
			</div>
			<ng-scrollbar visibility="hover">
				<!-- <a   *ngFor="let task of customDatalists"
					class="d-flex align-items-center p-2.5 rounded-2 bg-100-hover">
					<div class="flex-grow-1">
						<div class="text-headings fw-bold mb-2">{{ task.ListName }}</div>
						<ngb-progressbar [type]="task.color" [value]="task.soldCalls"></ngb-progressbar>
					</div>
				</a> -->
 
				<div class="d-flex align-items-center p-1 rounded-2 bg-100-hover" *ngFor="let task of filterDatalists">
					<div class="flex-grow-1" (click)="LoadTwmlDail(task)">
						<div class="d-flex">
							<div title="" class="flex-grow-1 ms-1 fs-5 fw-bold mb-2">
								{{ task.ListName }} </div>
							<div title=" " class="ms-1 fs-5 fw-bold mb-2"><i class="zwicon fs-5 zwicon-phone"></i>
								{{ task.totalLeads }}</div>
							<div title=" " class="ms-1 fs-5 fw-bold mb-2"><i class="fs-5 zwicon zwicon-history"></i>
								{{ task.totalCalls }}</div>
							<div title=" " class="ms-1 text-end mb-2 d-none d-sm-inline w-11 text-green"><i
									class="fs-5 zwicon zwicon-checkmark-circle"></i> {{ task.soldCalls }} </div>
						</div>
						<ngb-progressbar [type]="task.color" [value]="task.soldCalls"></ngb-progressbar>
					</div>
				</div>

			</ng-scrollbar>



		</div>
	</div>
	<!-- End tasks -->


	<!-- Language -->
	<div class="top-nav__item d-none d-sm-block" ngbDropdown *ngIf="this.service.IsDevelopment == true">
		<i ngbDropdownToggle>
			<img class="w-6 rounded-1" [src]="['./assets/demo/img/languages/' + languages.current + '.png']"
				alt="English" />
		</i>

		<div ngbDropdownMenu>
			<a ngbDropdownItem routerLink="./" *ngFor="let item of languages.list">
				<img class="w-6 me-2 rounded-1" [src]="['./assets/img/flags/' + item.flag + '.png']" alt="" />
				{{ item.language }}
			</a>
		</div>
	</div>
	<!-- End language -->
</div>