import { Component, OnInit } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { AppService } from 'src/app/app.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("toggleSubMenu", [
      state(
        "inactive",
        style({
          height: "0",
          opacity: "0",
          marginBottom: "0",
        })
      ),
      state(
        "inactive",
        style({
          height: "*",
          opacity: "1",
          marginBottom: "1rem",
        })
      ),
      transition("inactive => active", animate("200ms")),
      transition("active => inactive", animate("200ms")),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  menuLinks: any[] = [
    {
      group: "Dashboards",
      links: [
        {
          title: "Welcome",
          icon: "earth-alt",
          route: "/dashboards/area",
        }, 
        {
          title: "Teams",
          icon: "team",
          route: "/app-views/team",
        },
        {
          title: "Telemarketing",
          icon: "phone-plus",
          route: "/app-views/datalists",
        },
        {
          title: "lg Center",
          icon: "link-simple",
          route: "/app-views/lgCenter",
        },
        {
          title: "Data Lists",
          icon: "headphone",
          route: "/dashboards/datalists",
        },
        {
          title: "Messages",
          icon: "chat-circle-dots",
          route: "/application/messages",
        },

        {
          title: "SMS-Center",
          icon: "cardholder",
          route: "/app-views/smsCenter",
        },
        {
          title: "Contacts",
          icon: "cardholder",
          route: "/app-views/contacts",
        },
        {
          title: "Prospects",
          icon: "grid",
          route: "/dashboards/projects",
          sub: [
            {
              title: "Created",
              icon: "grid",
              route: "/dashboards/projects/grid",
            } 
          ],
        },
      ],
    },
  ];

  orgmenuLinks: any[] = [
    {
      group: "Dashboards",
      links: [
        {
          title: "Welcome to QDispatch",
          icon: "earth-alt",
          route: "/dashboards/website-analytics",
        },
        {
          title: "E-Commerce",
          icon: "sale-badge",
          route: "/dashboards/e-commerce",
        },
      ],
    },
    {
      group: "Application Views",
      links: [
        {
          title: "User",
          icon: "user",
          sub: [
            {
              title: "Profile",
              route: "/application-views/user/profile",
            },
            {
              title: "Timeline",
              route: "/application-views/user/timeline",
            },
            {
              title: "Photos",
              route: "/application-views/user/photos",
            },
            {
              title: "Connections",
              route: "/application-views/user/connections",
            },
            {
              title: "Preferences",
              route: "/application-views/user/preferences",
            },
          ],
          visibility: "inactive",
        },
        {
          title: "File Manager",
          icon: "folder-open",
          route: "/application-views/files",
        },
        {
          title: "Contacts",
          icon: "user-circle",
          route: "/application-views/contacts",
        },
        {
          title: "Mail",
          icon: "mail",
          route: "/application-views/mail",
        },
        {
          title: "Messages",
          icon: "comment",
          route: "/application-views/messages",
        },
        {
          title: "Photos",
          icon: "image-circle",
          route: "/application-views/photos",
        },
        {
          title: "Todo Lists",
          icon: "checkmark-circle",
          route: "/application-views/todo",
        },
        {
          title: "Calendar",
          icon: "calendar",
          route: "/misc/sample-pages/coming-soon",
        },
      ],
    },
    {
      group: "Miscellaneous",
      links: [
        {
          title: "Sample Pages",
          icon: "content-right",
          visibility: "inactive",
          sub: [
            {
              title: "Invoice",
              route: "/misc/sample-pages/invoice",
            },
            {
              title: "Pricing Table",
              route: "/misc/sample-pages/pricing-table",
            },
            {
              title: "Search Results",
              route: "/misc/sample-pages/search-results",
            },
            {
              title: "Issue Tracker",
              route: "/misc/sample-pages/issue-tracker",
            },
            {
              title: "FAQ",
              route: "/misc/sample-pages/faq",
            },
            {
              title: "Team",
              route: "/misc/sample-pages/team",
            },
            {
              title: "Login",
              route: "/misc/sample-pages/login",
            },
            {
              title: "Register",
              route: "/misc/sample-pages/register",
            },
            {
              title: "Forgot Password",
              route: "/misc/sample-pages/forgot-password",
            },
            {
              title: "Coming Soon",
              route: "/misc/sample-pages/coming-soon",
            },
            {
              title: "Error",
              route: "/misc/sample-pages/error",
            },
          ],
        },
        {
          title: "Contents",
          icon: "font",
          visibility: "inactive",
          sub: [
            {
              title: "Typography",
              route: "/misc/content/typography",
            },
            {
              title: "Code",
              route: "/misc/content/code",
            },
            {
              title: "Images",
              route: "/misc/content/images",
            },
            {
              title: "Table",
              route: "/misc/content/tables",
            },
          ],
        },
        {
          title: "Icons",
          icon: "smile",
          route: "/misc/icons",
        },
        {
          title: "Angular Components",
          icon: "layout-4",
          visibility: "inactive",
          sub: [
            {
              title: "Accordion",
              route: "/misc/components/accordion",
            },
            {
              title: "Alert",
              route: "/misc/components/alert",
            },
            {
              title: "Carosuel",
              route: "/misc/components/carousel",
            },
            {
              title: "Collapse",
              route: "/misc/components/collapse",
            },
            {
              title: "Datepicker",
              route: "/misc/components/datepicker",
            },
            {
              title: "Dropdown",
              route: "/misc/components/dropdown",
            },
            {
              title: "Modal",
              route: "/misc/components/modal",
            },
            {
              title: "Nav Tabs",
              route: "/misc/components/tab",
            },
            {
              title: "Pagination",
              route: "/misc/components/pagination",
            },
            {
              title: "Popover",
              route: "/misc/components/popover",
            },
            {
              title: "Progressbar",
              route: "/misc/components/progressbar",
            },
            {
              title: "Ratings",
              route: "/misc/components/ratings",
            },
            {
              title: "Timepicker",
              route: "/misc/components/timepicker",
            },
            {
              title: "Toast",
              route: "/misc/components/toast",
            },
            {
              title: "Tooltips",
              route: "/misc/components/tooltips",
            },
            {
              title: "Typeahead",
              route: "/misc/components/typeahead",
            },
          ],
        },
        {
          title: "Static Components",
          icon: "grid",
          visibility: "inactive",
          sub: [
            {
              title: "Badge",
              route: "/misc/components/badge",
            },
            {
              title: "Breadcrumbs",
              route: "/misc/components/breadcrumbs",
            },
            {
              title: "Buttons",
              route: "/misc/components/static-buttons",
            },
            {
              title: "Button Groups",
              route: "/misc/components/button-groups",
            },
            {
              title: "Cards",
              route: "/misc/components/cards",
            },
            {
              title: "List Group",
              route: "/misc/components/list-group",
            },
            {
              title: "Navs",
              route: "/misc/components/navs",
            },
            {
              title: "Spinners",
              route: "/misc/components/spinners",
            },
          ],
        },
        {
          title: "Forms",
          icon: "tray",
          visibility: "inactive",
          sub: [
            {
              title: "Input Groups",
              route: "/misc/forms/input-groups",
            },
            {
              title: "Basic Form Elements",
              route: "/misc/forms/form-elements",
            },
            {
              title: "Form Components",
              route: "/misc/forms/form-components",
            },
            {
              title: "Form Layouts",
              route: "/misc/forms/form-layouts",
            },
            {
              title: "Form Validation",
              route: "/misc/forms/form-validation",
            },
          ],
        },
        {
          title: "Charts and Maps",
          icon: "line-chart",
          visibility: "inactive",
          sub: [
            {
              title: "Charts",
              route: "/misc/charts-maps/charts",
            },
            {
              title: "Maps",
              route: "/misc/charts-maps/maps",
            },
          ],
        },
        {
          title: "Background Colors",
          icon: "palette",
          route: "/misc/colors",
        },
      ],
    },
  ];

  // Toggle sub menu
  toggleSubMenu(i, x) {
    this.menuLinks[i].links[x].visibility =
      this.menuLinks[i].links[x].visibility === "inactive"
        ? "active"
        : "inactive";
  }
  constructor(public service: AppService) {
    this.GetJiralabels();
  }

  ngOnInit() {}

  GetJiralabels() {}
}
