<svg
	[attr.width]="size"
	[attr.height]="size"
	viewBox="0 0 35 35"
	(click)="onclick.emit()"
	[ngClass]="['text-' + color]"
>
	<ng-container *ngIf="type === 'cap'">
		<path d="M17.5 0C4 0 0 4 0 17.5C0 31 4 35 17.5 35C31 35 35 31 35 17.5C35 4 31 0 17.5 0Z" fill="currentColor" />
		<text x="50%" y="53%" font-weight="500" fill="#ffffff" dominant-baseline="middle" text-anchor="middle" style="text-transform: uppercase;">
			{{ cap }}
		</text>
	</ng-container>
	<ng-container *ngIf="type === 'img'">
		<path
			d="M17.5 0C4 0 0 4 0 17.5C0 31 4 35 17.5 35C31 35 35 31 35 17.5C35 4 31 0 17.5 0Z"
			[attr.fill]="['url(#' + id + ')']"
		/>
		<defs>
			<pattern [id]="id" patternContentUnits="objectBoundingBox" width="1" height="1">
				<image width="1" height="1" [attr.href]="img" />
			</pattern>
		</defs>
	</ng-container>
	<ng-container *ngIf="type === 'icon'">
		<path d="M17.5 0C4 0 0 4 0 17.5C0 31 4 35 17.5 35C31 35 35 31 35 17.5C35 4 31 0 17.5 0Z" [attr.fill]="glyphTheme === 'dark' ? 'currentColor' : '#e0f0fb'" />
		<text x="50%" y="53%" font-size="1.2rem" font-family="zwicon" [attr.fill]="glyphTheme === 'dark' ? '#ffffff' : '#205377'" dominant-baseline="middle" text-anchor="middle" [innerHTML]="glyph"></text>
	</ng-container>
</svg>
<i
	*ngIf="online"
	class="w-3 h-3 rounded-circle position-absolute end-0 bottom-0 me-n1 mb-n1 border border-white bg-green"
></i>
