import { Injectable } from "@angular/core";
import { AppService } from "../../../app.service";
import { HttpClient } from "@angular/common/http";
import { sceduleprojects } from "src/app/models/sceduleprojects";
@Injectable({
  providedIn: "root",
})
export class ReferalsApiService {
  constructor(public service: AppService, public http: HttpClient) {}


  GetReferalls() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Data/Referals/GetReferalls"
    );
  }

 
  GetUsersReferalls() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Data/Referals/GetUsersReferalls"
    );
  }


}
