import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Layout1Component } from "./components/layouts/layout1/layout1.component";
import { Layout2Component } from "./components/layouts/layout2/layout2.component";
import { AppService } from "src/app/app.service";



const routes: Routes = [
	{
		path: "",
		component: Layout1Component,
		
		children:   [
			{
				path: "",
				redirectTo: "dashboards/area",
				pathMatch: "full"
			},
			// {
			// 	path: "dashboards/welcome",
			// 	loadChildren: () =>
			// 		import("./pages/dashboards/Welcome/welcome.component").then(
			// 			(m) => m.WelcomeComponent
			// 		)
			// }, 
			{
				path: "dashboards/datalists",
				loadChildren: () =>
					import("./pages/dashboards/data-lists/datalists.module").then(
						(m) => m.DataListsModule
					)
			},  
			{
				path: "dashboards/area",
				loadChildren: () =>
					import("./pages/dashboards/area/area.module").then(
						(m) => m.AreaModule
					)
			}, 
			{
				path: "dashboards/clients",
				loadChildren: () =>
					import("./pages/dashboards/clients/clients.module").then(
						(m) => m.ClientsCenterModule
					)
			},  
			{
				path: "dashboards/projects",
				loadChildren: () =>
					import("./pages/dashboards/projects/projects.module").then(
						(m) => m.ProjectsModule
					)
			},
			{
				path: "dashboards/schedule",
				loadChildren: () =>
					import("./pages/schedule/calendar.component.module").then(
						(m) => m.CalendarModule
					)
			},
			{
				path: "misc/lead/map",
				loadChildren: () => import("./pages/misc/components/leads/map/map.module").then((m) => m.MapModule)
			}, 
			// {
			// 	path: "misc/lead/dashboard",
			// 	loadChildren: () => import("./pages/misc/components/leads/dashboard/lead-dashboard.module").then((m) => m.LeadDashboardModule)
			// }, 
			{
				path: "admin/center",
				loadChildren: () => import("./pages/admin/admin.module").then((m) => m.AdminCenterModule)
			}, 	
			{
				path: "reports/center",
				loadChildren: () => import("./pages/reports/reports.module").then((m) => m.ReportsCenterModule)
			}, 
			{
				path: "datalists/center",
				loadChildren: () => import("./pages/datalists/data-lists.module").then((m) => m.DataListsCenterModule)
			}, 
			{
				path: "admin/setting/users",
				loadChildren: () => import("./pages/admin/users/users-center.module").then((m) => m.UsersCenterModule)
			}, 
			{
				path: "admin/setting/twilio",
				loadChildren: () => import("./pages/admin/twilio/twilio-center.module").then((m) => m.TwilioCenterModule)
			},
			{
				path: "admin/twilio/callCenter",
				loadChildren: () => import("./pages/admin/twilio/widgets/calls/calls-center.module").then((m) => m.CallCenterModule)
			},
			{
				path: "app-views/user/profile",
				loadChildren: () => import("./pages/app-views/user/profile/profile.module").then((m) => m.ProfileModule)
			},
			{
				path: "app-views/contacts",
				loadChildren: () => import("./pages/app-views/contacts/contacts.module").then((m) => m.ContactsModule),
				data: {
					layout: "full"
				}
			},
			{
				path: "app-views/team",
				loadChildren: () => import("./pages/sample-pages/team/team.module").then((m) => m.TeamModule)
			}, 
			{
				path: "app-views/lgCenter",
				loadChildren: () =>
					import("./pages/lgcenter/lg-center.module").then((m) => m.LGCenterTrackerModule)
			},
			{
				path: "app-views/datalists",
				loadChildren: () =>
					import("./pages/telemarketing/telemarketing-center.module").then((m) => m.LGCenterTrackerModule)
			}, 
			 {
			 	path: "app-views/mailCenter",
				  	loadChildren: () => import("./pages/app-views/mail/mail.module").then((m) => m.MailModule),
				  	data: {
				 		layout: "full"
				  	}
				}
				,
			 {
				path: "app-views/smsCenter",
				  	loadChildren: () => import("./pages/app-views/sms/sms.module").then((m) => m.SMSModule),
				  	data: {
				 		layout: "full"
				  	}
				}
				,
			 {
				path: "admin/setting/urac",
				  	loadChildren: () => import("./pages/admin/users-role-access/urac.module").then((m) => m.URACModule),
				  	data: {
				 		layout: "full"
				  	}
				},
                {
				path: "application/messages",
				loadChildren: () => import("./pages/app-views/messages/messages.module").then((m) => m.MessagesModule)
			    },



			// {
			// 	path: "app-views/lgCenter",
			// 	loadChildren: () =>
			// 		import("./pages/sample-pages/issue-tracker/issue-tracker.module").then((m) => m.IssueTrackerModule)
			// }
			// ,  
			// {
			// 	path: "dashboards/calendar",
			// 	loadChildren: () => import("./pages/misc/components/full-calendar/calendar/calendar.component.module").then((m) => m.CalendarModule)
			// }
			// {
			// 	path: "dashboards/area/edit",
			// 	loadChildren: () => import("./pages/dashboards/Welcome/welcome.module").then((m) => m.WelcomeModule)
			// },
			// {
			// 	path: "dashboards/e-commerce",
			// 	loadChildren: () =>
			// 		import("./pages/dashboards/e-commerce/e-commerce.module").then((m) => m.ECommerceModule)
			// },
			// {
			// 	path: "application-views/user/profile",
			// 	loadChildren: () => import("./pages/app-views/user/profile/profile.module").then((m) => m.ProfileModule)
			// },
			// {
			// 	path: "application-views/user/timeline",
			// 	loadChildren: () =>
			// 		import("./pages/app-views/user/timeline/timeline.module").then((m) => m.TimelineModule)
			// },
			// {
			// 	path: "application-views/user/preferences",
			// 	loadChildren: () =>
			// 		import("./pages/app-views/user/preferences/preferences.module").then((m) => m.PreferencesModule)
			// },
			// {
			// 	path: "application-views/user/photos",
			// 	loadChildren: () =>
			// 		import("./pages/app-views/user/photos/photos.module").then((m) => m.UserPhotosModule)
			// },
			// {
			// 	path: "application-views/user/connections",
			// 	loadChildren: () =>
			// 		import("./pages/app-views/user/connections/connections.module").then((m) => m.UserConnectionsModule)
			// },
			// {
			// 	path: "application-views/files",
			// 	loadChildren: () => import("./pages/app-views/files/files.module").then((m) => m.FilesModule)
			// },
			// {
			// 	path: "application-views/contacts",
			// 	loadChildren: () => import("./pages/app-views/contacts/contacts.module").then((m) => m.ContactsModule),
			// 	data: {
			// 		layout: "full"
			// 	}
			// },
			// {
			// 	path: "application-views/messages",
			// 	loadChildren: () => import("./pages/app-views/messages/messages.module").then((m) => m.MessagesModule)
			// },
			// {
			// 	path: "application-views/mail",
			// 	loadChildren: () => import("./pages/app-views/mail/mail.module").then((m) => m.MailModule),
			// 	data: {
			// 		layout: "full"
			// 	}
			// },
			// {
			// 	path: "application-views/photos",
			// 	loadChildren: () => import("./pages/app-views/photos/photos.module").then((m) => m.PhotosModule)
			// },
			// {
			// 	path: "application-views/todo",
			// 	loadChildren: () => import("./pages/app-views/todo/todo.module").then((m) => m.TodoModule)
			// },
			// {
			// 	path: "misc/sample-pages/invoice",
			// 	loadChildren: () => import("./pages/sample-pages/invoice/invoice.module").then((m) => m.InvoiceModule)
			// },
			// {
			// 	path: "misc/sample-pages/pricing-table",
			// 	loadChildren: () =>
			// 		import("./pages/sample-pages/pricing-table/pricing-table.module").then((m) => m.PricingTableModule)
			// },
			// {
			// 	path: "misc/sample-pages/search-results",
			// 	loadChildren: () =>
			// 		import("./pages/sample-pages/search-results/search-results.module").then(
			// 			(m) => m.SearchResultsModule
			// 		)
			// },
			// {
			// 	path: "misc/sample-pages/issue-tracker",
			// 	loadChildren: () =>
			// 		import("./pages/sample-pages/issue-tracker/issue-tracker.module").then((m) => m.IssueTrackerModule)
			// },
			// {
			// 	path: "misc/sample-pages/faq",
			// 	loadChildren: () => import("./pages/sample-pages/faq/faq.module").then((m) => m.FaqModule)
			// },
			// {
			// 	path: "misc/sample-pages/team",
			// 	loadChildren: () => import("./pages/sample-pages/team/team.module").then((m) => m.TeamModule)
			// },
			// {
			// 	path: "misc/content/typography",
			// 	loadChildren: () => import("./pages/misc/typography/typography.module").then((m) => m.TypographyModule)
			// },
			// {
			// 	path: "misc/content/code",
			// 	loadChildren: () => import("./pages/misc/code/code.module").then((m) => m.CodeModule)
			// },
			// {
			// 	path: "misc/content/images",
			// 	loadChildren: () => import("./pages/misc/images/images.module").then((m) => m.ImagesModule)
			// },
			// {
			// 	path: "misc/content/tables",
			// 	loadChildren: () => import("./pages/misc/tables/tables.module").then((m) => m.TablesModule)
			// },
			// {
			// 	path: "misc/icons",
			// 	loadChildren: () => import("./pages/misc/icons/icons.module").then((m) => m.IconsModule)
			// },
			// {
			// 	path: "misc/components/accordion",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/accordion/accordion.module").then((m) => m.AccordionModule)
			// },
			// {
			// 	path: "misc/components/alert",
			// 	loadChildren: () => import("./pages/misc/components/alert/alert.module").then((m) => m.AlertModule)
			// },
			// {
			// 	path: "misc/components/carousel",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/carousel/carousel.module").then((m) => m.CarouselModule)
			// },
			// {
			// 	path: "misc/components/collapse",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/collapse/collapse.module").then((m) => m.CollapseModule)
			// },
			// {
			// 	path: "misc/components/datepicker",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/datepicker/datepicker.module").then((m) => m.DatepickerModule)
			// },
			// {
			// 	path: "misc/components/dropdown",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/dropdown/dropdown.module").then((m) => m.DropdownModule)
			// },
			// {
			// 	path: "misc/components/modal",
			// 	loadChildren: () => import("./pages/misc/components/modal/modal.module").then((m) => m.ModalModule)
			// },
			// {
			// 	path: "misc/components/tab",
			// 	loadChildren: () => import("./pages/misc/components/tab/tab.module").then((m) => m.TabModule)
			// },
			// {
			// 	path: "misc/components/pagination",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/pagination/pagination.module").then((m) => m.PaginationModule)
			// },
			// {
			// 	path: "misc/components/popover",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/popover/popover.module").then((m) => m.PopoverModule)
			// },
			// {
			// 	path: "misc/components/progressbar",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/progressbar/progressbar.module").then((m) => m.ProgressbarModule)
			// },
			// {
			// 	path: "misc/components/ratings",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/ratings/ratings.module").then((m) => m.RatingsModule)
			// },
			// {
			// 	path: "misc/components/timepicker",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/timepicker/timepicker.module").then((m) => m.TimepickerModule)
			// },
			// {
			// 	path: "misc/components/toast",
			// 	loadChildren: () => import("./pages/misc/components/toast/toast.module").then((m) => m.ToastModule)
			// },
			// {
			// 	path: "misc/components/tooltips",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/tooltips/tooltips.module").then((m) => m.TooltipsModule)
			// },
			// {
			// 	path: "misc/components/typeahead",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/typeahead/typeahead.module").then((m) => m.TypeaheadModule)
			// },
			// {
			// 	path: "misc/components/badge",
			// 	loadChildren: () => import("./pages/misc/components/badge/badge.module").then((m) => m.BadgeModule)
			// },
			// {
			// 	path: "misc/components/breadcrumbs",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/breadcrumbs/breadcrumbs.module").then((m) => m.BreadcrumbsModule)
			// },
			// {
			// 	path: "misc/components/static-buttons",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/static-buttons/static-buttons.module").then(
			// 			(m) => m.StaticButtonsModule
			// 		)
			// },
			// {
			// 	path: "misc/components/button-groups",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/button-group/button-group.module").then((m) => m.ButtonGroupModule)
			// },
			// {
			// 	path: "misc/components/cards",
			// 	loadChildren: () => import("./pages/misc/components/cards/cards.module").then((m) => m.CardsModule)
			// },
			// {
			// 	path: "misc/components/list-group",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/list-group/list-group.module").then((m) => m.ListGroupModule)
			// },
			// {
			// 	path: "misc/components/navs",
			// 	loadChildren: () => import("./pages/misc/components/navs/navs.module").then((m) => m.NavsModule)
			// },
			// {
			// 	path: "misc/components/spinners",
			// 	loadChildren: () =>
			// 		import("./pages/misc/components/spinners/spinners.module").then((m) => m.SpinnersModule)
			// },
			// {
			// 	path: "misc/forms/input-groups",
			// 	loadChildren: () =>
			// 		import("./pages/misc/forms/input-groups/input-groups.module").then((m) => m.InputGroupsModule)
			// },
			// {
			// 	path: "misc/forms/form-elements",
			// 	loadChildren: () =>
			// 		import("./pages/misc/forms/form-elements/form-elements.module").then((m) => m.FormElementsModule)
			// },
			// {
			// 	path: "misc/forms/form-components",
			// 	loadChildren: () =>
			// 		import("./pages/misc/forms/form-components/form-components.module").then(
			// 			(m) => m.FormComponentsModule
			// 		)
			// },
			// {
			// 	path: "misc/forms/form-layouts",
			// 	loadChildren: () =>
			// 		import("./pages/misc/forms/form-layouts/form-layouts.module").then((m) => m.FormLayoutsModule)
			// },
			// {
			// 	path: "misc/forms/form-validation",
			// 	loadChildren: () =>
			// 		import("./pages/misc/forms/form-validation/form-validation.module").then(
			// 			(m) => m.FormValidationModule
			// 		)
			// },
			// {
			// 	path: "misc/charts-maps/charts",
			// 	loadChildren: () => import("./pages/misc/charts-maps/charts/chart.module").then((m) => m.ChartModule)
			// },
			// {
			// 	path: "misc/charts-maps/maps",
			// 	loadChildren: () => import("./pages/misc/charts-maps/maps/maps.module").then((m) => m.MapsModule)
			// },
			// {
			// 	path: "misc/colors",
			// 	loadChildren: () => import("./pages/misc/colors/colors.module").then((m) => m.ColorsModule)
			// }
		]
	},
	{
		path: "",
		component: Layout2Component,
		children: [
			{
				path: "misc/sample-pages/login",
				loadChildren: () => import("./pages/sample-pages/login/login.module").then((m) => m.LoginModule)
			} ,
			{
				path: "mobile/center",
				loadChildren: () => import("./pages/mobile/webView/mobilewebview.module").then((m) => m.MobileWebViewModule)
			},
			{
				path: "mobile/appointments",
				loadChildren: () => import("./pages/mobile/appointments/appointments.module").then((m) => m.AppointmentsModule)
			},
			{
				path: "mobile/login",
				loadChildren: () => import("./pages/mobile/login-page/login-page-m.module").then((m) => m.MobileLoginPageModule)
			},
			{
				path: "mobile/getmobilecode",
				loadChildren: () => import("./pages/mobile/mobilepin/getmobilepin.module").then((m) => m.MobilePinPageModule)
			},
			{
				path: "mobile/clockin",
				loadChildren: () => import("./pages/mobile/clockin/clockin.module").then((m) => m.ClockInModule)
			},
			{
				path: "mobile/clockin-details",
				loadChildren: () => import("./pages/mobile/clockindetails/clockindetails.module").then((m) => m.ClockInDetailsModule)
			},
			{
				path: "mobile/clockout",
				loadChildren: () => import("./pages/mobile/clockout/clockout.module").then((m) => m.ClockOutModule)
			},
			{
				path: "mobile/clockout-reason",
				loadChildren: () => import("./pages/mobile/clockout-reason/clockout-reason.module").then((m) => m.ClockoutReasonModule)
			},
			{
				path: "mobile/password",
				loadChildren: () => import("./pages/mobile/password/password.module").then((m) => m.PasswordModule)
			},
			{
				path: "mobile/userid",
				loadChildren: () => import("./pages/mobile/userid/userid.module").then((m) => m.UseridModule)
			},
			{
				path: "mobile/html-template/client",
				loadChildren: () => import("./pages/mobile/html-template/client/client.module").then((m) => m.ClientModule)
			},
			{
				path: "mobile/html-template/contact",
				loadChildren: () => import("./pages/mobile/html-template/contact/contact.module").then((m) => m.ContactModule)
			},
			{
				path: "mobile/html-template/estimate",
				loadChildren: () => import("./pages/mobile/html-template/estimate/estimate.module").then((m) => m.EstimateModule)
			},
			{
				path: "mobile/html-template/index",
				loadChildren: () => import("./pages/mobile/html-template/index/index.module").then((m) => m.IndexModule)
			},
			{
				path: "mobile/html-template/inventory",
				loadChildren: () => import("./pages/mobile/html-template/inventory/inventory.module").then((m) => m.InventoryModule)
			},
			{
				path: "mobile/html-template/lead",
				loadChildren: () => import("./pages/mobile/html-template/lead/lead.module").then((m) => m.LeadModule)
			},
			{
				path: "mobile/html-template/leftmenu",
				loadChildren: () => import("./pages/mobile/html-template/leftmenu/leftmenu.module").then((m) => m.LeftmenuModule)
			},
			{
				path: "mobile/html-template/login",
				loadChildren: () => import("./pages/mobile/html-template/login/login.module").then((m) => m.LoginModule)
			},
			{
				path: "mobile/html-template/nav",
				loadChildren: () => import("./pages/mobile/html-template/nav/nav.module").then((m) => m.navModule)
			},
			{
				path: "mobile/html-template/newdashboard",
				loadChildren: () => import("./pages/mobile/html-template/newdashboard/newdashboard.module").then((m) => m.NewdashboardModule)
			},
			{
				path: "mobile/html-template/order",
				loadChildren: () => import("./pages/mobile/html-template/order/order.module").then((m) => m.OrderModule)
			},
			{
				path: "mobile/html-template/passcode",
				loadChildren: () => import("./pages/mobile/html-template/passcode/passcode.module").then((m) => m.PasscodeModule)
			},
			{
				path: "mobile/html-template/signup",
				loadChildren: () => import("./pages/mobile/html-template/signup/signup.module").then((m) => m.SignupModule)
			},
			{
				path: "mobile/html-template/splash",
				loadChildren: () => import("./pages/mobile/html-template/splash/splash.module").then((m) => m.SplashModule)
			},
			{
				path: "mobile/html-template/userid",
				loadChildren: () => import("./pages/mobile/html-template/userid/userid.module").then((m) => m.UseridModule)
			},
			{
				path: "mobile/html-template/workorder",
				loadChildren: () => import("./pages/mobile/html-template/workorder/workorder.module").then((m) => m.WorkorderModule)
			},
			{
				path: "mobile/html-template/workorder-details",
				loadChildren: () => import("./pages/mobile/html-template/workorder-details/workorder-details.module").then((m) => m.WorkorderDetailsModule)
			},
			{
				path: "mobile/html-template/workorderexist-billdetails",
				loadChildren: () => import("./pages/mobile/html-template/workorderexist-billdetails/workorderexist-billdetails.module").then((m) => m.WorkorderexistBilldetailsModule)
			},
			{
				path: "mobile/html-template/workorderexist-carddetails",
				loadChildren: () => import("./pages/mobile/html-template/workorderexist-carddetails/workorderexist-carddetails.module").then((m) => m.WorkorderexistCarddetailsModule)
			},
			{
				path: "mobile/html-template/workorderexist-createcarddetails",
				loadChildren: () => import("./pages/mobile/html-template/workorderexist-createcarddetails/workorderexist-createcarddetails.module").then((m) => m.WorkorderexistCreatecarddetailsModule)
			},
			{
				path: "mobile/html-template/workorderexist-payment",
				loadChildren: () => import("./pages/mobile/html-template/workorderexist-payment/workorderexist-payment.module").then((m) => m.WorkorderexistPaymentModule)
			},
			{
				path: "mobile/html-template/workorderexistpopup",
				loadChildren: () => import("./pages/mobile/html-template/workorderexistpopup/workorderexistpopup.module").then((m) => m.WorkorderexistpopupModule)
			},
			{
				path: "mobile/html-template/workorderexistpopup-chatlog",
				loadChildren: () => import("./pages/mobile/html-template/workorderexistpopup-chatlog/workorderexistpopup-chatlog.module").then((m) => m.WorkorderexistpopupChatlogModule)
			},
			{
				path: "mobile/html-template/workorderexistpopup-setup",
				loadChildren: () => import("./pages/mobile/html-template/workorderexistpopup-setup/workorderexistpopup-setup.module").then((m) => m.WorkorderexistpopupSetupModule)
			},
			{
				path: "mobile/html-template/calendar-view",
				loadChildren: () => import("./pages/mobile/html-template/calendar-view/calendar-view.module").then((m) => m.CalendarViewModule)
			},
			{
				path: "mobile/html-template/joeybuilderstexas-dallas",
				loadChildren: () => import("./pages/mobile/html-template/joeybuilderstexas-dallas/joeybuilderstexas-dallas.module").then((m) => m.JoeybuilderstexasDallasModule)
			},
			{
				path: "mobile/html-template/joeybuilderstexas-houston",
				loadChildren: () => import("./pages/mobile/html-template/joeybuilderstexas-houston/joeybuilderstexas-houston.module").then((m) => m.JoeybuilderstexasHoustonModule)
			},
			{
				path: "mobile/html-template/texascontract-ehb",
				loadChildren: () => import("./pages/mobile/html-template/texascontract-ehb/texascontract-ehb.module").then((m) => m.TexascontractEhbModule)
			},
			{
				path: "mobile/html-template/ybarraprofessionalbuilderstexas-dallas",
				loadChildren: () => import("./pages/mobile/html-template/ybarraprofessionalbuilderstexas-dallas/ybarraprofessionalbuilderstexas-dallas.module").then((m) => m.YbarraprofessionalbuilderstexasDallasModule)
			},
			{
				path: "mobile/html-template/ybarraprofessionalbuilderstexas-houston",
				loadChildren: () => import("./pages/mobile/html-template/ybarraprofessionalbuilderstexas-houston/ybarraprofessionalbuilderstexas-houston.module").then((m) => m.YbarraprofessionalbuilderstexasHoustonModule)
			},
			{
				path: "mobile/html-template/joeybuilders-contract-or",
				loadChildren: () => import("./pages/mobile/html-template/joeybuilders-contract-or/joeybuilders-contract-or.module").then((m) => m.JoeybuildersContractOrModule)
			},
			{
				path: "mobile/html-template/joeybuilders-contract-wa",
				loadChildren: () => import("./pages/mobile/html-template/joeybuilders-contract-wa/joeybuilders-contract-wa.module").then((m) => m.JoeybuildersContractWaModule)
			},
			{
				path: "mobile/html-template/ypbarizona-changeorder",
				loadChildren: () => import("./pages/mobile/html-template/ypbarizona-changeorder/ypbarizona-changeorder.module").then((m) => m.YpbarizonaChangeorderModule)
			},
			{
				path: "mobile/html-template/ypbarizona-contractrevised-final",
				loadChildren: () => import("./pages/mobile/html-template/ypbarizona-contractrevised-final/ypbarizona-contractrevised-final.module").then((m) => m.YpbarizonaContractrevisedFinalModule)
			},
			{
				path: "mobile/html-template/experthomebuilders",
				loadChildren: () => import("./pages/mobile/html-template/expert-home-builders/expert-home-builders.module").then((m) => m.ExpertHomeBuildersModule)
			},
			{
				path: "mobile/html-template/experthomesend",
				loadChildren: () => import("./pages/mobile/html-template/expert-home-send/expert-home-send.module").then((m) => m.ExpertHomeSendModule)
			},
			{
				path: "mobile/html-template/send-change-order",
				loadChildren: () => import("./pages/mobile/html-template/send-change-order/send-change-order.module").then((m) => m.SendChangeOrderModule)
			},
			{
				path: "mobile/html-template/submit-finance-application",
				loadChildren: () => import("./pages/mobile/html-template/submit-finance-application/submit-finance-application.module").then((m) => m.SubmitFinanceApplicationModule)
			},
			{
				path: "mobile/html-template/submit-material-approval",
				loadChildren: () => import("./pages/mobile/html-template/submit-material-approval/submit-material-approval.module").then((m) => m.SubmitMaterialApprovalModule)
			},
			{
				path: "mobile/html-template/submit-completion-form",
				loadChildren: () => import("./pages/mobile/html-template/submit-completion-form/submit-completion-form.module").then((m) => m.SubmitCompletionFormModule)
			},
			{
				path: "mobile/html-template/submit-subcontractor-agreement",
				loadChildren: () => import("./pages/mobile/html-template/submit-subcontractor-agreement/submit-subcontractor-agreement.module").then((m) => m.SubmitSubcontractorAgreementModule)
			},
			{
				path: "mobile/html-template/upload-permit",
				loadChildren: () => import("./pages/mobile/html-template/upload-permit/upload-permit.module").then((m) => m.UploadPermitModule)
			},
			{
				path: "mobile/html-template/upload-photos",
				loadChildren: () => import("./pages/mobile/html-template/upload-photos/upload-photos.module").then((m) => m.UploadPhotosModule)
			},
		]
	}
];

//, relativeLinkResolution: "legacy"
@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {

	roleRouts :any = [];
 
	constructor( 
		public service: AppService, 
	  ) {
		 
		// if(service.CurentUserData.roleId != 'e163b520-ee75-45d6-b708-c7dcaa54b520xx'){
 
	 
		// 	this.roleRouts = [
			 
			  
		// 		{
		// 			path: "app-views/lgCenter",
		// 			loadChildren: () =>
		// 				import("./pages/lgcenter/lg-center.module").then((m) => m.LGCenterTrackerModule)
		// 		}
				 
		// 	]
		// }
		// else
		
		{


			this.roleRouts = [
				{
					path: "",
					redirectTo: "dashboards/area",
					pathMatch: "full"
				},
				{
					path: "dashboards/welcome",
					loadChildren: () =>
						import("./pages/dashboards/Welcome/welcome.component").then(
							(m) => m.WelcomeComponent
						)
				}, 
				{
					path: "dashboards/datalists",
					loadChildren: () =>
						import("./pages/dashboards/data-lists/datalists.module").then(
							(m) => m.DataListsModule
						)
				}, 
				{
					path: "dashboards/area",
					loadChildren: () =>
						import("./pages/dashboards/area/area.module").then(
							(m) => m.AreaModule
						)
				},  
				{
					path: "dashboards/projects",
					loadChildren: () =>
						import("./pages/dashboards/projects/projects.module").then(
							(m) => m.ProjectsModule
						)
				},
				{
					path: "dashboards/schedule",
					loadChildren: () =>
						import("./pages/schedule/calendar.component.module").then(
							(m) => m.CalendarModule
						)
				},
				{
					path: "misc/lead/map",
					loadChildren: () => import("./pages/misc/components/leads/map/map.module").then((m) => m.MapModule)
				}, 
				{
					path: "misc/lead/dashboard",
					loadChildren: () => import("./pages/misc/components/leads/dashboard/lead-dashboard.module").then((m) => m.LeadDashboardModule)
				}, 
				{
					path: "admin/center",
					loadChildren: () => import("./pages/admin/admin.module").then((m) => m.AdminCenterModule)
				}, 
				{
					path: "reports/center",
					loadChildren: () => import("./pages/reports/reports.module").then((m) => m.ReportsCenterModule)
				}, 
				{
					path: "datalists/center",
					loadChildren: () => import("./pages/datalists/data-lists.module").then((m) => m.DataListsCenterModule)
				}, 
				{
					path: "admin/setting/users",
					loadChildren: () => import("./pages/admin/users/users-center.module").then((m) => m.UsersCenterModule)
				}, 
				{
					path: "admin/setting/twilio",
					loadChildren: () => import("./pages/admin/twilio/twilio-center.module").then((m) => m.TwilioCenterModule)
				},
				{
					path: "app-views/user/profile",
					loadChildren: () => import("./pages/app-views/user/profile/profile.module").then((m) => m.ProfileModule)
				},
				{
					path: "app-views/contacts",
					loadChildren: () => import("./pages/app-views/contacts/contacts.module").then((m) => m.ContactsModule),
					data: {
						layout: "full"
					}
				},
				{
					path: "app-views/team",
					loadChildren: () => import("./pages/sample-pages/team/team.module").then((m) => m.TeamModule)
				}, 
				{
					path: "app-views/lgCenter",
					loadChildren: () =>
						import("./pages/lgcenter/lg-center.module").then((m) => m.LGCenterTrackerModule)
				}
				 
			]

		}



	  }

	  

}




// const routes: Routes = [
// 	{
// 		path: "",
// 		component: Layout1Component,
		
// 		children: [
// 			{
// 				path: "",
// 				redirectTo: "dashboards/area",
// 				pathMatch: "full"
// 			},
// 			{
// 				path: "dashboards/welcome",
// 				loadChildren: () =>
// 					import("./pages/dashboards/Welcome/welcome.component").then(
// 						(m) => m.WelcomeComponent
// 					)
// 			}, 
// 			{
// 				path: "dashboards/datalists",
// 				loadChildren: () =>
// 					import("./pages/dashboards/data-lists/datalists.module").then(
// 						(m) => m.DataListsModule
// 					)
// 			}, 
// 			{
// 				path: "dashboards/area",
// 				loadChildren: () =>
// 					import("./pages/dashboards/area/area.module").then(
// 						(m) => m.AreaModule
// 					)
// 			},  
// 			{
// 				path: "dashboards/projects",
// 				loadChildren: () =>
// 					import("./pages/dashboards/projects/projects.module").then(
// 						(m) => m.ProjectsModule
// 					)
// 			},
// 			{
// 				path: "dashboards/schedule",
// 				loadChildren: () =>
// 					import("./pages/schedule/calendar.component.module").then(
// 						(m) => m.CalendarModule
// 					)
// 			},
// 			{
// 				path: "misc/lead/map",
// 				loadChildren: () => import("./pages/misc/components/leads/map/map.module").then((m) => m.MapModule)
// 			}, 
// 			{
// 				path: "misc/lead/dashboard",
// 				loadChildren: () => import("./pages/misc/components/leads/dashboard/lead-dashboard.module").then((m) => m.LeadDashboardModule)
// 			}, 
// 			{
// 				path: "admin/center",
// 				loadChildren: () => import("./pages/admin/admin.module").then((m) => m.AdminCenterModule)
// 			}, 
// 			{
// 				path: "admin/setting/users",
// 				loadChildren: () => import("./pages/admin/users/users-center.module").then((m) => m.UsersCenterModule)
// 			}, 
// 			{
// 				path: "admin/setting/twilio",
// 				loadChildren: () => import("./pages/admin/twilio/twilio-center.module").then((m) => m.TwilioCenterModule)
// 			},
// 			{
// 			    path: "app-views/user/profile",
// 				loadChildren: () => import("./pages/app-views/user/profile/profile.module").then((m) => m.ProfileModule)
// 			},
// 			{
// 				path: "app-views/contacts",
// 				loadChildren: () => import("./pages/app-views/contacts/contacts.module").then((m) => m.ContactsModule),
// 				data: {
// 					layout: "full"
// 				}
// 			},
// 			{
// 				path: "app-views/team",
// 				loadChildren: () => import("./pages/sample-pages/team/team.module").then((m) => m.TeamModule)
// 			}, 
// 			{
// 				path: "app-views/lgCenter",
// 				loadChildren: () =>
// 					import("./pages/lgcenter/lg-center.module").then((m) => m.LGCenterTrackerModule)
// 			}
// 			{
// 				path: "app-views/lgCenter",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/issue-tracker/issue-tracker.module").then((m) => m.IssueTrackerModule)
// 			}
// 			,  
// 			{
// 				path: "dashboards/calendar",
// 				loadChildren: () => import("./pages/misc/components/full-calendar/calendar/calendar.component.module").then((m) => m.CalendarModule)
// 			}
// 			{
// 				path: "dashboards/area/edit",
// 				loadChildren: () => import("./pages/dashboards/Welcome/welcome.module").then((m) => m.WelcomeModule)
// 			},
// 			{
// 				path: "dashboards/e-commerce",
// 				loadChildren: () =>
// 					import("./pages/dashboards/e-commerce/e-commerce.module").then((m) => m.ECommerceModule)
// 			},
// 			{
// 				path: "application-views/user/profile",
// 				loadChildren: () => import("./pages/app-views/user/profile/profile.module").then((m) => m.ProfileModule)
// 			},
// 			{
// 				path: "application-views/user/timeline",
// 				loadChildren: () =>
// 					import("./pages/app-views/user/timeline/timeline.module").then((m) => m.TimelineModule)
// 			},
// 			{
// 				path: "application-views/user/preferences",
// 				loadChildren: () =>
// 					import("./pages/app-views/user/preferences/preferences.module").then((m) => m.PreferencesModule)
// 			},
// 			{
// 				path: "application-views/user/photos",
// 				loadChildren: () =>
// 					import("./pages/app-views/user/photos/photos.module").then((m) => m.UserPhotosModule)
// 			},
// 			{
// 				path: "application-views/user/connections",
// 				loadChildren: () =>
// 					import("./pages/app-views/user/connections/connections.module").then((m) => m.UserConnectionsModule)
// 			},
// 			{
// 				path: "application-views/files",
// 				loadChildren: () => import("./pages/app-views/files/files.module").then((m) => m.FilesModule)
// 			},
// 			{
// 				path: "application-views/contacts",
// 				loadChildren: () => import("./pages/app-views/contacts/contacts.module").then((m) => m.ContactsModule),
// 				data: {
// 					layout: "full"
// 				}
// 			},
// 			{
// 				path: "application-views/messages",
// 				loadChildren: () => import("./pages/app-views/messages/messages.module").then((m) => m.MessagesModule)
// 			},
// 			{
// 				path: "application-views/mail",
// 				loadChildren: () => import("./pages/app-views/mail/mail.module").then((m) => m.MailModule),
// 				data: {
// 					layout: "full"
// 				}
// 			},
// 			{
// 				path: "application-views/photos",
// 				loadChildren: () => import("./pages/app-views/photos/photos.module").then((m) => m.PhotosModule)
// 			},
// 			{
// 				path: "application-views/todo",
// 				loadChildren: () => import("./pages/app-views/todo/todo.module").then((m) => m.TodoModule)
// 			},
// 			{
// 				path: "misc/sample-pages/invoice",
// 				loadChildren: () => import("./pages/sample-pages/invoice/invoice.module").then((m) => m.InvoiceModule)
// 			},
// 			{
// 				path: "misc/sample-pages/pricing-table",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/pricing-table/pricing-table.module").then((m) => m.PricingTableModule)
// 			},
// 			{
// 				path: "misc/sample-pages/search-results",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/search-results/search-results.module").then(
// 						(m) => m.SearchResultsModule
// 					)
// 			},
// 			{
// 				path: "misc/sample-pages/issue-tracker",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/issue-tracker/issue-tracker.module").then((m) => m.IssueTrackerModule)
// 			},
// 			{
// 				path: "misc/sample-pages/faq",
// 				loadChildren: () => import("./pages/sample-pages/faq/faq.module").then((m) => m.FaqModule)
// 			},
// 			{
// 				path: "misc/sample-pages/team",
// 				loadChildren: () => import("./pages/sample-pages/team/team.module").then((m) => m.TeamModule)
// 			},
// 			{
// 				path: "misc/content/typography",
// 				loadChildren: () => import("./pages/misc/typography/typography.module").then((m) => m.TypographyModule)
// 			},
// 			{
// 				path: "misc/content/code",
// 				loadChildren: () => import("./pages/misc/code/code.module").then((m) => m.CodeModule)
// 			},
// 			{
// 				path: "misc/content/images",
// 				loadChildren: () => import("./pages/misc/images/images.module").then((m) => m.ImagesModule)
// 			},
// 			{
// 				path: "misc/content/tables",
// 				loadChildren: () => import("./pages/misc/tables/tables.module").then((m) => m.TablesModule)
// 			},
// 			{
// 				path: "misc/icons",
// 				loadChildren: () => import("./pages/misc/icons/icons.module").then((m) => m.IconsModule)
// 			},
// 			{
// 				path: "misc/components/accordion",
// 				loadChildren: () =>
// 					import("./pages/misc/components/accordion/accordion.module").then((m) => m.AccordionModule)
// 			},
// 			{
// 				path: "misc/components/alert",
// 				loadChildren: () => import("./pages/misc/components/alert/alert.module").then((m) => m.AlertModule)
// 			},
// 			{
// 				path: "misc/components/carousel",
// 				loadChildren: () =>
// 					import("./pages/misc/components/carousel/carousel.module").then((m) => m.CarouselModule)
// 			},
// 			{
// 				path: "misc/components/collapse",
// 				loadChildren: () =>
// 					import("./pages/misc/components/collapse/collapse.module").then((m) => m.CollapseModule)
// 			},
// 			{
// 				path: "misc/components/datepicker",
// 				loadChildren: () =>
// 					import("./pages/misc/components/datepicker/datepicker.module").then((m) => m.DatepickerModule)
// 			},
// 			{
// 				path: "misc/components/dropdown",
// 				loadChildren: () =>
// 					import("./pages/misc/components/dropdown/dropdown.module").then((m) => m.DropdownModule)
// 			},
// 			{
// 				path: "misc/components/modal",
// 				loadChildren: () => import("./pages/misc/components/modal/modal.module").then((m) => m.ModalModule)
// 			},
// 			{
// 				path: "misc/components/tab",
// 				loadChildren: () => import("./pages/misc/components/tab/tab.module").then((m) => m.TabModule)
// 			},
// 			{
// 				path: "misc/components/pagination",
// 				loadChildren: () =>
// 					import("./pages/misc/components/pagination/pagination.module").then((m) => m.PaginationModule)
// 			},
// 			{
// 				path: "misc/components/popover",
// 				loadChildren: () =>
// 					import("./pages/misc/components/popover/popover.module").then((m) => m.PopoverModule)
// 			},
// 			{
// 				path: "misc/components/progressbar",
// 				loadChildren: () =>
// 					import("./pages/misc/components/progressbar/progressbar.module").then((m) => m.ProgressbarModule)
// 			},
// 			{
// 				path: "misc/components/ratings",
// 				loadChildren: () =>
// 					import("./pages/misc/components/ratings/ratings.module").then((m) => m.RatingsModule)
// 			},
// 			{
// 				path: "misc/components/timepicker",
// 				loadChildren: () =>
// 					import("./pages/misc/components/timepicker/timepicker.module").then((m) => m.TimepickerModule)
// 			},
// 			{
// 				path: "misc/components/toast",
// 				loadChildren: () => import("./pages/misc/components/toast/toast.module").then((m) => m.ToastModule)
// 			},
// 			{
// 				path: "misc/components/tooltips",
// 				loadChildren: () =>
// 					import("./pages/misc/components/tooltips/tooltips.module").then((m) => m.TooltipsModule)
// 			},
// 			{
// 				path: "misc/components/typeahead",
// 				loadChildren: () =>
// 					import("./pages/misc/components/typeahead/typeahead.module").then((m) => m.TypeaheadModule)
// 			},
// 			{
// 				path: "misc/components/badge",
// 				loadChildren: () => import("./pages/misc/components/badge/badge.module").then((m) => m.BadgeModule)
// 			},
// 			{
// 				path: "misc/components/breadcrumbs",
// 				loadChildren: () =>
// 					import("./pages/misc/components/breadcrumbs/breadcrumbs.module").then((m) => m.BreadcrumbsModule)
// 			},
// 			{
// 				path: "misc/components/static-buttons",
// 				loadChildren: () =>
// 					import("./pages/misc/components/static-buttons/static-buttons.module").then(
// 						(m) => m.StaticButtonsModule
// 					)
// 			},
// 			{
// 				path: "misc/components/button-groups",
// 				loadChildren: () =>
// 					import("./pages/misc/components/button-group/button-group.module").then((m) => m.ButtonGroupModule)
// 			},
// 			{
// 				path: "misc/components/cards",
// 				loadChildren: () => import("./pages/misc/components/cards/cards.module").then((m) => m.CardsModule)
// 			},
// 			{
// 				path: "misc/components/list-group",
// 				loadChildren: () =>
// 					import("./pages/misc/components/list-group/list-group.module").then((m) => m.ListGroupModule)
// 			},
// 			{
// 				path: "misc/components/navs",
// 				loadChildren: () => import("./pages/misc/components/navs/navs.module").then((m) => m.NavsModule)
// 			},
// 			{
// 				path: "misc/components/spinners",
// 				loadChildren: () =>
// 					import("./pages/misc/components/spinners/spinners.module").then((m) => m.SpinnersModule)
// 			},
// 			{
// 				path: "misc/forms/input-groups",
// 				loadChildren: () =>
// 					import("./pages/misc/forms/input-groups/input-groups.module").then((m) => m.InputGroupsModule)
// 			},
// 			{
// 				path: "misc/forms/form-elements",
// 				loadChildren: () =>
// 					import("./pages/misc/forms/form-elements/form-elements.module").then((m) => m.FormElementsModule)
// 			},
// 			{
// 				path: "misc/forms/form-components",
// 				loadChildren: () =>
// 					import("./pages/misc/forms/form-components/form-components.module").then(
// 						(m) => m.FormComponentsModule
// 					)
// 			},
// 			{
// 				path: "misc/forms/form-layouts",
// 				loadChildren: () =>
// 					import("./pages/misc/forms/form-layouts/form-layouts.module").then((m) => m.FormLayoutsModule)
// 			},
// 			{
// 				path: "misc/forms/form-validation",
// 				loadChildren: () =>
// 					import("./pages/misc/forms/form-validation/form-validation.module").then(
// 						(m) => m.FormValidationModule
// 					)
// 			},
// 			{
// 				path: "misc/charts-maps/charts",
// 				loadChildren: () => import("./pages/misc/charts-maps/charts/chart.module").then((m) => m.ChartModule)
// 			},
// 			{
// 				path: "misc/charts-maps/maps",
// 				loadChildren: () => import("./pages/misc/charts-maps/maps/maps.module").then((m) => m.MapsModule)
// 			},
// 			{
// 				path: "misc/colors",
// 				loadChildren: () => import("./pages/misc/colors/colors.module").then((m) => m.ColorsModule)
// 			}
// 		]
// 	},
// 	{
// 		path: "",
// 		component: Layout2Component,
// 		children: [
// 			{
// 				path: "misc/sample-pages/login",
// 				loadChildren: () => import("./pages/sample-pages/login/login.module").then((m) => m.LoginModule)
// 			},
// 			{
// 				path: "misc/sample-pages/register",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/register/register.module").then((m) => m.RegisterModule)
// 			},
// 			{
// 				path: "misc/sample-pages/forgot-password",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/forgot-password/forgot-password.module").then(
// 						(m) => m.ForgotPasswordModule
// 					)
// 			},
// 			{
// 				path: "misc/sample-pages/error",
// 				loadChildren: () => import("./pages/sample-pages/error/error.module").then((m) => m.ErrorModule)
// 			},
// 			{
// 				path: "misc/sample-pages/coming-soon",
// 				loadChildren: () =>
// 					import("./pages/sample-pages/coming-soon/coming-soon.module").then((m) => m.ComingSoonModule)
// 			}
// 		]
// 	}
// ];
