import { Component, OnInit, Input } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { timeStamp } from "console";
import { AppService } from "../../../app.service";
import { DataListsApiService } from "src/app/shared/apiServices/datalists/datalists.api.services";
import { EmployeeApiService } from "src/app/shared/apiServices/Employee/employee.api.services";
var moment = require("moment");
var moment = require("moment-timezone");
//const dateInput = new Date();



// import * as Rx from 'rxjs/Rx';

@Component({
  selector: "app-top-nav-referral",
  templateUrl: "./referral-top-nav.component.html",
  styleUrls: ["./referral-top-nav.component.scss"],
})
export class ReferralTopNavComponent implements OnInit {
  timeZone:any;
  EventSubWatcher: any;
 

  myStatusDialog: boolean = false;

  viewStartDate: Date = new Date();
  userRoleId:string = '';
  constructor(private router: Router,
    public service: AppService, 
    public employeeApiService: EmployeeApiService,
    public vdataListsApiService: DataListsApiService) {


      this.userRoleId =  service.CurentUserData.roleId;

     
 
  }
  ngOnInit() {
   
    this.getCustomDataLists();
    this.GetDataListNames();
    this.twillioDeviceSetUp();
    this.GetRoles();
    this.eventChangeSubscribe() 
     this.loadDatePicker();
 
      
  }

  eventChangeSubscribe()
  {
    this.EventSubWatcher = this.service.App.onGlobalDateChange.subscribe((obj) => {
      const startdate = moment(this.service.UtcPickerData.fromDate)
      .tz(this.timeZone)
      .toDate();
     

      $("#daterangeTop")
      .data("daterangepicker")
      .setStartDate(new Date(startdate));

      $("#daterangeTop")
      .data("daterangepicker")
      .setEndDate(new Date(startdate))
    });
  }

  twillioDeviceSetUp(){
    var CurentUserData = this.service.CurentUserData;  
    //this.service.DevelopConsoleLog("callConnect CurentUserData",  CurentUserData);
    //this.service.DevelopConsoleLog("callConnect twillioAcessToken",  CurentUserData.twillioAcessToken);
    if(CurentUserData.twillioAcessToken != undefined){
      //this.service.DevelopConsoleLog("callConnect d.twillioAcessToken",  CurentUserData.twillioAcessToken);
      this.service.Twilioclient.Device.setup(CurentUserData.twillioAcessToken, { debug: true, enableRingingState: true, answerOnBridge: true });
      
      //this.LockScreen()
    }
    else{
      this.LockScreen()
    }
  }


  loadDatePicker() {
     

    $("#daterangeTop")["daterangepicker"](
      {
        autoApply: true,
        singleDatePicker: true,
        timePicker: false,
        autoUpdateInput: true,
        showDropdowns: false,
        startDate: new Date(this.service.ServerDataDto.fromDate),
        endDate: new Date(this.service.ServerDataDto.fromDate),
      },
      (x, d) => {
         

        this.service.pickerPostData = {
          fromDate: x._d,
          toDate: d._d,
        };
        this.service.UtcPickerData = {
          fromDate: x._d,
          toDate: d._d,
        };

        this.service.ServerDataDto = {
          fromDate: new Date(x._d).toISOString(),
          toDate: new Date(d._d).toISOString(),
        };


        this.service.SelectedEpochDate = new Date(x._d).getTime()
        this.service.App.onGlobalDateChange.emit(this.service.pickerPostData);
      }
    );

 
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + 1; // + (day == 0 ? -6 : 1) adjust when day is sunday
    return new Date(d.setDate(diff));
  }
  LockScreen() {
    //
    //this.service.DevelopConsoleLog("LockScreen Caled");

    var frlnk =
    this.service.qdispatchDomain + "/SignOut.aspx";
    this.service.App.LoadMainIframe.emit({visible: true,src: frlnk}); 

    this.service.LockedApp = true;
    this.service.App.onOpenTopSlide.emit({ type: "OpenlockedScreenComponent" });
    localStorage.setItem("LockedApp", "1");
    this.service.logOut();
  }
 
  SelectMenue(page:string) {
    this.router.navigate([page], {});
 
  }
selecteddl:number=1;
  setDatalistSurce(src:number) {

    this.selecteddl = src;
    this.filterDatalists =[];
    if(src == 0){
      this.getCustomDataLists();
      this.GetDataListNames();
    this.selecteddl = 1;
    }
    else if(src == 1){
      this.filterDatalists = this.Datalists ;
    }
    else{
      this.filterDatalists = this.customDatalists;
    }

  }
  customDatalists:any = [];
  Datalists:any = [];
  filterDatalists:any = [];
getCustomDataLists() {
  
  //
  this.customDatalists =[];
  this.filterDatalists =[];
      this.vdataListsApiService
        .GetCustomDataListNames()
        .then((data) => { 
          this.customDatalists = data;
          //this.service.DevelopConsoleLog("getDataLists GetCustomDataListNames", data);
          
        });
  
  }


  GetDataListNames() {
  
    //GetCustomDataListNames
    this.filterDatalists =[];
    this.Datalists =[];
        this.vdataListsApiService
          .GetDataListNames()
          .then((data) => { 
            this.Datalists = data;
            this.filterDatalists = this.Datalists ;
            //this.service.DevelopConsoleLog("getDataLists GetCustomDataListNames", data);
            
          });
    
    }


    GetRoles() {
      this.employeeApiService
        .GetRoles()
        .then((data) => {
        this.service.cRoles = data;
      
        
    
         });
      }


      public LoadDailySchedule(n:number):void {
        try {
          var params = [
            "height=" + screen.height,
            "width=" + screen.width,
            "fullscreen=yes",
          ].join(",");
          var frlnk =
            this.service.qdispatchDomain + "/Control/Dispatch/DailySchedule.aspx?rwndrnd=1"
    
            if(n==0){
              frlnk =
            this.service.qdispatchDomain + "/Control/Marketing/MarketingCalendar.aspx?status=veiwall&rwndrnd=1"
            }

           this.service.App.LoadMainIframe.emit({visible: true,src: frlnk}); 

          // var popup = window.open(frlnk, "DailySchedule", params);
          // popup.moveTo(0, 0);
        } catch (x) {
          this.service.DevelopConsoleLog("LoadDailySchedule", x);
        }
      }


  public LoadTwmlDail(item: any) {
    try {
      var params = [
        "height=" + screen.height,
        "width=" + screen.width,
        "fullscreen=yes",
      ].join(",");
      var frlnk =
        this.service.qdispatchDomain + "/ConProUi/Twml/TwmlDail.aspx?DataListVarId=" +
        item.DataListVarId +   "&CallBtn=1";
       this.service.App.LoadMainIframe.emit({visible: true,src: frlnk}); 
      // var popup = window.open(frlnk, "TwmlDail", params);
      // popup.moveTo(0, 0);
    } catch (x) {
      this.service.DevelopConsoleLog("LoadDailyScheduleGroup", x);
    }
  }

  ngOnDestroy()
{
  if(this.EventSubWatcher)
    this.EventSubWatcher.unsubscribe()
}

}
