import { Component, OnInit } from '@angular/core';
import { AppService } from "src/app/app.service";

@Component({
	selector: 'app-logo',
	templateUrl: './logo.component.html',
	styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
	constructor(public service: AppService) {

		this.service.sidebarActive = true;
	}

	ngOnInit() {
this.service.sidebarActive = true;

	}
}
