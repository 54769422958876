import { Component, OnInit } from "@angular/core";
import { AppService } from "src/app/app.service";
import { ActivatedRoute, Router, Params } from "@angular/router";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  searchFocused = false;
  searchValue = "";

  constructor(public service: AppService, public router: Router) {}

  searchReset() {


    this.service.App.RunSearch.emit(this.searchValue);

    this.service.DevelopConsoleLog('RunSearch:', this.searchValue);
    // this.router.navigate(["/dashboards/projects/grid"], {
    //   queryParams: { areaVarId: "search", searchValue: this.searchValue },
    // });

    const navigationExtras = {
      queryParams: { areaVarId: 'search', searchValue: this.searchValue },
      reload: true
    };
    this.router.navigate(['/dashboards/projects/grid'], navigationExtras);


   
    //   this.searchValue = '';
    //   this.searchFocused = false;
    this.service.searchToggled = false;
  }

  ngOnInit() {}
}
