<div class="user ms-4 userInfo_userdto" ngbDropdown>
	<a routerLink="./" ngbDropdownToggle>
		<img class="user__img w-7 h-7 rounded" [src]="userInfo.imageUrl" [alt]="userInfo.lineName" />
	</a>
	<div ngbDropdownMenu>
		<div class="bg-gradient-perano px-4 py-3 mb-1 rounded-1 text-white fw-bold white-space-nowrap">Hi {{userInfo.name}}</div>
		<span ngbDropdownItem class="link">role: {{userInfo.role}}</span>
		<span ngbDropdownItem class="link">email: {{userInfo.email}}</span>
		<span ngbDropdownItem class="link">Phone: {{userInfo.cellPhone}}</span>
		<!-- <a ngbDropdownItem routerLink=""><i class="zwicon zwicon-coin"></i>Billing</a> <i class="zwicon zwicon-user-circle"></i>
		<a ngbDropdownItem routerLink=""><i class="zwicon zwicon-cog"></i>Settings</a> -->
		<span ngbDropdownItem  class="link"  (click)="UserLogOut()"><i class="zwicon zwicon-sign-out"></i>Sign Out!</span>
	</div>
</div>