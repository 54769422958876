
import { Injectable } from '@angular/core';
import { datadogLogs as datadog } from '@datadog/browser-logs';
import { environment } from './../environments/environment';
import packageInfo from '../../package.json'
import { log } from 'console';
// import { AppService } from "src/app/app.service";
 
@Injectable({
  providedIn: 'root',
})
export class Logger {
  private initialized = false;
  constructor() {
     
    //this.service.DevelopConsoleLog('no Logger:environment 1111111111111',environment);
  //   if (!environment?.datadog) {
  //     return;
  //   }

  //   datadog.init({
  //     clientToken: environment.datadog.clientToken,
  //     site: environment.datadog.site,
  //     env: this.GetEnv,
  //     version :  packageInfo.version,
  //     service: environment.datadog.service,
  //     forwardErrorsToLogs: true,
  //     sampleRate: 100,
  //   });

  
 
  //  this.initialized = true;
  }


  public get GetEnv(): string {

 
    let surl = window.location.href;
    if (surl.indexOf('localhost') != -1) {


       return "development"; 

    }
    else if (surl.indexOf('test-leadmop') != -1) 
    {
        return "test"; 
        
    }
    else if (surl.indexOf('qa-leadmop') != -1) 
    {
        return "qa"; 
        
    }
    else if (surl.indexOf('rel-leadmop') != -1) 
    {
        return "release"; 
    }
    else if (surl.indexOf('leadmop') != -1) 
    {
        return "production"; 
        
    } 
    else
    { 
        return "development"; 
    }


}


  public debug(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      //this.service.DevelopConsoleLog('dderror:message',message);
      //this.service.DevelopConsoleLog('dderror:context',context);
      datadog.logger.debug(message, context);
    }
  }

  public info(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      //this.service.DevelopConsoleLog('ddinfo:message',message);
      //this.service.DevelopConsoleLog('ddinfo:context',context);
      datadog.logger.info(message, context);
    }
  }

  public warn(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadog.logger.warn(message, context);
    }
  }

  public error(message: string, context?: { [x: string]: any }): void {
    if (this.initialized) {
      datadog.logger.error(message, context);
    }
  }

  
}