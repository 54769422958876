import { Component } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppService } from "src/app/app.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styles: [':host { display: block; height: 100% }']
})
export class AppComponent {
	title = 'LeadMop Admin';

	// Page loading indicator
	constructor(private router: Router, public service: AppService) {
		this.router.events.subscribe((event: Event) => {
			switch (true) {
				case event instanceof NavigationStart: {
					service.isLoading = true;
					break;
				}
				case event instanceof NavigationEnd:
				case event instanceof NavigationCancel:
				case event instanceof NavigationError: {
					setTimeout(() => {
						service.isLoading = false;
					}, 1000);
					break;
				}
				default: {
					break;
				}
			}
		});
	}
}
