import { Injectable } from "@angular/core";
import { AppService } from "../../../app.service";
import { HttpClient } from "@angular/common/http";
 
@Injectable({
  providedIn: "root",
})
export class DataListsApiService {
  constructor(public service: AppService, public http: HttpClient) {}

 
  GetCustomDataListNames() {
 
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Data/DataLists/GetCustomDataListNames"
    );
 
  }

  GetDataListNames() {
 
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "Data/DataLists/GetDataListNames"
    );
 
  }


  CreateProjectsDataList(projects: Array<any>, dataListName:string, dataListType:string) {
 
     var prdata = { projects: projects, dataListName: dataListName, dataListType: dataListType };

    // this.service.DevelopConsoleLog("CreateDataList projects", prdata);

    return this.service.Data.ExecuteAPI_Post<Array<any>>(
      "Data/DataLists/CreateProjectsDataList", prdata
    );
 
  }


  GetUserDataListsNames() { 
   return this.service.Data.ExecuteAPI_Get<Array<any>>(
     "Data/DataLists/GetUserDataListsNames"
   );

 }

 
  
 GetDataListCenterdb(SelectedColumn:string) { 

  var moment = require("moment-timezone");
  //const dateInput = new Date();
  const timeZone = moment.tz.guess();

  // var startdate = moment(this.service.UtcPickerData.fromDate).add(-1, 'days')//.startOf('month')
  //   .tz(timeZone)
  //   .toDate().toISOString();
  //   var enddate = moment(this.service.UtcPickerData.fromDate).add(1, 'days')//.endOf('month')
  //   .tz(timeZone)
  //   .toDate().toISOString();

  moment.updateLocale('en', {
    week: {
        dow: 1 // Monday is the first day of the week
    }
});

  var startdate = moment(this.service.UtcPickerData.fromDate)
    .startOf('week')
    .tz(timeZone)
    .toDate().toISOString();

var enddate = moment(this.service.UtcPickerData.fromDate)
    .endOf('week')
    .tz(timeZone)
    .toDate().toISOString();
 


    this.service.DevelopConsoleLog("GetDataListCenterdb:startdate", startdate);
    this.service.DevelopConsoleLog("GetDataListCenterdb:enddate", enddate);

  return this.service.Data.ExecuteAPI_Get<Array<any>>(
    "Data/DataLists/GetDataListCenterdb?" + 
    "startDate=" +  startdate +
    "&EndDate=" +  enddate  + 
    "&SelectedColumn=" + SelectedColumn
  );

}
}
