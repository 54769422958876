import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "src/app/app.service";
@Pipe({
  name: "utcToLocal ",
})
export class UtcToLocalPipe implements PipeTransform {
  constructor(public service: AppService) {}

  transform(utcDate: Date): Date {
    const date = new Date(utcDate);
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate;
  }
}
