import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { AvatarComponent } from "./avatar.component";

@NgModule({
	declarations: [AvatarComponent],
	imports: [CommonModule, NgbModalModule],
	exports: [AvatarComponent]
})
export class AvatarModule {}
