

 


export class KeyValue {
    public Key: string;
    public Value: number;
}
export class KeyValueDefault {
    public Key: string;
    public Value: number;
    public MainID: number;
    public Is_Default: boolean;
}
export class KeyValueString {
    public Key: string;
    public Value: string;
}

export class RolesRigts {
    public Accountant: boolean = false;
    public AccountantManager: boolean = false;
    public AccountantViewer: boolean = false;
    public Admin: boolean = false;
    public Canvasser: boolean = false;
    public Dispatch: boolean = false;
    public DispatchViewer: boolean = false;
    public FSM: boolean = false;
    public HR: boolean = false;
    public InstallManager: boolean = false;
    public Installer: boolean = false;
    public Marketer: boolean = false;
    public MobileAdmin: boolean = false;
    public Production: boolean = false;
    public ProductionManager: boolean = false;
    public ProductionProcessor: boolean = false;
    public SalesRep: boolean = false;
    public ServiceTech: boolean = false;
    public Telemarketer: boolean = false;
    public TelemarketingManager: boolean = false;
    public Test: boolean = false;
    public WarehouseAgent: boolean = false;
    public WarehouseManager: boolean = false;
}


export const PageIds = {
    intel_introduction: 'B633AD70-92D0-480B-9CB5-FA53451ED953',
    workflow: '91C24C8C-42F3-493F-9A76-04DADC4E8CD8',
    workflow_project: 'C54F69C7-7E4F-4DD7-9251-C0C00D1C0E7C',
    workflow_task: '9BA26BC3-0EE3-4431-99C1-954A18F512C4',
    workflow_edit_task: '90E05B65-60AF-4CF3-A3D8-B4CD37847D7F',
    workflow_upload: '4F4E0844-BAD7-437D-A57E-7CE5B9BF931B',
    settings: '0CAD425E-7D1D-4A9E-88E0-CE86B226C507',
    settings_dashboard: 'CB1BA429-48CB-4D24-BCFA-9E0CC79C952B',
    settings_company_edit: '3F9DB45C-5F96-4162-B28D-37DB88B20204',
    settings_referral_edit: 'F48F0C92-9A39-4CCB-9A1F-64FE1173ABA2',
    settings_disposition_edit: '582CF3B3-5326-4AA4-94DE-ECCA86FF1269',
    settings_status_edit: 'B9A79C4F-AF4C-4F19-B8EC-2BDC06A60D9C',
    settings_productstep_edit: '051975EE-1227-46F5-8A76-037950BAB133',
    settings_template_edit: 'D413D1F3-7D76-48BF-B36B-8DC20C7A524E',
    settings_dashboard_role_edit: '2B8B51B5-9ACD-4B31-B157-C5252BCCE1BA',
    account: '1FA614F5-2375-4CC3-8EAE-36EBD63C2F27',
    account_user_list: '0F540B68-3409-4641-BEF6-E33FBD8DEEAE',
    account_update: 'CA383501-9F4A-40D1-8DF6-5C9855B25BF7',
    admin: '8BF945AD-B032-4492-A5B0-7E5D0E231644',
    admin_reset_account: '5769F5D7-F9BF-4D58-999E-FD2808F83794',
    agent: 'DFEC4AD2-3C2E-4553-BF21-3A15632BFD3E',
    agent_twmldial: '7E7796FF-CD3E-4078-B7AE-860F1FAA38A6',
    client: '5DAEB972-9C1E-4508-BA5C-2C6912339715',
    client_new_client: '165B844E-7A0B-4891-9FF3-A9B15B061DAA',
    client_image_gallery: '0B53A7D5-3692-4FB7-B4F9-A68C91B02AA6',
    client_finder: '1F5BCE12-A087-496B-A8B1-1B47F6F6AC9F',
    client_grid: '349E570F-EA59-4973-9642-3D4A7BCE860D',
    client_dashboard: 'F772F445-475A-4867-B30E-C660E61878ED',
    client_center: '0F5AF7AE-FA15-44AC-99B3-EE9654129B3B',
    dispatch: '6BC66FB5-297B-495E-BA55-4C281928016E',
    dispatch_daily: '4E8E7276-1CD8-4DB8-9475-724C51C96779',
    dispatch_monthly: '014F6049-6FE3-48BA-B4D2-743F5980A729',
    dispatch_weekly: '68864B92-11C6-4725-BC2C-B42E17ECA965',
    dispatch_agentweekly: '0A2B9AEA-2C40-42E4-96F1-2F120AD3AC9D',
    dispatch_gridview: 'F4447E81-DB04-4863-BBA1-7AE9B3459D47',
    extra: '78842A82-290A-4565-8C37-0773B9F5183D',
    extra_maintenance: '19B3E116-6890-4A59-93D8-8CA64031CFDC',
    extra_price: 'DA650C09-F412-4D09-B3D4-D761A865A9AD',
    extra_upload: 'BF7E19B1-132B-4A37-B2C6-71AD4FF6A711',
    extra_logs: 'B65A21DD-D77B-477A-AE3D-7FDA45D6A1CC',
    gps: 'D1E7D74B-00E1-4FE6-9578-76388C754C7C',
    gps_location: '95880F31-F178-4730-9CC1-526E3CC12AC2',
    prospects_dashboard: '7071258B-72B1-456F-84E6-6F2086205B89',
    accounting: '3BB59265-C7AD-4564-9F2E-1A4FB1DB3DA5',
    accounting_center: '99E34F2A-059F-4534-95FC-0584505E1C9F',
    production: '5D1B4465-2D4B-4CD9-AEE5-6A7263906D32',
    production_center: '18B4FDF1-6C97-4FB7-ADEE-8E27D0BB516D',
    import: 'F3967692-395D-459D-8C3A-C5947A9F5118',
    import_client: 'D709B4AA-F4F3-4186-B5EB-8DCE5248CE15',
    api: '5994C74B-4C9A-4BB0-BDAB-ED36007C88BD'
}

export class Account_Model {
    contactGuid: string;
    displayName: string; 
    userTokenDto: UserTokenDto
    Token:string
    Result:string
    UserData: any  
    PageVarid: string;
    EnvironmentInfo: string;
    userInRole:string;
      

}

 


export class MasterControls {
    id: string;
    controlGroup: string;
    controlID: string;
    controlName: string;
    enable: boolean
    inserted: Date;
    updated: Date;

}

export class UserControlsRight {
    id: string;
    accountGuid: string;
    userGuid: string;
    controlID: string;
    enable: boolean
    inserted: Date;
    updated: Date;

}


export class UserDto {
    applicationId: string;
    accountGuid: string;
    token: string;
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    cellPhone: string;
    userId: string;  
    timeZone: string;
    sessionId: string;
    ipaddress: string;
    pageVarId: string;
    imageUrl: string; 
    lockScreen: number;
    signOut: number;
    screenShot: number; 
  
}


export class UserTokenDto {
    applicationId: string;
    accountGuid: string;
    userGuid: string; 
    token: string;
    userName: string;
    contactGuid: string; 
    pageVarId: string;
    sessionId: string;
     
}



export class aspRoles {

    ApplicationId : string = ""; 
    CreateLead : boolean = false; 
    Description : string = ""; 
    FieldUser : boolean = false; 
    IsLg : boolean = false; 
    LoweredRoleName : string = ""; 
    Manager : boolean = false; 
    ManualClockIn : boolean = false; 
    Marketing : boolean = false; 
    Positionid : number = 0; 
    ProductionUser : boolean = false; 
    RoleId : string = ""; 
    RoleName : string = ""; 
    SmsList : boolean = false; 
    SystemUser : boolean = false; 
    TeleMarketing : boolean = false; 
    UserGroup : string = ""; 
    ViewInCallMap : boolean = false; 
    ViewInTimeLine : boolean = false; 

}


export class UserInRole {

    public roleId: string;
    public roleName: string;
    public userId: string;

}

export class UserInGroup {

    public groupId: string;
    public groupName: string;
    public userId: string;
    public inserted: Date;
    public updated: Date;
    public sincUserid: string;
    public conflict: boolean;

}


//Common Grid
export class GridFilter {
    public ColumnName: string;
    public DisplayText: string;
    public Value: any;
    public Condition: string;
    public Type: string; // string|date|int|decimal|bool
    public Mode: string;
    public Is_Visible: boolean;
    public Width: number;
    public TextAlign: string;
}
export class Pager {
    public totalItems: number;
    public currentPage: number;
    public pageSize: number;
    public totalPages: number;
    public startPage: number;
    public endPage: number;
    public startIndex: number;
    public endIndex: number;
    public pages: any;
}

export class Column_Detail {
    Display_Name: string;
    Property_Name: string;
    Width: number;
    Is_Visible: boolean;
    Is_Sortable: boolean;
    Type: string;
    Sort_Order: string;
}

export class ResponseModel<T>{
    Statuscode: number;
    StatusMessage: string;
    Data: T;
}

export class XmlSetting_Model {
    PageSize: number;
    token: string;
    merchant_id: number;
    app_id: number;
    roleid: number;
    userid: number;
    address: string;
    contact: string;
    birthday_dollars: string;
}


//Start Alerts
export class Alert {
    type: AlertType;
    message: string;
    alertId: string;
    keepAfterRouteChange: boolean;
    cssClass: string;
   
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
//End Alerts


//Start Chat
export class Chat_Model {
    ChatList: Array<Chat_Type>;
    constructor() {
        this.ChatList = [];
    }
}
export class Chat_Type {
    Type: string;//chat||call||
    Is_Show: boolean;
    Is_Minimize: boolean;
    Name: string;
    Data: any;
    uniqeID: number;
    Online_Type: string;//online||offline||away
    constructor() {
        this.Data = [];
        this.Is_Minimize = false;
        this.Is_Show = true;
    }
}
//End Chat

export interface Account_Info_View_Model {
    "id": number,
    "userId": string,
    "accountGuid": string,
    "accountVarID": string,
    "companyID": string,
    "company": string,
    "phone": string,
    "email": string,
    "address": string,
    "address1": string,
    "suite": string,
    "city": string,
    "zip": string,
    "state": string,
    "county": string,
    "createdDate": string,
    "approved": true,
    "approvedDate": string,
    "status": string,
    "deleteAccountGuid": string,
    "twilio": boolean,
    "twilioNumbers": string,
    "allowTollFree": boolean,
    "twAcountsid": string,
    "twAcounttoken": string,
    "twmlApp": string,
    "twmlAppSid": string,
    "isBlocked": boolean,
    "blockeddDate": string,
    "latitude": number,
    "longitude": number,
    "accountID": string,
    "registerPassWord": string
}

export interface User {

    "applicationId": string,
    "userId": string,
    "loweredUserName": string,
    "mobileAlias": string,
    "isAnonymous": boolean,
    "lastActivityDate": string,
    "isOnLine": boolean,
    "forceLogOut": boolean,
    "lastTimeStemp": string,
    "lastIp": string,
    "version": string,
    "accountGuid": string,
    "firstName": string,
    "lastName": string,
    "cellPhone": string,
    "mobileActivationCode": string,
    "mobileConfirmed": boolean,
    "altEmailAddress": string,
    "altEmailConfirmeCode": string,
    "emailActivationCode": string,
    "altEmailConfirmed": boolean,
    "accessCode": string,
    "timeZone": number,
    "userVarId": string,
    "passwordHash": string,
    "passwordSalt": string,
    "sessionId": string,
    "pageVarId": string,
    "aspnetMembership": string,
    "aspnetProfile": string,
    "employees": Array<EmployeeCard>,
    "aspnetUsersInRoles": Array<UserRoles>,
    "usersInGroups": Array<UserGroups>,
    "usersInFlags": Array<UserFlags>,
    "photos": Array<any>,
    "appComponentsUserPermission": Array<any>,
    "companysUsers": Array<CompanyUser>,
    "referralsUsers": Array<Referral>,
    "created": string,
    "createdBy": string,
    "googleSignInToken": string,
    "passwordConfirmeToken": string,
    "emailConfirmeToken": string,
    "googleJwtId": string,
    "imageUrl": string,
    "ipAccess1": string,
    "ipAccess2": string,
    "ipAccess3": string,
    "ipAccess4": string,
    "mobileAppAccess": boolean,
    "mobileTimeClockAccess": boolean,
    "twilioAppAccess": boolean,
    "emailAppAccess": boolean,
    "timeClockId": string,
    "timeClockPassword": string,
    "mobileId": string,
    "deviceId": string,
    "id": string,
    "userName": string,
    "normalizedUserName": string,
    "email": string,
    "normalizedEmail": string,
    "emailConfirmed": boolean,
    "securityStamp": string,
    "concurrencyStamp": string,
    "phoneNumber": string,
    "phoneNumberConfirmed": boolean,
    "twoFactorEnabled": boolean,
    "lockoutEnd": string,
    "lockoutEnabled": boolean,
    "accessFailedCount": number
}

export interface UserRoles {
    "userId": string,
    "roleId": string,
    "role": string
}

export interface UserGroups {
    "userId": string,
    "groupId": string
}

export interface UserFlags {
    "userId": string,
    "flagId": string
}

export interface CompanyUser {
    "id": number,
    "accountGuid": string,
    "companyVarID": string,
    "userId": string,
    "firstName": string,
    "lastName": string,
    "phone": string,
    "title": string,
    "emailAddress": string,
    "active": boolean,
    "activeBy": string,
    "activeDate": string
}

export interface Referral {
    "id": number,
    "accountGuid": string,
    "referralVarID": string,
    "userId": string,
    "active": boolean,
    "activeBy": string,
    "activeDate": string,
    isAssignUser: boolean
}

export interface EmployeeCard {

    accountGuid: string,
    active: boolean,
    address: string,
    birthDate: Date,
    city: string,
    conflict: boolean,
    county: string,
    createdBY: string,
    createdDate: Date,
    deleted: boolean,
    employeeGuid: string,
    employeeVarID: string,
    english: boolean,
    hero: boolean,
    hiredDate: Date,
    homePhone: string,
    id: number,
    inserted: Date,
    inspector: boolean,
    installManager: boolean,
    installer: boolean,
    latitude: number,
    longitude: number,
    mobileCarrier: string,
    mobilePhone: string,
    salesRep: boolean,
    serviceTech: boolean,
    setUpInstaller: boolean,
    sincUserid: string,
    spanish: boolean,
    ssn: string,
    state: string,
    subContractor: boolean,
    suite: string,
    taxId: string,
    title: string,
    updated: Date,
    userId: string,
    zip: string,
    employeeCommission: Array<EmployeeCommission>
    

}

export interface EmployeeCommission {
    "id": number,
    "aEmployeesid": string,
    "empCommVarID": string,
    "empCommGuid": string,
    "accountGuid": string,
    "employeeVarID": string,
    "userId": string,
    "commPerJob": number,
    "prCommPerJob": number,
    "cash": number,
    "chack": number,
    "creditCard": number,
    "processCash": boolean,
    "processCreditCard": boolean,
    "processChack": boolean,
    "profitDay": number,
    "profitNight": number,
    "extraProfit": number,
    "holdCash": boolean,
    "holdChecK": boolean,
    "holdCreditCard": boolean,
    "holdTax": boolean,
    "dedactParts": boolean,
    "forceChargeCommPerJob": boolean,
    "createdDate": string,
    "approvedEmail": string,
    "approvedDate": string,
    "approvedBy": string,
    "approved": boolean,
    "createdBY": string,
    "accountLimit": number,
    "cashLimit": number,
    "startDate": string,
    "endDate": string,
    "minSaleAmmount": number,
    "locked": boolean,
    "lockedBY": string,
    "dyleeCostPrice": number,
    "hourlyRate": number
}

export interface FlagSummary {
    "applicationId": string,
    "accountGuid": string,
    "flagId": string,
    "flagName": string,
    "totalUsers": number,
    "prospects": number,
    "userInfoList": Array<UserInfoList>
}

export interface StatusSummary {
    "id": number,
    "accountGuid": string,
    "statusGuid": string,
    "status": string,
    "active": boolean,
    "activeOnDispatche": boolean,
    "isMonitored": boolean,
    "color": string,
    "backColor": string,
    "mapIconPinid": number,
    "fontColor": string,
    "prospects": number,
    "workOrders": number
}

export interface UserInfoList {
    "userId": string,
    "userName": string,
    "imageUrl": string
}

export interface CompanyDetail {
    id: number,
    accountGuid: string,
    companyGuid: string;
    userId: number,
    companyVarID: string,
    active: boolean,
    companyName: string,
    firstName: string,
    lastName: string,
    ssm: string,
    taxId: string,
    tollFreeNum: string,
    businessPhone: string,
    homePhone: number,
    mobilePhone: number,
    address: string,
    suite: string,
    city: string,
    state: string,
    zip: number,
    county: string,
    createdBY: string,
    createdDate: string,
    email: string,
    emailApproved: boolean,
    qbPassword: string,
    qbSinqIP: string,
    qbSinqItems: boolean,
    qbSinqListId: string,
    confirmationNotificationEmail: string,
    appointmentNotificationEmail: string,
    appointmentSurveyEmail: string,
    gateway: string,
    defaultEmailSender: string,
    authorizeNetApi: string,
    authorizeNetSecureKey: string,
    heartlandPublicKey: string,
    heartlandSecretKey: string,
    authorizeInTestMode: boolean,
    imageUrl: string,
    inserted: Date,
    updated: Date,
    sincUserid: string,
    conflict: boolean,
    qbStartInvoiceNumber: number,
    qbStartServiceNumber: number,
}

export interface ReferralsDetail {
    id: number,
    referralVarID: string,
    referralGuid: string,
    accountGuid: string,
    userId: string,
    companyVarID: string,
    title: string,
    referralNameTag: string,
    code: string,
    emailReplayTo: string,
    active: boolean,
    leadCost: number,
    prLeadCost: number,
    rColor: string,
    profit: number,
    profitNight: number,
    extraProfit: number,
    startDate: string,
    endDate: string,
    createdDate: string,
    createdBY: string,
    locked: boolean,
    lockedBY: string,
    parentReferralVarID: string,
    calendarAccessCode: string,
    cash: number,
    chack: number,
    creditCard: number,
    processCash: boolean,
    processCreditCard: boolean,
    processChack: boolean,
    holdCash: boolean,
    holdChecK: boolean,
    holdCreditCard: boolean,
    holdTax: boolean,
    dedactParts: boolean,
    forceChargeCommPerJob: boolean,
    accountLimit: number,
    cashLimit: number,
    minSaleAmmount: number,
    alowAccess: boolean,
    accessCode: string,
    accessUrl: string,
    accessIp: string,
    passWord: string,
    startFlag: string,
    imageUrl: string,
    providerImageUrl: string,
    isLg: boolean,
    autoAssignUser: boolean
}


export interface ClientInfo {

    id: number,
    accountGuid: string,
    companyGuid: string,
    companyVarID: string,
    referralGuid: string,
    referralVarID: string,
    createdBy: string,
    createdDate: string,
    clientGuid: string,
    clientVarID: string,
    parentClientVarID: string,
    agentId: string,
    qbAccountid: string,
    header: string,
    firstName: string,
    middleName: string,
    lastName: string,
    companyName: string,
    cellPhone: number,
    email: string,
    driverLicence: string,
    birthDate: string,
    credit: string,
    balance: string,
    type: number,
    taxExempt: boolean,
    affiliator: string,
    useAsReff: boolean,
    active: boolean,
    doNotService: boolean,
    doNotMail: boolean,
    doNotSendPromotionalEmails: boolean,
    doNotChargeInterestFee: boolean,
    duplicated: boolean,
    approved: boolean,
    approvedEmail: boolean,
    approvedDate: string,
    approvedBy: string,
    importId: string,
    importFrom: string,
    inserted: string,
    updated: string,
    sincUserid: string,
    conflict: boolean,
    qbeditSequence: string,
    qbid: string,
    qblistId: string,
    qbstatusMessage: string,
    qbxml: string,
    qbtimeCreated: string,
    qberror: string,
    qbmassage: string,
    notes: string,
    initiator: string,
    senior: boolean,
    milatry: boolean,
    veteran: boolean,
    appeasement: boolean,
    clientsAddress: string,

    

}


export interface ParseClientInfo {

    Id: number,
    AccountGuid: string,
    CompanyGuid: string,
    CompanyVarID: string,
    ReferralGuid: string,
    ReferralVarID: string,
    CreatedBy: string,
    CreatedDate: Date,
    ClientGuid: string,
    ClientVarID: string,
    ParentClientVarID: string,
    AgentId: string,
    QbAccountid: string,
    Header: string,
    FirstName: string,
    MiddleName: string,
    LastName: string,
    CompanyName: string,
    CellPhone: number,
    Email: string,
    DriverLicence: string,
    BirthDate: string,
    Credit: string,
    Balance: string,
    Type: number,
    TaxExempt: boolean,
    Affiliator: string,
    UseAsReff: boolean,
    Active: boolean,
    DoNotService: boolean,
    DoNotMail: boolean,
    DoNotSendPromotionalEmails: boolean,
    DoNotChargeInterestFee: boolean,
    Duplicated: boolean,
    Approved: boolean,
    ApprovedEmail: boolean,
    ApprovedDate: string,
    ApprovedBy: string,
    ImportId: string,
    ImportFrom: string,
    Inserted: string,
    Updated: string,
    SincUserid: string,
    Conflict: boolean,
    QbeditSequence: string,
    Qbid: string,
    QblistId: string,
    QbstatusMessage: string,
    Qbxml: string,
    QbtimeCreated: string,
    Qberror: string,
    Qbmassage: string,
    Notes: string,
    Initiator: string,
    Senior: boolean,
    Milatry: boolean,
    Veteran: boolean,
    Appeasement: boolean,
    ClientsAddress: string,


}


export interface GetClientEmails {
    "id": number,
    "accountGuid": string,
    "clientGuid": string,
    "clientVarID": string,
    "emailVarID": string,
    "email": string,
    "createdBY": string,
    "createdDate": string,
    "useAsDefault": boolean,
    "doNotMail": boolean
}


export interface GetClientProspects {
    "id": number,
    "prospectGuid": string,
    "prospectVarID": string,
    "accountGuid": string,
    "clientGuid": string,
    "leadid": string,
    "leadSource": string,
    "leadAgent": string,
    "recivedDate": string,
    "recivedBy": string,
    "created": string,
    "createdBy": string,
    "jobId": number,
    "jobType": string,
    "title": string,
    "statusGuid": string,
    "flagGuid": string,
    "notes": string,
    "rating": number,
    "mode": string,
    "color": string,
    "tentiveDate": string,
    "verifeyed": boolean,
    "verifeyedBy": string,
    "verifeyedDate": string,
    "confirmed": boolean,
    "confirmedBy": string,
    "confirmedDate": string,
    "rehashed": boolean,
    "rehashBy": string,
    "rehashDate": string,
    "reloaded": boolean,
    "reloadedBy": string,
    "reloadedDate": string,
    "lastOpendBy": string,
    "lastOpenDate": string,
    "agentFault": boolean,
    "verificationFault": boolean,
    "viciCampaign": string,
    "viciCampaignID": string,
    "viciList": string,
    "viciListID": string,
    "goodLead": boolean,
    "badLeed": boolean,
    "notALead": boolean,
    "lastCallTime": string,
    "lastCallSid": string,
    "lastCallAgent": string,
    "lastCallStatus": string,
    "lastCallDuration": number,
    "lastCallVarId": string,
    "twmlQueueVarId": string,
    "twmlDispo": string,
    "twmlDispoByName": string,
    "twmlDispoDate": string,
    "twmlDispoBy": string,
    "leadTagId": number,
    "leadTagText": string

}

export interface GPSTrackerUsers {
    "id": number,
    "accountGuid": string,
    "userIdVarID": string,
    "userId": string,
    "firstName": string,
    "lastName": string,
    "imageUrl": string,
    "date": Date,
    "latitude": number,
    "longitude": number,
    "dir": string,
    "speed": string,
    "mobilePhone": string,
    "address": string,
    "zipCode": string,
    "city": string,
    "distance": number,
    "deviceId": string,
    "userRequest": string,
    "newProspects": number,
    "workOrders": number,
    "notesCount": number,
    "notifications": number,
    "notes": string,
    "points": number
}

export interface UserGeoTrack {
    "userId": string,
    "isOnLine": boolean,
    "lastTimeStemp": string,
    "firstName": string,
    "lastName": string,
    "cellPhone": string,
    "geoList": Array<GPSTrackerUsers>
}

export interface WorkflowStatus {
    "id": number,
    "accountGuid": string,
    "statusGuid": string,
    "status": string,
    "active": boolean,
    "activeOnDispatche": boolean,
    "isMonitored": boolean,
    "color": string,
    "backColor": string,
    "mapIconPinid": number,
    "fontColor": string
}

export interface CreateWorkflowProject {
    "id": number,
    "accountGuid": string,
    "projectGuid": string,
    "status": string,
    "created": string,
    "createdBy": string,
    "title": string,
    "description": string,
    "color": string,
    "isClose": boolean,
    "closed": string,
    "closedBy": string
}

export interface CreateWorkflowTask {
    "id": number,
    "taskGuid": string,
    "accountGuid": string,
    "projectGuid": string,
    "title": string,
    "description": string,
    "isOpen": boolean,
    "createdBy": string,
    "createdDate": string,
    "status": string,
    "priority": number,
    "color": string
}

export interface WorkflowTasks {
    "id": number,
    "taskGuid": string,
    "accountGuid": string,
    "projectGuid": string,
    "title": string,
    "description": string,
    "isOpen": boolean,
    "createdBy": string,
    "createdDate": string,
    "status": string,
    "startDay": string,
    "endDay": string,
    "priority": number,
    "color": string,
    "workFlowProjectsAssigmentsDto": Array<WorkFlowProjectsAssigmentsDto>,
    "workFlowTasksAssigmentsDto": Array<WorkFlowTasksAssigmentsDto>,
    "workFlowTasksNotesDto": Array<WorkFlowTasksNotes>,
    "workFlowTicketsDto": Array<WorkFlowTicketsDto>
    "openTasks": number,
    "openTickets": number,
    "openNotes": number
}




export interface WorkflowProjects {
    "id": number
    "taskGuid": string,
    "accountGuid": string,
    "projectGuid": string,
    "clientGuid": string,
    "appointmentGuid": string,
    "invoiceGuid": string,
    "title": string,
    "description": string,
    "isClose": boolean,
    "createdBy": string,
    "createdDate": string,
    "status": string,
    "statusText": string,
    "priority": number,
    "color": string,
    "workFlowStatus": WorkflowStatus,
    "workFlowTasksDto": Array<WorkFlowTasksDto>,
    "workFlowProjectsAssigmentsDto": Array<WorkFlowProjectsAssigmentsDto>,
    "workFlowTicketsDto": Array<WorkFlowTicketsDto>,
    "workFlowTasksNotes": Array<WorkFlowTasksNotes>,
    "openTasks": number,
    "openTickets": number,
    "openNotes": number,
    "tickets": number,
    "notes": number,
    "tasks": number,
    "startDay": Date,
    "endDay": Date,
    "created": Date,
    "closed": Date,
    "closedBy": string,
    "assignments": string,
    "inserted": Date,
    "updated": Date,
}

export interface WorkFlowProjectsAssigmentsDto {
    "id": number,
    "accountGuid": string,
    "projectGuid": string,
    "taskGuid": string,
    "userGuid": string,
    "assiendDate": string,
    "assignedBy": string,
    "active": boolean,
    "emailAlert": boolean,
    "smsAlert": boolean,
    "discharged": boolean,
    "dischargedBy": string
    "dischargedDate": string,
    "dischargeNote": string,

}

export interface WorkFlowTasksNotes {
    "id": number,
    "accountGuid": string,
    "projectGuid": string,
    "taskGuid": string,
    "comment": string,
    "createdBy": string,
    "createdDate": string,
    "inserted": Date,
    "updated": Date,
    "sincUserid": string
    "conflict": boolean
}

export interface WorkFlowTicketsDto {
    "id": number,
    "ticketGuid": string,
    "accountGuid": string,
    "projectGuid": string,
    "taskGuid": string,
    "ticketType": number,
    "category": number,
    "title": string,
    "description": string,
    "isOpen": boolean,
    "createdBy": string,
    "createdDate": string,
    "status": string,
    "priority": number,
    "inserted": Date,
    "updated": Date,
    "sincUserid": string
    "conflict": boolean
}

export interface WorkFlowTasksDto {
    "id": number,
    "taskGuid": string,
    "accountGuid": string,
    "projectGuid": string,
    "title": string,
    "description": string,
    "isOpen": boolean,
    "createdBy": string,
    "createdDate": Date,
    "status": string,
    "priority": number,
    "color": string,
    "startDay": Date,
    "endDay": Date,
    "inserted": Date,
    "updated": Date,
    "sincUserid": string,
    "conflict": boolean,
    "pageId": string,
    "closed": Date,
    "closedBy": string
}

export interface WorkFlowTasksAssigmentsDto {
    accountGuid: string
    active: boolean
    assiendDate: string
    assignedBy: string
    dischargeNote: string
    discharged: boolean
    dischargedBy: string
    dischargedDate: string
    emailAlert: boolean
    id: number
    projectGuid: string
    smsAlert: boolean
    taskGuid: string
    userGuid: string
}

export interface UserDB {
    userId: string,
    userName: string,
    firstName: string,
    lastName: string,
    imageUrl: string,
    //roles: string,
    isSelected: boolean,
}

export interface WorkflowTaskProgress {
    projectGuid: string,
    taskGuid: string,
    userId: string,
    createdDate: string,
    firstName: string,
    lastName: string,
    userImageUrl: string,
    color: string,
    icon: string,
}


