<app-logo></app-logo>

<ng-scrollbar visibility="hover" pointerEventsMethod="scrollbar" class="flex-grow-1">
	<div *ngFor="let menu of menuLinks; let i = index" class="mb-5">
		<div class="menu__title">{{ menu.group }}</div>

		<ul class="menu__links">
			<li
				*ngFor="let link of menu.links; let x = index"
				[class.menu__sub]="link.sub"
				[class.menu__sub--active]="link.sub && link.visibility === 'active'"
				routerLinkActive="menu__active"
			>
				<a *ngIf="!link.sub" [routerLink]="link.route">
					<i [ngClass]="['zwicon', 'zwicon-' + link.icon]"></i>
					{{ link.title }}
				</a>

				<a *ngIf="link.sub" (click)="link.sub && toggleSubMenu(i, x)">
					<i [ngClass]="['zwicon', 'zwicon-' + link.icon]"></i>
					{{ link.title }}
				</a>

				<ul *ngIf="link.sub" [@toggleSubMenu]="link.visibility">
					<li *ngFor="let sub of link.sub" routerLinkActive="menu__active">
						<a [routerLink]="sub.route">{{ sub.title }}</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</ng-scrollbar>
