import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from "src/app/app.service";

@Component({
	selector: 'app-layout1',
	templateUrl: './layout1.component.html',
	styleUrls: ['./layout1.component.scss']
})
export class Layout1Component implements OnInit {
	layoutParam = 'layout';
	isMobile: boolean;
	viewUserInfo: boolean = false;
	constructor(public service: AppService, private route: ActivatedRoute, private router: Router) {
		// this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((params) => {
		// 	 service.sidebarActive = route.snapshot.firstChild.data[this.layoutParam] === 'full';
		// 	 service.themeLayout = route.snapshot.firstChild.data[this.layoutParam] === 'full' ? 'full' : '';
		// });
		this.isMobile = this.checkIfMobile(); // Implement the method to check for mobile device/screen
	}


	setUserInfoView() {
		this.viewUserInfo = !this.viewUserInfo;
	  }


	checkIfMobile(): boolean {
		const userAgent = window.navigator.userAgent;
		const mobileDeviceRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
		return mobileDeviceRegex.test(userAgent);
	  }
	  

	userInfo:any ={

		      name: '',
				role: '',
				email: '',
				imageUrl: '',
				userIdVarID:'',
				cellPhone:''

	};
	ngOnInit() {


		var d = this.service.CurentUserData; 
		this.service.DevelopConsoleLog('CurentUserData',d);
		if(d == null){
			 
		} else {
			this.userInfo  = {
				name: d.lineName,
				role: d.role,
				email: d.email,
				imageUrl: d.imageUrl,
				userIdVarID:d.userIdVarID,
				cellPhone:d.cellPhone
			};
		}
		//this.service.DevelopConsoleLog('CurentUserData',d);
		//d.imageUrl.replace('../../','https://www.qdtext.com/')
		


	}
}
