<div>
	<!-- class="layout layout--toggled" -->

	 <app-sidebar [class.sidebar-toggled]="true"></app-sidebar>  
	<!-- <div
		*ngIf="service.sidebarActive"
		class="backdrop backdrop--sidebar d-xl-none"
		(click)="service.sidebarActive = true"
	></div> -->

	<div class="main">

 

 
		 



		<!--		<ng-container *ngIf="isMobile; else desktopLayout">
			<app-layout2></app-layout2>
		  </ng-container>
	   -->
		<app-header class="header"></app-header> 
		<app-iframe> </app-iframe> 
		<router-outlet></router-outlet>



		 <div class="card transparent"  id="accountinfo"  *ngIf="this.service.CurentUserData?.userGuid == '15f92839-4ccb-4550-9ec0-86753b9a1d11' " 
		style="position: absolute;
		bottom: 0;
		right: 20; ">
			<ul class="   mt-2 mb-1 mr-2">  
				<li class="link link-h" (click)="setUserInfoView()">CurentUserDataView</li>
				<li>{{this.service.CurentUserData?.companyName}}</li>
				<li *ngIf="viewUserInfo == true">isMobile</li> 
				<li *ngIf="viewUserInfo == true">companyUserName: {{this.service.CurentUserData?.companyUserName}}</li>
				<li *ngIf="viewUserInfo == true">userName: {{this.service.CurentUserData?.userName}}</li>
				<li *ngIf="viewUserInfo == true">email: {{this.service.CurentUserData?.email}}</li>
				<li *ngIf="viewUserInfo == true">lineName: {{this.service.CurentUserData?.lineName}}</li>
				<li *ngIf="viewUserInfo == true">role: {{this.service.CurentUserData?.role}}</li> 
				<li *ngIf="viewUserInfo == true">accountGuid: {{this.service.CurentUserData?.accountGuid}}</li>
				<li *ngIf="viewUserInfo == true">userGuid: {{this.service.CurentUserData?.userGuid}}</li>
				<li *ngIf="viewUserInfo == true">roleId: {{this.service.CurentUserData?.roleId}}</li>
				
			</ul>
		</div>  
 

	</div>
</div>


<!-- [ngClass]="{
	'layout--toggled': service.sidebarActive,
	'layout--aside': service.themeLayout === 'full',
	'layout--aside-toggled': service.asidePageActive
}" -->
