<div
	class="sidebar-toggle"
	(click)="service.sidebarActive = !service.sidebarActive"
	[ngClass]="{
		'sidebar-toggles--active': service.sidebarActive,
		'sidebar-toggles--hover': service.themeLayout === 'full'
	}"
></div>

<div class="logo">
	app-logo!1!
	<!-- <small class="fs-7 d-block">version 1.3.0</small> -->
</div>
