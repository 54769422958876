import { Injectable } from "@angular/core";
import { AppService } from "../../../app.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EmployeeApiService {
  constructor(public service: AppService, public http: HttpClient) {}

  GetRoles() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetRoles"
    );
  }

  GetRolesUsersCount() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetRolesUsersCount"
    );
  }

  GetPosions() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetPosions"
    );
  }


  GetAllUsers() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetAllUsers"
    );
  }


  UpdateUsersInRols(userInRole:any) {
    return this.service.Data.ExecuteAPI_Post<Array<any>>(
      "admin/employee/Employees/UpdateUsersInRols", userInRole
    );
  }


  GetUsersInRols() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetUsersInRols"
    );
  }



  GetAllEmployees() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetAllEmployees"
    );
  }

  GetOnlineEmployesTimeSheet() {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetOnlineEmployesTimeSheet"
    );
  }

  GetEmployeTimeSheet(EmployeeVarID: string) {
    return this.service.Data.ExecuteAPI_Get<Array<any>>(
      "admin/employee/Employees/GetOnlineEmployesTimeSheet?EmployeeVarID=" + EmployeeVarID
    );
  }


  GenerateMobilePinNumber(UserId: string) {
    return this.service.Data.ExecuteAPI_Get<any>(
      "admin/employee/Employees/GenerateMobilePinNumber?UserId=" + UserId
    );
  }
}
